import React from 'react'
import StaticImage from '../Components/StaticImage/StaticImage'


function StaticImageList() {
  return (
    <div>
        <StaticImage/>
    </div>
  )
}

export default StaticImageList