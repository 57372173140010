import React, { useEffect, useState } from "react";
import amazone from "../Assets/pluscardgif.svg";
import { Link, useNavigate } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import "./Product.css";
import { CSVLink } from "react-csv";

function GiftCardAdminList() {
  const [productdata, setProductdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const userPhoneNumber = localStorage.getItem("userPhoneNumber");
  const navigate = useNavigate();
  // console.log(userPhoneNumber, "ip");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}admin/gifzi_plus/manual?offset=0&limit=50`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setProductdata(data?.data.cards || []);
        } else {
          const errorData = await response.json();
          setError(
            `Failed to fetch data: ${errorData.message || "Unknown error"}`
          );
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
        // console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, token]);

  const handleCardClick = (cardId) => {
    navigate(`/giftcarddetails/${cardId}`);
  };

  const toggleStatus = async (id, currentStatus) => {
    const token = localStorage.getItem("authToken");
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}admin/gifzi_plus/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setProductdata((prevData) =>
          prevData.map((blog) =>
            blog.id === id ? { ...blog, status: newStatus } : blog
          )
        );
      } else {
        alert(result.message || "Failed to update status");
      }
    } catch (err) {
      alert("Something went wrong. Please try again.");
    }
  };

  const formatCardNumber = (cardNumber) => {
    if (cardNumber.length < 6) {
      return cardNumber; // or return a placeholder
    }
    // console.log('Lenght', cardNumber.length)
    //const firstTwo = cardNumber.slice(0, 2);
    const lastFour = cardNumber.slice(-4);
    const maskedSection = "X".repeat(cardNumber.length - 4);
    // console.log('maskedSection', maskedSection)

    return `${maskedSection}${lastFour}`;
  };

  const csvData = productdata.map(item => ({
    ID: item.id,
    "Card Number": formatCardNumber(item.card_number),
    "Expiry Date": item.expiry,
    "Status": item.status ? 'Active':'Inactive',
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="p-60 ml-14-d userdetail">
        <div className="container">
          <div className="main-bg-off-white bg-off-white product-list">
            <section className="faq-body">
              <div className="container">
                <div className="d-flex-center justify-content-between pb-3">
                  <div className="d-flex gap-2 ">
                    <ArrowRedirect />
                    <h3 className="grey-85 f-18 f-weight-600">Gift Card</h3>
                  </div>

                  <div className="btn-flex-center d-flex gap-2">
                   

                    <CSVLink data={csvData} filename={"product_data.csv"} className="Commonbtn">
                      Export to CSV
                    </CSVLink>

                    {/* <Link to="/addgiftcard">
                      <Commonbtn Commonbtntext="+Add Gift Card" />
                    </Link> */}
                    {/* <button type="button" className="Commonredbtn">
                      Delete
                    </button> */}
                  </div>
                </div>
                
                <div className="row">
                  {productdata?.map((item, index) => (
                    <div
                      key={index}
                      className="col-md-6 col-lg-3 col-sm-12 gift-card"
                      data-aos="fade-right"
                      data-aos-delay="300"
                      style={{ cursor: "pointer" }}
                    >
                     
                      <div className="card d-block border-0">
                        <img
                          className="rounded"
                          src={item.cardimage || amazone}
                          alt="product"
                        />
                         <Link
                            to={`/giftcarddetails/${item.id}`}
                            className="edit-icon"
                            onClick={(e) => e.stopPropagation()}
                          >
                        <h3
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "0.8rem",
                            marginTop: "0.8rem",
                            color: "#666666",
                          }}
                        >
                          <p className="f-16 buy-text d-block">
                            Card Number: {formatCardNumber(item.card_number)}
                          </p>
                          {item?.name || "Gift Card"}
                        </h3>
                        <p className="f-16 buy-text d-block">
                          Expiry Date: {item.expiry}
                        </p>
                        </Link>
                        <div className="action-row d-flex justify-content-between">
                          <div
                            className="status-toggle"
                            onClick={() => toggleStatus(item.id, item.status)}
                          >
                            <p
                              className={`status-indicator mt-2 ${
                                item.status === 0 ? "inactive" : "active"
                              }`}
                            >
                              {item.status === 1 ? "Active" : "Inactive"}
                            </p>
                          </div>

                          
                           
                         
                        </div>
                        
                      </div>
                     
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftCardAdminList;
