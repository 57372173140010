import React, { useState, useEffect, useMemo, useRef } from "react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";

function Addoffer() {
  const editor = useRef(null);
  const mainArray = ["BY_PERCENT", "BY_FIXED", "TO_PERCENT"];
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  const [productArray, setProductArray] = useState([]);
  const [value, setValue] = useState("");
  const [discount_Type, setDiscountType] = useState("");
  const [discount_id, setDiscountId] = useState("");
  const [discount_amount, setDiscountAmount] = useState("");
  const [discount_max, setDiscountMax] = useState("");
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [discount_desc, setDiscountDesc] = useState("");
  const [coupon_code, setCouponCode] = useState("");
  const [priority, setPriority] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  
  const config = useMemo(() => ({
    readonly: false,
    placeholder: 'Enter blog description here...',
  }), []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/offer/products`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProductArray(data.data.products);
        } else {
          setErrorMessage("Failed to fetch products.");
        }
      } catch (error) {
        setErrorMessage("Error fetching products: " + error.message);
      }
    };

    fetchProducts();
  }, [baseUrl, token]);

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const offerData = {
      sku: value,
      // discount_id,
      discount_type: discount_Type,
      discount_amount: discount_amount,
      discount_max: discount_max,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
      discount_desc: discount_desc,
      coupon_code: coupon_code,
      order: 1,
    };

    // if (discount_id && discount_id.trim() !== "") {
    //   offerData.discount_id = discount_id;
    // }

    // console.log("offerdata---->", offerData);
    // console.log("offerdata---", JSON.stringify(offerData));

    try {
      const response = await fetch(`${baseUrl}admin/offer/create`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(offerData),
      });

      // console.log("create response", response);

      if (response.ok) {
        const result = await response.json();
        // console.log("Offer created: ", result);
        setSuccessMessage("Offer created successfully!");
        setErrorMessage("");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Failed to create offer.");
      }
    } catch (error) {
      setErrorMessage("Error creating offer: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Offer</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-12 my-3">
              <label className="text-center">Product </label>
              <select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="form-control"
                style={{
                  padding: "10px 10px",
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "grey",
                }}
              >
                <option value="Select an Option">Select Product</option>
                {productArray.map((item) => (
                  <option value={item.sku} key={item.sku}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-12 my-3">
  <label className="text-center">Discount Type</label>
  <select
    value={discount_Type}
    onChange={(e) => setDiscountType(e.target.value)}
    className="form-control"
    style={{
      padding: "10px 10px",
      backgroundColor: "#F5F5F5",
      border: "1px solid #F5F5F5",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      color: "grey",
    }}
  >
    <option value="Select an Option">Select Discount Type</option>
    {mainArray.map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ))}
  </select>
</div>

{/* Conditionally render inputs based on discount_Type */}
{discount_Type === "BY_PERCENT" && (
  <div className="col-md-12 my-3">
    <Input
      placeholdertext="Enter discount in Percentage"
      labeltext="Discount in Percentage"
      typetext="text"
      onChange={(e) => setDiscountMax(e.target.value)}
      value={discount_max}
    />
  </div>
)}

{discount_Type === "BY_FIXED" && (
  <div className="col-md-12 my-3">
    <Input
      placeholdertext="Enter discount amount"
      labeltext="Discount Amount"
      typetext="text"
      onChange={(e) => setDiscountAmount(e.target.value)}
      value={discount_amount}
    />
  </div>
)}

{discount_Type === "TO_PERCENT" && (
  <>
    <div className="col-md-12 my-3">
      <Input
        placeholdertext="Enter discount in Percentage"
        labeltext="Discount in Percentage"
        typetext="text"
        onChange={(e) => setDiscountMax(e.target.value)}
        value={discount_max}
      />
    </div>
    <div className="col-md-12 my-3">
      <Input
        placeholdertext="Enter discount amount"
        labeltext="Discount Amount"
        typetext="text"
        onChange={(e) => setDiscountAmount(e.target.value)}
        value={discount_amount}
      />
    </div>
  </>
)}


         

            <div className="col-md-12 my-3">
              <div className="d-flex gap-2 align-items-center">
                <label className="text-center">Start Date :</label>
                <DatePicker
                  selected={start_date}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  placeholderText="Select date"
                  style={{
                    padding: "10px 10px",
                    backgroundColor: "#F5F5F5",
                    border: "1px solid #F5F5F5",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    color: "grey",
                  }}
                />
              </div>
            </div>

            <div className="col-md-12 my-3">
              <div className="d-flex gap-2 align-items-center">
                <label className="text-center">End Date :</label>
                <DatePicker
                  selected={end_date}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  placeholderText="Select date"
                  style={{
                    padding: "10px 10px",
                    backgroundColor: "#F5F5F5",
                    border: "1px solid #F5F5F5",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    color: "grey",
                  }}
                />
              </div>
            </div>

            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter coupon code"
                labeltext="Coupon Code"
                typetext="text"
                onChange={(e) => setCouponCode(e.target.value)}
                value={coupon_code}
              />
            </div>
            

            {/* <div className="col-md-12 pb-4">
              <label htmlFor="discountDescription" className="form-label">
                Discount Description
              </label>
              <JoditEditor
                ref={editor}
                config={config}
                value={discount_desc}
                onBlur={(newContent) => setDiscountDesc(newContent)}
                onChange={(newContent) => setDiscountDesc(newContent)}
              />
            </div> */}


            <div className='col-md-12 pb-4'>
                <label htmlFor="discountDescription" className="form-label">Discount Description</label>
                <JoditEditor
                  ref={editor}
                  value={discount_desc}
                  config={config}
                  onBlur={(newContent) => setDiscountDesc(newContent)}
                  onChange={(newContent) => setDiscountDesc(newContent)}
                />
              </div>

            <div className="btn-flex-center d-flex gap-2 pt-10">
              <Commonbtn
                Commonbtntext="Save"
                type="submit"
                disabled={isSubmitting}
              />
              <button
                type="button"
                className="Commonredbtn"
                onClick={() => navigate("/offer")}
              >
                Cancel
              </button>
            </div>

            {successMessage && <p className="text-success">{successMessage}</p>}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Addoffer;
