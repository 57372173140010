import React, { useState } from "react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

function AddAdmin() {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const adminData = {
      salutation,
      first_name: firstName,
      last_name: lastName,
      email,
      mobile,
      password,
    };

    try {
      const response = await fetch(`${baseUrl}admin/createAdmin`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(adminData),
      });

      const result = await response.json();
      if (response.ok) {
        setSuccessMessage("Admin created successfully!");
        setErrorMessage("");
        // Reset form
        setSalutation("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobile("");
        setPassword("");
      } else if (result.errors && Array.isArray(result.errors)) {
        const errorMessages = result.errors.map((err) => err.msg).join(", ");
        setErrorMessage(errorMessages || "Failed to create admin. Please try again.");
      } else {
        setErrorMessage(result.message || "Failed to create admin. Please try again.");
      }
    } catch (error) {
      setErrorMessage( error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Admin</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter salutation (e.g., Mr., Ms.)"
                labeltext="Salutation"
                typetext="text"
                onChange={(e) => setSalutation(e.target.value)}
                value={salutation}
                maxLength={10}
              />
            </div>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter first name"
                labeltext="First Name"
                typetext="text"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                maxLength={50}
              />
            </div>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter last name"
                labeltext="Last Name"
                typetext="text"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                maxLength={50}
              />
            </div>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter email"
                labeltext="Email"
                typetext="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                maxLength={100}
              />
            </div>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter mobile number"
                labeltext="Mobile"
                typetext="tel"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                maxLength={10}
              />
            </div>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter password"
                labeltext="Password"
                typetext="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                maxLength={10}
              />
            </div>
            <div className="btn-flex-center d-flex gap-2 pt-10">
              <Commonbtn
                Commonbtntext="Save"
                type="submit"
                disabled={isSubmitting}
              />
              <button
                type="button"
                className="Commonredbtn"
                onClick={() => navigate("/admincontrol")}
              >
                Cancel
              </button>
            </div>
            {successMessage && <p className="text-success mt-3">{successMessage}</p>}
            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddAdmin;
