import React, { useEffect, useState } from "react";
import axios from "axios";
import westside from "../Assets/westside.png";
import gifziplus from "../Assets/gifziplus.png";
import SearchBar from "../Searchbar/Searchbar";
import "./Myorder.css";
import { Link } from "react-router-dom";

function Myorder() {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10); // Default items per page
  const [totalOrders, setTotalOrders] = useState(0); // Total number of orders for pagination
  const token = localStorage.getItem("authToken");

  const fetchOrderHistory = async (query = "") => {
    try {
      setLoading(true);
      const offset = (currentPage - 1) * limit;
  
      const url = query
        ? `${process.env.REACT_APP_BASE_API_URL}admin/order/search?offset=${offset}&limit=${limit}&search=${encodeURIComponent(query)}`
        : `${process.env.REACT_APP_BASE_API_URL}admin/order/all?offset=${offset}&limit=${limit}`;
  
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
  
      if (response.status === 200 && response.data.data?.orders?.length > 0) {
        const orders = response.data.data.orders;
        setOrderHistoryData(orders);
        setTotalOrders(response.data.data.totalOrders || 0);
        setError(null);
      } else {
        throw new Error("No results found."); // Trigger fallback if no results
      }
    } catch (err) {
      // Fallback to fetching all orders if search fails
      if (query) {
        try {
          const fallbackUrl = `${process.env.REACT_APP_BASE_API_URL}admin/order/all?offset=${(currentPage - 1) * limit}&limit=${limit}`;
          const fallbackResponse = await axios.get(fallbackUrl, {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          });
  
          if (fallbackResponse.status === 200) {
            const orders = fallbackResponse.data.data.orders;
            setOrderHistoryData(orders);
            setTotalOrders(fallbackResponse.data.data.totalOrders || 0);
            setError(null);
          } else {
            setError("Failed to fetch orders.");
          }
        } catch (fallbackErr) {
          setError("No Data Available.");
        }
      } else {
        setError("No Data Available.");
      }
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (token) {
      fetchOrderHistory();
    } else {
      setError("No authorization token found.");
      setLoading(false);
    }
  }, [token, currentPage, limit]);

  const handleSearch = (event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
    fetchOrderHistory(query); // Pass query to initiate search
  };
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when limit changes
  };


  if (error) {
    return (
      <div className="p-60 ml-14-d userdetail">
        <div className="container">
          <div className="Orderhistorydata">
            <h2 className="f-20">Order History </h2>
            <SearchBar
              searchtext="Search by Order Code"
              searchtype="text"
              searchclass="shadow-sm mt-3 mb-3"
              value={searchQuery}
              handleSearchChange={handleSearch}
            />
            <p className="f-18 f-weight-600 p-30 text-center">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  const totalPages = Math.ceil(totalOrders / limit);

  return (
    <div className="p-60 ml-14-d userdetail">
       
      <div className="container">
        <div className="Orderhistorydata ">
        <h2 className="f-20">Order History</h2>
          <SearchBar
            searchtext="Search by Order Code"
            searchtype="text"
            searchclass="shadow-sm mt-3 mb-3"
            value={searchQuery}
            handleSearchChange={handleSearch}
          />
          <div className="Orderhistorydata-list-main">
            <div className="row">
              {orderHistoryData.length > 0 ? (
                orderHistoryData.map((order, index) => (
                  <div key={index} className="col-md-3">
                    <Link to={`/orderdetail/${order.id}`} className="d-block">
                      <div className="mright-3 Orderhistorydata-item shadow-sm rounded-3">
                        <div className="Orderhistorydata-icon col-md-12">
                          <img
                            src={order.is_gifzi_plus_order === 1 ? gifziplus : order.thumbnail || westside}
                            alt="Order"
                            className="text-white f-16"
                          />
                        </div>
                        <div className="Orderhistorydata-content col-md-12">
                          <h4 className="f-20 f-weight-600 text-main mb-1">
                            Order id: <b className="text-main">{order.code}</b>
                          </h4>
                          <h4 className="f-20 f-weight-600 text-main mb-1">
                            Qty: <b className="text-main">{order.qty}</b>
                          </h4>
                          <p className="text-muted f-16">{order.order_dt}</p>
                        </div>
                        <div className="col-md-12 d-flex btn-list mt-1 mb-3">
                          <button
                            className={`btn-lg ${
                              order.status === 1
                                ? "success-status"
                                : order.status === 2
                                ? "failed"
                                : "pending"
                            }`}
                          >
                            Status:{" "}
                            {order.status === 1
                              ? "Success"
                              : order.status === 2
                              ? "Failed"
                              : "Pending"}
                          </button>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p>No orders found</p>
              )}
            </div>
          </div>

          {/* Pagination and Limit Dropdown */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select value={limit} onChange={handleLimitChange} className="form-select w-auto d-inline-block select-width">
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> orders per page</span>
            </div>
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary prev-btn" 
                >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="btn btn-secondary  next-btn"
                >
                 <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myorder;
