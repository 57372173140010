import React from 'react'
import List from '../Components/Blog/List'

export default function AllBlog() {
  return (
    <div>
        <List/>
    </div>
  )
}
