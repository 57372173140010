import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function QuerDetail() {
  const navigate = useNavigate();
  const [queryDetails, setQueryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const { id } = useParams();

  // API call to fetch query details
  useEffect(() => {
    const fetchQueryDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");

        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const response = await fetch(
          `${baseUrl}admin/cms/messages/detail?id=${id}`,
          {
            method: "GET",
            headers: headers,
          }
        );

        const data = await response.json();

        if (response.ok && data.status === 200) {
          setQueryDetails(data.data); // Directly set the object
        } else {
          setError(data.message || "Failed to fetch data");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchQueryDetails();
  }, [baseUrl, id]);

  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1); // Go back if history length is greater than 2
    } else {
      navigate("/dashboard"); // Navigate to dashboard if no history
    }
  };

  return (
    <div className="p-60 ml-14-d user-list query">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white shadow rounded-3">
          <div className="d-flex pb-3" style={{ backgroundColor: "#fff" }}>
          <i className="arrow-right" onClick={handleArrowClick}>
              <svg
                width="18px"
                height="15px"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_945_2412)">
                  <path
                    d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_945_2412">
                    <rect
                      width="18"
                      height="14"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg> 
            </i>
            <h3 className="grey-85 f-18 f-weight-600">Query Details</h3>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : queryDetails ? (
            <div style={{ backgroundColor: "#fff", padding: "10px" }}>
              <p>
                Date: <strong>{queryDetails.created_at || "N/A"}</strong>
              </p>
              <p>
                User Name:{" "}
                <strong>{queryDetails.user_name || "Unregistered"}</strong>
              </p>
              <p>
                Email: <strong>{queryDetails.email}</strong>
              </p>
              <p>
                Phone Number: <strong>{queryDetails.phone || "N/A"}</strong>
              </p>
              <p>
                Message: <strong>{queryDetails.message || "N/A"}</strong>
              </p>
            </div>
          ) : (
            <p>No query details available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuerDetail;

