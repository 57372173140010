import React, { useEffect, useState } from 'react';

function WriteEarnDetail() {
  const [detailData, setDetailData] = useState(null); // State to hold detail data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchDetailData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/write_earn/detail?id=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('authToken'), // Assuming token is needed
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.status === 200) {
        setDetailData(data.data); // Assuming the data is returned under data.data
      } else {
        setError('Failed to load details.');
        // console.error('Received data:', data);
      }
    } catch (error) {
      setError('Failed to fetch details.');
      // console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailData();
  }, []);

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <h3 className="title mb-4">Write & Earn Details</h3>
          <div className="row justify-content-center">
            <div className="col-md-8">
              {loading && <p>Loading...</p>}
              {error && <p className="text-danger">{error}</p>}
              {detailData && (
                <div className="card shadow-lg">
                  <div className="card-header bg-primary text-white">
                    <h5 className="mb-0">User: {detailData.name}</h5>
                  </div>
                  <div className="card-body">
                    <p className="f-16 f-weight-600 mb-2"><strong>Name:</strong> {detailData.name}</p>
                    <p className="f-16 f-weight-600 mb-2"><strong>Email:</strong> {detailData.email}</p>
                    <p className="f-16 f-weight-600 mb-2"><strong>Phone:</strong> {detailData.phone}</p>
                    <p className="f-16 f-weight-600 mb-2"><strong>Created on:</strong> {detailData.created_at}</p>

                    <h6 className="mt-4 mb-3 f-15 f-weight-600">Social Media Links</h6>
                    <ul className="list-group">
                      {detailData.urls.map((url, index) => (
                        <li className="list-group-item" key={index}>
                          {url.facebook && (
                            <div>
                              <strong>Facebook: </strong>
                              <a href={`https://${url.facebook}`} target="_blank" rel="noopener noreferrer">
                                {url.facebook}
                              </a>
                            </div>
                          )}
                          {url.instagram && (
                            <div>
                              <strong>Instagram: </strong>
                              <a href={`https://${url.instagram}`} target="_blank" rel="noopener noreferrer">
                                {url.instagram}
                              </a>
                            </div>
                          )}
                          {url.linkedin && (
                            <div>
                              <strong>LinkedIn: </strong>
                              <a href={`https://${url.linkedin}`} target="_blank" rel="noopener noreferrer">
                                {url.linkedin}
                              </a>
                            </div>
                          )}
                          {/* Add more URLs as needed */}
                          {url.other && (
                            <div>
                              <strong>Other: </strong>
                              <a href={`https://${url.other}`} target="_blank" rel="noopener noreferrer">
                                {url.other}
                              </a>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WriteEarnDetail;
