import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Searchbar.css';


const SearchBar = (props) => {
  const searchclass = `search-bar-container ${props.searchclass || ''}`.trim();
  return (
    <div className={searchclass}>
      <input
        type={props.searchtype}
        placeholder={props.searchtext}
        className='search-input'
        onChange={props.handleSearchChange} 
      />
      <button className="search-button">
        <FontAwesomeIcon icon={faSearch} className='f-16 greyf6'/>
      </button>
    </div>
  );
};

export default SearchBar;
