import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import dashboard from "../Assets/dashboard/dashboard.svg";
import iv from "../Assets/dashboard/iv.svg";
import card from "../Assets/dashboard/card.svg";
import "./Mydashboard.css";
import Table from "./Table";
import { Link } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";

function Mydashboard() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [todayOrder, setTodayOrder] = useState(0);
  const [todaySell, setTodaySell] = useState(0);
  const [totalgifzi, settotalgifzi] = useState(0);
  const currentYear = new Date().getFullYear();
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(currentYear, 0, 1),
    endDate: new Date(),
  });

  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_API_URL;

  const fetchData = async (from_date, to_date) => {
    try {
      const response = await fetch(
        `${baseUrl}admin/dashboard/data?from_date=${from_date}&to_date=${to_date}`,
        {
          method: "GET",
          headers: {
            authorization: token,
            "content-type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        setData(result.data.orders);
        setTodaySell(result.data.today_sell);
        setTodayOrder(result.data.today_orders);
        settotalgifzi(result.data.today_gifzi);
      } else {
        setError("Failed to fetch products.");
      }
    } catch (err) {
      setError("Error fetching products.");
    }
  };

  useEffect(() => {
    const from_date = selectionRange.startDate.toISOString().split("T")[0];
    const to_date = selectionRange.endDate.toISOString().split("T")[0];
    fetchData(from_date, to_date);
  }, [selectionRange]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard p-60 ml-14-d">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex justify-content-between align-items-center mob-dnone">
            <h1 className="title-d mb-3">Dashboard</h1>
            <div className="d-flex gap-2">
              {/* <Link to="/dashboard">
                <Commonbtn Commonbtntext="Report" />
              </Link> */}
              <Link to="/querylist">
                <Commonbtn Commonbtntext="Query" />
              </Link>
            </div>
          </div>

          <div className="pb-4">
            <div className="d-flex align-items-center">
              <ReactDatePicker
                selected={selectionRange.startDate}
                onChange={(date) => setSelectionRange((prev) => ({ ...prev, startDate: date }))}
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
              <span style={{ margin: "0 10px" }}>to</span>
              <ReactDatePicker
                selected={selectionRange.endDate}
                onChange={(date) => setSelectionRange((prev) => ({ ...prev, endDate: date }))}
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </div>
          </div>

          <div className="mt-5">
            <Table orders={data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mydashboard;
