import React from 'react'
import Myorder from '../Components/MyorderHistory/Myorder'

function OrderHistory() {
  return (
    <div>
        <Myorder/>
    </div>
  )
}

export default OrderHistory