import React from 'react';
import Heading from '../Heading/Heading';
import '../Commonbtn/Table.css';

function Table({ orders }) {
  // Check if orders is an array and contains data
  if (!orders || orders.length === 0) {
    return (
      <div className="bg-white table">
        <h3 className="grey-85 f-18 f-weight-600 bg-white py-2 border-bottom">Transaction Status</h3>
        <p className="text-center">No Transaction.</p>
      </div>
    );
  }

  return (
    <div className="bg-white table">
      <h3 className="grey-85 f-18 f-weight-600 bg-white py-2 border-bottom">Transaction Status</h3>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th scope="col">Order Date</th>
            <th scope="col">Order ID</th>
            <th scope="col">Card</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            // Determine Card Type
            const cardType =
              order.is_gifzi_plus_order === 1
                ? 'Gifzi Plus'
                : order.is_gifzi_plus_order === 0
                ? 'Gifzi Card'
                : '';
            const cardClass = order.is_gifzi_plus_order === 1 ? 'Gifzi Plus' : 'Gifzi Card';

            // Determine Status
            const statusClass =
              order.status === 1 ? 'success' : order.status === 0 ? 'pending' : 'failed';
            const statusLabel =
              order.status === 1 ? 'Completed' : order.status === 0 ? 'Pending' : 'Failed';

            return (
              <tr key={order.id}>
                <th scope="row">{order.order_dt}</th>
                <td>{order.code}</td>
                <td>
                  <span className={cardClass}>{cardType}</span>
                </td>
                <td>
                  <span className={statusClass}>{statusLabel}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
