import React, { useState, useEffect } from 'react';
import Input from '../Input/Input';
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import { useNavigate, useParams } from "react-router-dom";

function EditFaq() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); // Retrieve the FAQ ID from the URL parameters

  const baseUrl = process.env.REACT_APP_BASE_API_URL; // Ensure this is set in your environment variables
  const token = localStorage.getItem("authToken");

  // Fetch FAQ data by ID on component mount
  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/faq/detail?id=${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`, // Assuming Bearer token for authorization
          },
        });
        if (response.ok) {
          const result = await response.json();
          setQuestion(result.data.question);
          setAnswer(result.data.answer);
        } else {
          // console.error('Failed to fetch FAQ data.');
        }
      } catch (error) {
        // console.error('Error fetching FAQ:', error);
      }
    };

    fetchFaqData();
  }, [id, token, baseUrl]);

  // Handle form submission to update the FAQ
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const updatedFaqData = {
      id, // Use the existing ID from the URL
      question,
      answer,
    };

    try {
      const response = await fetch(`${baseUrl}admin/faq/update`, {
        method: 'POST', // Assuming you're using POST for updating the FAQ
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify(updatedFaqData),
      });

      if (response.ok) {
        // On success, navigate back to the FAQ list or show success message
        navigate('/faq'); // Redirect to FAQ list page after successful update
      } else {
        const errorData = await response.json();
        // console.error('Failed to update FAQ:', errorData);
        // Optionally handle errors
      }
    } catch (error) {
      // console.error('An error occurred while updating FAQ:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='p-60 ml-14-d userdetail'>
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit FAQ</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-12'>
                <Input 
                  placeholdertext="Edit the FAQ question" 
                  labeltext="Question" 
                  fortext="faqQuestion" 
                  typetext="text" 
                  value={question} 
                  onChange={(e) => setQuestion(e.target.value)} 
                  required
                />
              </div>
              <div className='col-md-12'>
                <Input 
                  placeholdertext="Edit the FAQ answer" 
                  labeltext="Answer" 
                  fortext="faqAnswer" 
                  typetext="text" 
                  value={answer} 
                  onChange={(e) => setAnswer(e.target.value)} 
                  required
                />
              </div>
              <div className='btn-flex-center d-flex gap-2'>
                <Commonbtn Commonbtntext="Save" disabled={isSubmitting} />
                <button 
                  type="button" 
                  className="Commonredbtn"
                  onClick={() => navigate('/faq')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditFaq;
