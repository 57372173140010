import React, { useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import home from "../Assets/dashboard/icon/home.svg";
import notification from "../Assets/dashboard/icon/Envelope.svg";
import user from "../Assets/dashboard/icon/ChatsCircle.svg";
import cms from "../Assets/dashboard/icon/cms.svg";
import pages from "../Assets/dashboard/icon/Page.svg";
import gift from "../Assets/dashboard/icon/Vector.svg";
import order from "../Assets/dashboard/icon/transaction-minus.svg";
import payment from "../Assets/dashboard/icon/Cardholder.svg";
import Category from "../Assets/dashboard/icon/ChartPie.svg";
import earn from "../Assets/dashboard/icon/Balance.svg";
import demo from "../Assets/dashboard/icon/StarFour.svg";
import query from "../Assets/dashboard/icon/Helpoutline.svg";
import report from "../Assets/dashboard/icon/UserCircle.svg";
import logout from "../Assets/dashboard/icon/logout.svg";
import closeIcon from "../Assets/Icon/close.svg";
import { TbMessageReport } from "react-icons/tb";
import {
  MdOutlineReviews,
  MdOutlinePeopleOutline,
  MdOutlineLocalOffer,
  MdOutlinePointOfSale,
} from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";

import { CiUser, CiImageOn, CiSettings } from "react-icons/ci";

import "./AdminSidebar.css";

function AdminSidebar({
  isOpen,
  toggleSidebar,
  toggleDropdown,
  handleCloseIconClick,
  handleMenuClick,
}) {
  const dropdownRef = useRef(null);
  const location = useLocation(); // Get the current route
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const userPhoneNumber = localStorage.getItem("userPhoneNumber");
  // Handle outside click to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);
  // Handle logout
  const handleLogout = async () => {
    try {
      const response = await fetch(`${baseUrl}admin/logout`, {
        method: "POST", // Use POST method
        headers: {
          "Content-Type": "application/json",
          Authorization: token, // Ensure the token is passed in the correct format
        },
      });

      if (response.ok) {
        // Remove tokens from localStorage or any state management
        localStorage.removeItem("authToken");
        navigate("/login"); // Redirect to login page
      } else {
        const data = await response.json(); // Parse the error response
        // console.error("Logout failed:", data.message);
      }
    } catch (error) {
      // console.error("Error during logout:", error);
    }
  };

  return (
    <ul
      className={`menu-dropdown shadow-sm dashboard-sidebar ${
        isOpen ? "open" : ""
      }`}
      ref={dropdownRef}
    >
      <li className="closeicon" onClick={() => toggleDropdown(false)}>
        <img
          src={closeIcon}
          alt="close"
          height="30px"
          width="30px"
          className="closeiconimg"
        />
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/dashboard" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/dashboard">
          <div className="d-flex align-items-center">
          <img
            src={home}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          />
          <span className="text-list">Dashboard</span>
          </div>
        </Link>
      </li>
      {userPhoneNumber === "9799866929" && (
                      <li
                      className={`menu-dropdown-list ${
                        location.pathname === "/admincontrol" ? "active" : ""
                      }`}
                      onClick={handleMenuClick}
                    >
                      <Link to="/admincontrol">
                        <div className="d-flex align-items-center">
                          <CiUser className="icon-wrapper" />
                          <span className="text-list">Admin Control</span>
                        </div>
                      </Link>
                    </li>
                    )}
     

      <li className={`menu-dropdown-list ${location.pathname === '/notification' ? 'active' : ''}`} onClick={handleMenuClick}>
        <Link to="/sendnotification">
          <img src={notification} className='menu-list-icon' alt='Order' height="60" width="100px" />
          <span className='text-list'>Notification</span>
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/userlist" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/userlist">
          {/* <img
            src={user}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          /> */}
          <div className="d-flex align-items-center">
            <CiUser className="icon-wrapper" />
            <span className="text-list">User/Customer List</span>
          </div>
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/pagelist" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/pagelist">
        <div className="d-flex align-items-center">
        <img
            src={cms}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          />
          <span className="text-list">CMS</span>
        </div>
          
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/faq" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/faq">
        <div className="d-flex align-items-center">
        <img
            src={pages}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          />
          <span className="text-list">Faq</span>
        </div>
          
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/flatoffer" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/flatoffer">
          {/* <img
            src={pages}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          /> */}
          <div className="d-flex align-items-center" >
          <MdOutlinePointOfSale className="icon-wrapper" />
          <span className="text-list">Flat discount</span>
          </div>
          
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/offer" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/offer">
          {/* <img
            src={pages}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          /> */}
          <div className="d-flex align-items-center" >
          <MdOutlineLocalOffer className="icon-wrapper" />
          <span className="text-list">Offer</span>
          </div>
          
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/giftcard" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/giftcard">

        <div className="d-flex align-items-center" >
          <img
            src={gift}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          />
          <span className="text-list">Gift Card Upload</span>
          </div>
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/orderhistory" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/orderhistory">
        <div className="d-flex align-items-center" >
          <img
            src={order}
            className="menu-list-icon"
            alt="Order"
            height="60"
            width="100px"
          />
          <span className="text-list">Order</span>
          </div>
        </Link>
      </li>

      <li
        className={`menu-dropdown-list ${
          location.pathname === "/allProducts" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/allProducts">
          {/* <img
            src={order}
            className="menu-list-icon"
            alt="AllProducts"
            height="60"
            width="100px"
          /> */}
          <div className="d-flex align-items-center" >
          <AiOutlineProduct className="icon-wrapper" />
          <span className="text-list">All Products</span>
          </div>
        </Link>
      </li>

      <li
        className={`menu-dropdown-list ${
          location.pathname === "/CustomCategory" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/CustomCategory">
        <div className="d-flex align-items-center" >
          <img
            src={Category}
            className="menu-list-icon"
            alt="Earn"
            height="60"
            width="100px"
          />
          <span className="text-list">Custom Category</span>
          </div>
        </Link>
      </li>
 {/*
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/category" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/category">
        <div className="d-flex align-items-center" >
          <img
            src={Category}
            className="menu-list-icon"
            alt="Earn"
            height="60"
            width="100px"
          />
          <span className="text-list">Category</span>
          </div>
        </Link>
      </li>
      <li className={`menu-dropdown-list ${location.pathname === '/category' ? 'active' : ''}`} onClick={handleMenuClick}>
        <Link to="/syncategory">
          <img src={Category} className='menu-list-icon' alt='Earn' height="60" width="100px" />
          <span className='text-list'>Sync Category</span>
        </Link>
      </li> */}
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/writeearn" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/writeearn">
        <div className="d-flex align-items-center" >
          <img
            src={earn}
            className="menu-list-icon"
            alt="Offers"
            height="60"
            width="100px"
          />
          <span className="text-list">Write & Earn</span>
          </div>
        </Link>
      </li>
      {/* <li className={`menu-dropdown-list ${location.pathname === '/schedule' ? 'active' : ''}`} onClick={handleMenuClick}>
        <Link to="/schedule">
          <img src={demo} className='menu-list-icon' alt='Help' height="60" width="100px" />
          <span className='text-list'>Demo Schedule</span>
        </Link>
      </li> */}
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/querylist" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/querylist">
        <div className="d-flex align-items-center" >
          <img
            src={query}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          />
          <span className="text-list">Customer Query</span>
          </div>
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/allblog" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/allblog">
        <div className="d-flex align-items-center" >
          <img
            src={report}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          />
          {/* <SiMicrodotblog className="icon-wrapper"  /> */}
          <span className="text-list">Blog</span>
          </div>
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/team" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/team">
          {/* <img
            src={report}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          /> */}
          <div className="d-flex align-items-center" >
          <MdOutlinePeopleOutline className="icon-wrapper" />

          <span className="text-list">Team</span>
          </div>
        </Link>
      </li>
      {/* <li
        className={`menu-dropdown-list ${
          location.pathname === "/dashboard" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/dashboard">
          <img
            src={report}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          /> 
          <div className="d-flex align-items-center" >
          <TbMessageReport className="icon-wrapper" />
          <span className="text-list">Report</span>
          </div>
        </Link>
      </li> */}

      <li
        className={`menu-dropdown-list ${
          location.pathname === "/reviews" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/reviews">
          {/* <img
            src={report}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          /> */}
          <div className="d-flex align-items-center" >
          <MdOutlineReviews className="icon-wrapper" />
          <span className="text-list">Reviews</span>
          </div>
        </Link>
      </li>
      <li
        className={`menu-dropdown-list ${
          location.pathname === "/staticimage" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/staticimage">
        <div className="d-flex align-items-center" >
          <CiImageOn className="icon-wrapper" />
          {/* <img
            src={report}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          /> */}
          <span className="text-list">Static Image</span>
          </div>
        </Link>
      </li>

      <li
        className={`menu-dropdown-list ${
          location.pathname === "/generalsettings" ? "active" : ""
        }`}
        onClick={handleMenuClick}
      >
        <Link to="/generalsettings">
        <div className="d-flex align-items-center" >
          <CiSettings className="icon-wrapper" />
          {/* <img
            src={report}
            className="menu-list-icon"
            alt="Help"
            height="60"
            width="100px"
          /> */}
          <span className="text-list">General Settings</span>
          </div>
        </Link>
      </li>

      <li
        className={`menu-dropdown-list ${
          location.pathname === "/" ? "active" : ""
        }`}
        onClick={handleLogout}
      >
        <Link to="/">
        <div className="d-flex align-items-center" >
        <img
          src={logout}
          className="menu-list-icon"
          alt="Help"
          height="60"
          width="100px"
        />
        <span className="text-list">Log out</span>
        </div>
        </Link>
      </li>
    </ul>
  );
}

export default AdminSidebar;
