import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { useNavigate, useParams } from "react-router-dom";
import "./Blog.css";

function EditBlog() {
  const editor = useRef(null);
  const [blogImage, setBlogImage] = useState();
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: " ",
    }),
    []
  );

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/blog/detail?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        if (response.ok) {
          const result = await response.json();

          if (result.data) {
            setBlogTitle(result.data.name); 
            setBlogDescription(result.data.description);
            setBlogImageUrl(result.data.image);

            setBlogImageUrl(result.data.image);
          } else {
            setErrorMessage("No data found.");
          }
        } else {
          setErrorMessage("Failed to fetch blog data.");
        }
      } catch (error) {
        setErrorMessage("Error fetching blog: " + error.message);
      }
    };

    fetchBlogData();
  }, [id, token, baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("id", id);
    formData.append("title", blogTitle);
    formData.append("description", blogDescription);

    if (blogImage) {
      formData.append("blog_image", blogImage);
    }

    try {
      const response = await fetch(`${baseUrl}admin/blog/update`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        // Reset fields after submission
        setBlogImage("");
        setBlogTitle("");
        setBlogDescription("");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/allblog");
        }, 2000);
      } else {
        setErrorMessage("Failed to update blog");
      }
    } catch (err) {
      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit Blog</h3>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Choose an image"
                  labeltext="Blog Image"
                  fortext="blogImage"
                  typetext="file"
                  onChange={(e) => {
                    setBlogImage(e.target.files[0]);
                    setBlogImageUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {blogImageUrl && (
                  <img
                    src={blogImageUrl}
                    alt="Preview"
                    height="100"
                    style={{ borderRadius: "10px", paddingBottom: "10px",}}
                  />
                )}
              </div>
              <div className="col-md-12 py-10">
                <Input
                  placeholdertext="Enter Blog Title"
                  labeltext="Blog Title"
                  typetext="text"
                  onChange={(e) => setBlogTitle(e.target.value)}
                  value={blogTitle}
                />
              </div>

              <div className="col-md-12 pb-4 pt-2">
                <label htmlFor="blogDescription" className="form-label">
                  Blog Description
                </label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={blogDescription}
                  onBlur={(newContent) => setBlogDescription(newContent)}
                  onChange={(newContent) => setBlogDescription(newContent)}
                />
              </div>
              <div className="btn-flex-center d-flex gap-2 pt-10">
                <Commonbtn
                  Commonbtntext="Save"
                  type="submit"
                  disabled={isSubmitting}
                />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate("/blog")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditBlog;
