import React from 'react'
import Input from '../Input/Input';
import { Link } from "react-router-dom";
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
function Addgiftcard() {
  return (
    <div>
    <div className='p-60 ml-14-d userdetail'>
 <div className="container">

   <div className="main-bg-off-white bg-off-white">

   <div className="d-flex mb-3 gap-2">
   <ArrowRedirect/>
       <h3 className="grey-85 f-18 f-weight-600">Add offer</h3>
       </div>
       <div className='row'>
           <div className='col-md-12'>
             <Input placeholdertext="h&m" labeltext="Coupon Brand / company " fortext="bankAccount" typetext="text" />
           </div>
           <div className='col-md-12'>
             <Input placeholdertext="Shop in Style!" labeltext="Title" fortext="bankAccount" typetext="text" />
           </div>
           <div className='col-md-12'>
             <Input placeholdertext="rs.2000" labeltext="Price" fortext="bankAccount" typetext="text" />
           </div>
           <div className='col-md-12'>
             <Input placeholdertext="rs.20% off" labeltext="Offer" fortext="bankAccount" typetext="text" />
           </div>
           <div className='col-md-12'>
             <Input placeholdertext="Get heavy discount on the H&M app" labeltext="Description" fortext="bankAccount" typetext="text" />
           </div>
           <div className=' btn-flex-center d-flex gap-2'>
             <Commonbtn Commonbtntext="Save" />
             <button type="button" className="Commonredbtn">Cancel</button>
           </div>
        </div>
  
   </div>
</div>
</div>
</div>
  )
}

export default Addgiftcard