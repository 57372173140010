import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";


function SectionDetail() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0); // Initialize offset
  const [totalItems, setTotalItems] = useState(0); // Total number of items from the API
  const [isFetching, setIsFetching] = useState(false); // Control fetching state
  const [hasMoreData, setHasMoreData] = useState(true); // Track if there's more data
  const token = localStorage.getItem("authToken");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const limit = 10; // Number of users to fetch per request
  const { id } = useParams();

  // Fetch data when offset changes
  const fetchData = async () => {
    if (!token || !hasMoreData) return;

    setIsFetching(true);

    try {
      const response = await fetch(
        `${baseUrl}admin/cms/sections?page_id=${id}&offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result && result.data && result.data.sections.length > 0) {
          setData((prevData) => [...prevData, ...result.data.sections]);
          setTotalItems(result.data.totalItems);
        } else {
          setHasMoreData(false);
        }
      } else {
        const errorData = await response.json();
        // console.error("Failed to fetch data:", response.statusText, errorData);
      }
    } catch (error) {
      // console.error("An error occurred while fetching data:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offset]);

  // Pagination handlers
  
  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="p-60 ml-14-d user-list">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex bg-white">
            <div className="col-12 d-flex align-items-center">
              <div className="d-flex align-items-center flex-grow-1 ">
                <i className="arrow-left me-2 cursor-pointer" onClick={handleArrowClick}>
                  <svg
                    width="18px"
                    height="15px"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_945_2412)">
                      <path
                        d="M17 7.5H1M1 7.5L7 1.5M1 7.5L7 13.5"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_945_2412">
                        <rect
                          width="18"
                          height="14"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                <h3 className="text-dark f-18 f-weight-600 mb-0">Section List</h3>
              </div>
              <div className="btn-flex-center d-flex gap-2 pb-20">
              <Link to={`/addlist/${id}`}>
                  <Commonbtn Commonbtntext="+ Add List" />
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">Pages</th>
                <th scope="col">Status</th>
                <th scope="col">Section</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">
                     
                    
                        {item.title}
                     
                    </th>
                    <td>
                      <span
                        className={item.status === 1 ? "active" : "inactive"}
                      >
                        {item.status === 1 ? "active" : "inactive"}
                      </span>
                    </td>
                    <td>
                      <Link
                        to={`/sectioncontent/${id}/${item.id}`}
                        className="table-row-link"
                      >
                        <MdEdit className="edit-icon" />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No Pages found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isFetching && <p>Loading more sections...</p>}
        </div>
      </div>
    </div>
  );
}

export default SectionDetail;
