import React, { useState } from "react";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { AiOutlineClose } from "react-icons/ai";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import axios from "axios";

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryName || !categoryImage) {
      alert("Please fill all fields");
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("category_image", categoryImage);

    try {
      const response = await axios.post(
        `${baseUrl}admin/category/sub/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:token
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Category added successfully");
        // Clear form fields after successful submission
        setCategoryName("");
        setCategoryImage(null);
      }
    } catch (error) {
      // console.error("Error adding category:", error);
      alert("Failed to add category. Please try again.");
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Category</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Enter category name"
                  labeltext="Category Name"
                  fortext="category"
                  typetext="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <Input
                  placeholdertext="Choose an image"
                  labeltext="Category Image"
                  fortext="categoryImage"
                  typetext="file"
                  onChange={(e) => setCategoryImage(e.target.files[0])}
                />
              </div>

              <div className="btn-flex-center d-flex gap-2">
                <Commonbtn Commonbtntext="Save" type="submit" />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => {
                    setCategoryName("");
                    setCategoryImage(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
