import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { useNavigate, useParams } from "react-router-dom";
import "./Media.css";

function EditMedia() {
  const editor = useRef(null);
  const [mediaImage, setMediaImage] = useState();
  const [mediaImageUrl, setMediaImageUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [altText, setAltText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageId, setPageId] = useState('');
  const navigate = useNavigate();
  const { pgeid, id } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: " ",
    }),
    []
  );

  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/cms/media/detail?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        if (response.ok) {
          const result = await response.json();

          if (result.data) {
            setMediaType(result.data.media_type); 
            setAltText(result.data.alt_text);
            setMediaImageUrl(result.data.media_url);
            setPageId(result.data.page_id);
          } else {
            setErrorMessage("No data found.");
          }
        } else {
          setErrorMessage("Failed to fetch media data.");
        }
      } catch (error) {
        setErrorMessage("Error fetching media: " + error.message);
      }
    };

    fetchMediaData();
  }, [id, token, baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
  
    const formData = new FormData();
    formData.append("id", id);  // Add media ID
    formData.append("page_id", pageId);  // Make sure you are using the correct pageId here
    formData.append("media_type", mediaType);
    formData.append("alt_text", altText);
  
    // Check if a new media image has been uploaded, otherwise use the existing URL
    if (mediaImage) {
      formData.append("media_image", mediaImage);  // New image
    } else if (mediaImageUrl) {
      formData.append("media_url", mediaImageUrl);  // Existing image URL if no new image
    }
  
    try {
      const response = await fetch(`${baseUrl}admin/cms/media/update`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        // Reset fields after submission
        setMediaImage("");
        setMediaType("");
        setAltText("");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate(`/allmedia/${pgeid}`);
        }, 300);
      } else {
        setErrorMessage("Failed to update media");
      }
    } catch (err) {
      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit Media</h3>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Choose an image"
                  labeltext="Media Image"
                  fortext="mediaImage"
                  typetext="file"
                  onChange={(e) => {
                    setMediaImage(e.target.files[0]);
                    setMediaImageUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {mediaImageUrl && (
                  <img
                    src={mediaImageUrl}
                    alt="Preview"
                    height="100"
                    style={{ borderRadius: "10px", paddingBottom: "10px" }}
                  />
                )}
              </div>
              <div className='col-md-12'>
  <label htmlFor="mediaType" className="form-label">Media Type</label>
  <select 
    id="mediaType"
    className="form-control"
    value={mediaType}
    onChange={(e) => setMediaType(e.target.value)}
  >
    <option value="">Select Media Type</option>
    <option value="banner">Banner</option>
    <option value="brand">Brand</option>
  </select>
</div>



<div className="col-md-12 pb-4 pt-2">
  <label htmlFor="altText" className="form-label">
    Alt Text
  </label>
  <textarea
    id="altText"
    className="form-control"
    value={altText}
    onChange={(e) => setAltText(e.target.value)} // Use e.target.value for input
    rows="4" // Optional: Adjust the number of rows for the textarea
  />
</div>

              <div className="btn-flex-center d-flex gap-2 pt-10">
                <Commonbtn
                  Commonbtntext="Save"
                  type="submit"
                  disabled={isSubmitting}
                />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate(`/allmedia/${id}`)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditMedia;
