import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import axios from "axios";

const UpdateCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  // Retrieve data passed via Link's state
  const { id, name, thumbnail } = location.state || {};
  const [categoryName, setCategoryName] = useState(name || "");
  const [categoryImage, setCategoryImage] = useState(null);
  const [existingImage, setExistingImage] = useState(thumbnail || "");

  // Handle form submission for updating category
  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!categoryName) {
      alert("Category name is required");
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("id", id); // The category ID
    formData.append("name", categoryName);
    if (categoryImage) {
      formData.append("category_image", categoryImage); // Append new image if uploaded
    }

    try {
      const response = await axios.post(
        `${baseUrl}admin/category/sub/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        alert("Category updated successfully");
        navigate("/CustomCategory"); // Redirect to the categories list page
      }
    } catch (error) {
      // console.error("Error updating category:", error);
      alert("Failed to update category.");
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Update Category</h3>
          </div>
          <form onSubmit={handleUpdate}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Enter category name"
                  labeltext="Category Name"
                  fortext="category"
                  typetext="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="existingImage">Existing Image:</label>
                {existingImage && (
                  <div className="mb-2">
                    <img
                      src={existingImage}
                      alt="Existing Category"
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                  </div>
                )}
                <Input
                  placeholdertext="Choose a new image (optional)"
                  labeltext="Category Image"
                  fortext="categoryImage"
                  typetext="file"
                  onChange={(e) => setCategoryImage(e.target.files[0])}
                />
              </div>

              <div className="btn-flex-center d-flex gap-2">
                <Commonbtn Commonbtntext="Update" type="submit" />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate("/CustomCategory")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateCategory;
