import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import "./Product.css";

function GiftCardDetail() {
  const [primaryOwner, setPrimaryOwner] = useState(null);
  const [otherOwners, setOtherOwners] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}admin/gifzi_plus/detail?id=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setPrimaryOwner(data.data.primary_owner || null);
          setOtherOwners(data.data.other_owner || []);
          setTransactions(data.data.transactions || []);
        } else {
          const errorData = await response.json();
          setError(
            ` ${errorData.message || "Unknown error"}`
          );
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
        // console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, token, id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <div className="p-60 ml-14-d userdetail">
          <div className="container">
            <div className="main-bg-off-white bg-off-white product-list">
              <section className="faq-body">
                <div className="container">
                  <div className="d-flex-center justify-content-between pb-3">
                    {error}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="p-60 ml-14-d userdetail">
        <div className="container">
          <div className="main-bg-off-white bg-off-white product-list">
            <section className="faq-body1">
              <div className="container">
                <div className="d-flex-center justify-content-between pb-3">
                  <div className="d-flex gap-2">
                    <ArrowRedirect />
                    <h3 className="grey-85 f-18 f-weight-600">
                      Gift Card Details
                    </h3>
                  </div>
                </div>

                <div className="row">
                  <div className="gift-card" data-aos="fade-right">
                    {/* Primary Owner Details */}
                    {primaryOwner && (
                      <div className="card d-block border-0">
                        <h4>Primary Owner</h4>
                        <table className="table table-bordered">
                          <tbody>
                           
                            <tr>
                              <td>
                                <strong>Full Name:</strong>
                              </td>
                              <td>
                                {primaryOwner.first_name} {primaryOwner.last_name}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Email:</strong>
                              </td>
                              <td>{primaryOwner.email}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Mobile:</strong>
                              </td>
                              <td>{primaryOwner.mobile}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Card Number:</strong>
                              </td>
                              <td>{primaryOwner.card_number}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Expiry Date:</strong>
                              </td>
                              <td>{primaryOwner.expiry}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    {/* Other Owners */}
                    {otherOwners.length > 0 && (
                      <div className="card d-block border-0 mt-4">
                        <h4>Secondary Owners</h4>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                            
                              <th>Full Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Card Number</th>
                              <th>Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {otherOwners.map((owner) => (
                              <tr key={owner.user_id}>
                                
                                <td>
                                  {owner.first_name} {owner.last_name}
                                </td>
                                <td>{owner.email}</td>
                                <td>{owner.mobile}</td>
                                <td>{owner.card_number}</td>
                                <td>{owner.expiry}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {/* Transactions */}
                    <div className="card d-block border-0 mt-4">
                      <h4>Transactions</h4>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                           
                            <th>Transaction Type</th>
                            <th>Order ID</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.length > 0 ? (
                            transactions.map((transaction) => (
                              <tr key={transaction.id}>
                             
                                <td>
                                  {transaction.type === 1 ? "Credit" : "Debit"}
                                </td>
                                <td>{transaction.order_code || "NA"}</td>
                                <td>{transaction.amount}</td>
                                <td>
                                  <span
                                    className={
                                      transaction.order_status === 1
                                        ? "success"
                                        : transaction.order_status === 0
                                        ? "pending"
                                        : "text-danger"
                                    }
                                  >
                                    {transaction.order_status === 1
                                      ? "Completed"
                                      : transaction.order_status === 0
                                      ? "Pending"
                                      : "Cancelled"}
                                  </span>
                                </td>
                                <td>{transaction.transaction_dt}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No transactions available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftCardDetail;
