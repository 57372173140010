import React, { useState } from "react";
import icon from "../../Assets/Authentication/icon.svg";
import card from "../../Assets/gifzicard.svg";
import "./Form.css";
import Input from "../../Input/Input";
import Commonbtn from "../../Commonbtn/Commonbtn";
import Logo from "../../Logo/Logo";
import { Link, useNavigate } from "react-router-dom";
import Password from "../../Input/Password"; // Reused Password component

function Form() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    salutation: "Mr.",
  });

  const [error, setError] = useState(null); // To handle error messages
  const navigate = useNavigate(); // Hook to programmatically navigate

  // Email validation function
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "number" && name === "mobile") {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (formData.mobile.length !== 10) {
      setError("Mobile number must be exactly 10 digits.");
      return;
    }

    try {
      // Using a CORS proxy for development
      const response = await fetch('http://65.2.89.115:3000/api/user/register/request', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: formData.first_name,
          last_name: formData.last_name,
          mobile: formData.mobile,
          email: formData.email,
          password: formData.password,
          user_type: formData.user_type,
          salutation: formData.salutation,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        navigate("/verification", { state: { mobile: formData.mobile , firstName: result.first_name  ,  otp: result.data.otp } });
        // navigate("/verification");
      } else {
        setError(result.message || "Registration failed");
      }
    } catch (err) {
      setError("Something went wrong, please try again.");
    }
  };

  return (
    <section className="authentication register">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="form-left">
              <form id="survey-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-head col-md-12">
                    <div className="logo-for desk-none">
                      <Logo />
                    </div>
                    <h1 className="f-40 mb-2">Register</h1>
                    <p className="f-18 f-weight-600">
                      Already have an account?{" "}
                      <Link to="/" className="text-decoration-underline text-main">
                        Login now
                      </Link>
                    </p>
                  </div>

                  <div className="col-md-12 role">
                                        <p className='f-16 f-weight-600 mb-2'>Select your role here</p>
                                        <div className="d-flex">
                                            <div className="form-check1">
                                                <input 
                                                    type="radio" 
                                                    className="checkbox-round" 
                                                    name="user_type" 
                                                    value="2" 
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-check-label m-0">Customer</label>
                                            </div>
                                            <div className="form-check">
                                                <input 
                                                    type="radio" 
                                                    className="checkbox-round" 
                                                    name="user_type" 
                                                    value="3" 
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-check-label m-0">Corporate user</label>
                                            </div>
                                        </div>
                                    </div>


                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your first name"
                      labeltext="First Name"
                      fortext="first_name"
                      typetext="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      maxLength={50}
                    />
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your last name"
                      labeltext="Last Name"
                      fortext="last_name"
                      typetext="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      maxLength={50}
                    />
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your mobile number"
                      labeltext="Mobile Number"
                      fortext="mobile"
                      typetext="number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      maxLength={10} // Restrict input to 10 digits
                    />
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your email"
                      labeltext="Email"
                      fortext="email"
                      typetext="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <Password
                      placeholdertext1="Enter your password"
                      labeltext1="Password"
                      fortext1="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <Password
                      placeholdertext1="Confirm your password"
                      labeltext1="Confirm Password"
                      fortext1="confirmPassword"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                    />
                  </div>

                  {error && <div className="error-message">{error}</div>}

                  <div className="col-md-12 d-flex-center btn-form">
                    <Commonbtn Commonbtntext="Sign up" />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-5 form-wrap">
            <div className="form-right-sec">
              <div className="logo-for">
                <Logo />
              </div>
              <div className="form-right position-relative">
                <h3 className="f-30 f-weight-600 text-main mb-3">E-Gift Vouchers</h3>
                <p className="f-16">
                  Use your gift card around the world with no hidden fees. Hold, transfer, and spend money.
                </p>
                <div className="card-des">
                  <img src={card} alt="Gift card" className="img-fluid" />
                </div>
              </div>
              <div className="card-design shadow-lg rounded-2 row d-flex-center text-start p-2">
                <div className="icon col-md-4">
                  <img src={icon} alt="Icon" className="img-fluid" />
                </div>
                <div className="right col-md-8">
                  <span className="f-14">Gifzi</span>
                  <p className="f-16 f-weight-600">Lorem Ipsum</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
