import React, { useEffect, useMemo, useRef, useState } from "react";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";

const EditOfferdiscount = () => {
  const { sku, itemid } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const editor = useRef(null);

  // State management
  const [id, setId] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [desc, setDesc] = useState("");
  const [offerData, setOfferData] = useState(null);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [productArray, setProductArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(false); // New state for toggle

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/offer/products`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProductArray(data.data.products);
        } else {
          setErrorMessage("Failed to fetch products.");
        }
      } catch (error) {
        setErrorMessage("Error fetching products: " + error.message);
      }
    };

    fetchProducts();
  }, [baseUrl, token]);

  const fetchEditOffer = async () => {
    try {
      const response = await fetch(
        `${baseUrl}admin/discount/detail?id=${itemid}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const data = result.data;
        setOfferData(data);

        setId(itemid || "");
        setDiscountAmount(data?.value || "");
        setValue(data?.sku || "");
        setDesc(data?.desc || "");
        setIsActive(data?.status === 1); // Set initial toggle state based on API response
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to fetch offer data.");
      }
    } catch (err) {
      setError("Error while fetching offers: " + err.message);
    }
  };

  useEffect(() => {
    fetchEditOffer();
  }, [sku]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const updatedOfferData = {
      id: itemid,
      value: discountAmount,
      desc: desc,
    };

    try {
      const response = await fetch(`${baseUrl}admin/discount/update`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedOfferData),
      });
      const data = await response.json();
      
      if (response.ok) {
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate(`/editOfferdiscount/${itemid}`, { state: { updated: true } });
        }, 2000);
      } else if (data.errors.errors && Array.isArray(data.errors)) {
        // Handle array of errors
        const errorMessage = data.errors.errors.map(err => err.msg).join(', ');
        setError(errorMessage || 'Please try again.');
    } else {
      const errorMessage = data.errors.errors.map(err => err.msg).join(', ');
      setError(errorMessage || ' Please try again.');
    }
    } catch (err) {
      setError("Error while updating the offer: " + err.message);
    } finally {
      setIsSubmitting(false);
    }
  };


  const handleStatusChange = async () => {
    const newStatus = isActive ? 0 : 1; // Toggle status
    try {
      const response = await fetch(
        `${baseUrl}admin/discount/changeStatus?id=${itemid}&status=${newStatus}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.ok) {
        setIsActive(!isActive); // Update the toggle state
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      } else {
        setError("Failed to update the status.");
      }
    } catch (err) {
      setError("Error updating the status: " + err.message);
    }
  };


  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit Discount</h3>
          </div>
          {error && <p className="text-danger">{error}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12 my-3">
                  <label className="text-center">Product</label>
                  <select
                    value={value}
                    className="form-control"
                    style={{
                      padding: "10px 10px",
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #F5F5F5",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      color: "grey",
                    }}
                  >
                    <option value="">Select Product</option>
                    {productArray.map((item) => (
                      <option value={item.sku} key={item.sku}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 my-3">
                  <Input
                    placeholdertext="Enter discount amount"
                    labeltext="Discount %"
                    typetext="text"
                    maxLength={3}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                    value={discountAmount}
                  />
                </div>
                <div className="col-md-12 my-3">
                  <label htmlFor="description" className="form-label">
                    Discount Description
                  </label>
                  <JoditEditor
                  placeholdertext="test"
                    ref={editor}
                    value={desc}
                    onChange={(newContent) => setDesc(newContent)}
                  />
                </div>
                <div className="col-md-12 my-3">
                  <label>Status</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isActive}
                      onChange={handleStatusChange}
                    />
                    <label className="form-check-label">
                      {isActive ? "Active" : "Inactive"}
                    </label>
                  </div>
                </div>
                <div className="btn-flex-center d-flex gap-2 pt-10">
                  <Commonbtn
                    Commonbtntext="Save"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  <button
                    type="button"
                    className="Commonredbtn"
                    onClick={() => navigate(`/flatoffer`)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>

          {showPopup && (
            <div className="popup">
              <p>Successfully Updated</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditOfferdiscount;
