import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get URL parameters
import "./Media.css";
import Commonbtn from "../Commonbtn/Commonbtn";
import { Link } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import westside from "../Assets/westside.png";

function ListMedia() {
  const { id } = useParams(); // Get pageId from the URL
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      setLoading(true);
      setError(null);

      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/cms/media?page_id=${id}&offset=0&limit=10`; // Use the pageId from the URL
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(baseUrl, {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();

        if (response.ok) {
          setBlogData(result.data.medias);
        } else {
          setError(result.message || "Failed to fetch blogs");
        }
      } catch (err) {
        setError("Something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [id]); // Re-fetch data when pageId changes

  const toggleStatus = async (id, currentStatus) => {
    const token = localStorage.getItem("authToken");
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}admin/cms/media/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setBlogData((prevData) =>
          prevData.map((blog) =>
            blog.id === id ? { ...blog, status: newStatus } : blog
          )
        );
      } else {
        alert(result.message || "Failed to update status");
      }
    } catch (err) {
      alert("Something went wrong. Please try again.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <div className="p-60 ml-14-d userdetail">
        <div className="container">
          <div className="main-bg-off-white bg-off-white d-flex-center flex-wrap">
            <Link to={`/addmedia/${id}`}>
              <Commonbtn Commonbtntext="+Add Media" />
            </Link>
            <p className="error-message w-100 mt-4 f-18 f-weight-600">
              {error}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <section className="blog-body">
            <div>
              <div className="d-flex-center justify-content-between pb-3">
                <div className="d-flex gap-2 align-items-center">
                  <ArrowRedirect />
                  <h3 className="grey-85 f-18 f-weight-600">Media</h3>
                </div>
                <Link to={`/addmedia/${id}`}>
                  <Commonbtn Commonbtntext="+Add Media" />
                </Link>
              </div>
              <div className="row">
                {blogData.length === 0 ? (
                  <div className="p-60 ml-14-d userdetail">
                    <div className="container">
                      <div className="main-bg-off-white bg-off-white">
                        <p className="no-blogs-message">No media available now.</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  blogData.map((media, index) => (
                    <div
                      key={index}
                      className="col-md-4 d-flex position-relative pb-20"
                    >
                      <div className="media-card bg-white p-2">
                        <i className="media-icon">
                          <img
                            className="rounded"
                            src={media.media_url || westside} // use media_url from API
                            alt={'Media'} // use alt_text from API
                            height="100%"
                            width="100%"
                          />
                        </i>
                        <h3 className="f-20 f-weight-600">
                          {media.alt_text
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .replace(/&nbsp;/g, " ")}
                        </h3>
                        <p className="f-14 grey-85 mb-1 mt-1">
                          Media Type: {media.media_type} {/* display media type */}
                        </p>
                  
                        <div className="action-row d-flex justify-content-between">
                          <div
                            className="status-toggle"
                            onClick={() => toggleStatus(media.id, media.status)}
                          >
                            <p
                              className={`status-indicator mt-2 ${
                                media.status === 0 ? "inactive" : "active"
                              }`}
                            >
                              {media.status === 1 ? "Active" : "Inactive"}
                            </p>
                          </div>
                          <div className="action-buttons d-flex">
                            <Link
                              to={`/editmedia/${id}/${media.id}`}
                              className="edit-icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 26 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.9266 1.63387C16.4399 0.169407 18.8934 0.169404 20.4067 1.63387L23.2117 4.34834C24.7249 5.81281 24.7249 8.18717 23.2117 9.65164L11.7434 20.75H24.125C24.8384 20.75 25.4167 21.3096 25.4167 22C25.4167 22.6903 24.8384 23.25 24.125 23.25H2.16667C1.4533 23.25 0.875 22.6903 0.875 22V15.75C0.875 15.4185 1.01109 15.1005 1.25332 14.8661L14.9266 1.63387ZM8.08997 20.75L18.4233 10.75L13.7917 6.26775L3.45833 16.2678V20.75H8.08997ZM15.6184 4.49999L20.25 8.98222L21.385 7.88387C21.8894 7.39571 21.8894 6.60426 21.385 6.11611L18.58 3.40164C18.0756 2.91348 17.2577 2.91348 16.7533 3.40164L15.6184 4.49999Z"
                                  fill="black"
                                />
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ListMedia;
