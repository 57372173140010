import React, { useState, useEffect } from 'react';
import Input from '../Input/Input';
import Select from '../Input/Select';
import './Myprofile.css';
import Commonbtn from '../Commonbtn/Commonbtn';
import profileimage from '../Assets/Icon/profile-image.png';
import camera from '../Assets/Icon/camera.svg';

function Myprofile() {
  const citylist = ["Select a City", "Udaipur", "Jaipur", "Jodhpur"];
  const statelist = ["Select a State", "Rajasthan", "Gujarat"];
  
  const [id, setUserId] = useState('');
  const [selectedCity, setSelectedCity] = useState(citylist[0]);
  const [selectedState, setSelectedState] = useState(statelist[0]);
  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setContactNumber] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [old_password, setold_password] = useState('');
  const [password, setpassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const userPhoneNumber = localStorage.getItem("userPhoneNumber");


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('authToken'); 
      const id = localStorage.getItem('userId');
      if (id) {
        setUserId(id);
      }
     
      if (!token) {
        setError('Authentication token is missing.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        if (response.ok) {
          const result = await response.json();
          const userData = result.data?.userDetail;

          // console.log('userDetail',userData)
          // console.log('firstname',userData.first_name);

          setfirst_name(userData.first_name || '');
          // console.log('first_name',first_name);
          setlast_name(userData.last_name || '');
          setEmail(userData.email || '');
          setContactNumber(userData.mobile || '');
          setAddress(userData.address || '');
          setSelectedCity(userData.city || citylist[0]);
          setSelectedState(userData.state || statelist[0]);
        } else {
          setError('Failed to load user data.');
        }
      } catch (err) {
        setError('An error occurred while fetching user data.');
      }
    };

    fetchUserData();
  }, []);

  const handlePersonalDetailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const token = localStorage.getItem('authToken'); 

    const personalData = {
      salutation: 'Mr',
      first_name,
      last_name,
      email,
      mobile,
      address,
      city: selectedCity,
      state: selectedState,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}user/editPersonalDetail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(personalData),
      });

      if (response.ok) {
        alert('Personal details updated successfully');
      } else {
        const result = await response.json();
        setError(result.message || 'Failed to update personal details. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordchange = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (password !== confirmPassword) {
      setError('New password and Confirm Password do not match.');
      setLoading(false);
      return;
    }
  


    const token = localStorage.getItem('authToken'); 

    const personalData = {
      old_password,
      password,
      
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/password/change`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(personalData),
      });
      const result = await response.json();
      if (response.ok) {
        alert('Personal details updated successfully');
      } 
      else if (result.errors && Array.isArray(result.errors)) {
        // Handle array of errors
        const errorMessage = result.errors.map(err => err.msg).join(', ');
        setError(errorMessage || 'Login failed. Please try again.');
    } else {
        setError(result.message || 'Login failed. Please try again.');
    }
     
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const calculateCompletionPercentage = () => {
      const fields = [first_name, last_name, email, mobile, address, selectedCity, selectedState, id];
      const filledFields = fields.filter(field => field && field !== 'Select a City' && field !== 'Select a State');
      const percentage = Math.round((filledFields.length / fields.length) * 100);
      setCompletionPercentage(percentage);
    };

    calculateCompletionPercentage();
  }, [first_name, last_name, email, mobile, address, selectedCity, selectedState, id]);

  return (
    <div className='container'>
<div className='bg-fb my-profile'>
        <div className='row profileimage'>
          <div className='col-md-12 d-flex-center'>
            <img src={profileimage} className='profile-image' alt='profile' height="130px" width="130px" />
            <span className='progress-bar'>{completionPercentage}%</span>
            <i className='profile-cameraicon shadow-sm'>
              <img src={camera} className='profile-camera' alt='camera' height="40" width="40px" />
            </i>
          </div>
        </div>

        <div className='personal-detail'>
          <h2 className='f-28 text-main mb-3 f-weight-600'>Personal Detail</h2>
          <form onSubmit={handlePersonalDetailSubmit}>
            <div className='row'>
              <div className='col-md-6 form-group '>
                <label htmlFor='name' className='form-label'> Name</label>
                <input
                className='border shadow-sm form-control form-control-lg'
                  placeholdertext="Enter your First name"
                  name="first_name"
                  labeltext="First Name"
                  fortext="name"
                  typetext="text"
                  value={first_name}
                  readOnly    
                 
                />
              </div>
              <div className='col-md-6 form-group '>
              <label htmlFor='last_name' className='form-label'>Last Name</label>
                <input
                className='border shadow-sm form-control form-control-lg'
                  placeholdertext="Enter your Last name"
                  name="last_name"
                  labeltext="Last Name"
                  fortext="name"
                  typetext="text"
                  value={last_name}
                  readOnly
                
                />
              </div>
              <div className='col-md-6 form-group '>
              <label htmlFor='email' className='form-label'> Email</label>
                <input
                className='border shadow-sm form-control form-control-lg'
                  placeholdertext="Email"
                  labeltext="Email"
                  name="email"
                  fortext="email"
                  typetext="email"
                  value={email}
                  readOnly
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='col-md-6 form-group '>
              <label htmlFor='number' className='form-label'> Number</label>
                <input
                className='border shadow-sm form-control form-control-lg'
                  placeholdertext="9876543210"
                  name="mobile"
                  labeltext="Contact Number"
                  fortext="number"
                  typetext="tel"
                  value={mobile}
                 readOnly
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
        {error && <div className="error-message text-danger fs-6">{error}</div>}

        {userPhoneNumber === "9799866929" && (
  <div className='personal-detail'>
    <h2 className='f-28 text-main mb-3 f-weight-600'>Password</h2>
    <form onSubmit={handlePasswordchange}>
      <div className='row'>
        <div className='col-md-6'>
          <Input
            placeholdertext="Old Password"
            name="old_password"
            labeltext="Old Password"
            fortext="name"
            typetext="password"
            value={old_password}
            maxLength={10}
            onChange={(e) => setold_password(e.target.value)}
          />
        </div>
        <div className='col-md-6'>
          <Input
            placeholdertext="Enter your Password"
            name="password"
            labeltext="New Password"
            fortext="name"
            typetext="password"
            value={password}
            maxLength={10}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
        <div className='col-md-12'>
          <Input
            placeholdertext="Confirm your Password"
            name="confirm_password"
            labeltext="Confirm Password"
            fortext="name"
            typetext="password"
            value={confirmPassword}
            maxLength={10}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className='col-md-12 btn-flex-center d-flex gap-2'>
          <Commonbtn
            Commonbtntext={loading ? 'Saving...' : 'Change Password'}
            disabled={loading}
          />
        </div>
      </div>
    </form>
  </div>
)}

       
      </div>
    </div>
  );
}

export default Myprofile;
