import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";

const AssignCategory = () => {
  const { sku } = useParams();
  const [categories, setCategories] = useState([]); // To store fetched categories
  const [selectedCategories, setSelectedCategories] = useState([]); // To store selected categories
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState("");

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${baseUrl}admin/category/sub/all?offset=0&limit=100000000`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch categories");
        }

        const data = await response.json();
        setCategories(data.data.subCategories || []); // Adjust based on API response structure
      } catch (err) {
        setError("Error fetching categories: " + err.message);
      }
    };

    fetchCategories();
  }, [baseUrl, token]);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;

    if (
      categoryId &&
      !selectedCategories.some((cat) => cat.id === parseInt(categoryId, 10))
    ) {
      const selectedCategory = categories.find(
        (category) => category.id === parseInt(categoryId, 10)
      );
      setSelectedCategories((prev) => [...prev, selectedCategory]);
    }
  };

  const removeSelectedCategory = (id) => {
    setSelectedCategories((prev) =>
      prev.filter((category) => category.id !== id)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCategories.length === 0) {
      setFormError("Please select at least one category.");
      return;
    }

    setFormError(""); // Clear previous form errors

    try {
      const payload = {
        sku,
        categories: selectedCategories.map((cat) => cat.id),
      };

      const response = await fetch(`${baseUrl}admin/category/sub/assign`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Error ${response.status}: ${errorData.message || "Submission failed!"}`
        );
      }

      alert("Categories assigned successfully!");
      setSelectedCategories([]);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="">
   <div className="container mt-5">
    <div className="main-bg-off-white bg-off-white">

      <h2 className="mb-4 f-weight-600 f-16">
        <ArrowRedirect /> Assign Categories
      </h2>
      <div className="card shadow-sm p-4">
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label>SKU</label>
            <input
              type="text"
              className="form-control"
              value={sku}
              disabled
            />
          </div>

          <div className="form-group mt-3">
            <label>Select Category</label>
            <select
              className="form-control"
              onChange={handleCategoryChange}
              defaultValue=""
            >
              <option value="" disabled>
                Select a category
              </option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          {selectedCategories.length > 0 && (
            <div className="mt-3">
              <h5>Selected Categories:</h5>
              <div className="d-flex flex-wrap gap-2">
                {selectedCategories.map((category) => (
                  <div
                    key={category.id}
                    className="badge badge-primary p-2"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#6c757d",
                      color: "white",
                    }}
                    onClick={() => removeSelectedCategory(category.id)}
                  >
                    {category.name} &times;
                  </div>
                ))}
              </div>
              <small className="form-text text-muted">
                Click on a category to remove it.
              </small>
            </div>
          )}

          {formError && <div className="alert alert-danger mt-3">{formError}</div>}
          <button type="submit" className="common-btn mt-4">
            Assign Categories
          </button>
        </form>

        {error && <div className="alert alert-danger mt-4">{error}</div>}
      </div>
    </div>
    </div>
    </div>
 
  );
};

export default AssignCategory;
