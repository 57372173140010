import React, { useEffect, useMemo, useRef, useState } from "react";
import ArrowRedirect from "../../Commonbtn/ArrowRedirect";
import Input from "../../Input/Input";
import JoditEditor from "jodit-react";
import Commonbtn from "../../Commonbtn/Commonbtn";
import { useNavigate, useLocation } from "react-router-dom";

const EditProductDescription = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);

  const sku = queryParams.get("sku") || "";
  const initialName = queryParams.get("name") || "";
  const initialDescription = queryParams.get("descreption") || "";

  const [productName, setProductName] = useState(initialName);
  const [productDescription, setProductDescription] =
    useState(initialDescription);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  const editorConfig = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter product description here...",
    }),
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    if (!productName.trim() || !productDescription.trim()) {
      setErrorMessage("Name and description cannot be empty.");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(`${baseUrl}admin/product/update/desc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` ${token}`,
        },
        body: JSON.stringify({
          sku,
          name: productName,
          description: productDescription,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
        // console.log("Product updated successfully:", result);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Failed to update product.");
      }
    } catch (error) {
      setErrorMessage(`Error updating product: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  
  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">
              Edit Product Description
            </h3>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 py-10">
                <Input
                  placeholdertext="Enter The Name"
                  labeltext="Name"
                  typetext="text"
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
              </div>

              <div className="col-md-12 pb-4 pt-2">
                <label htmlFor="productDescription" className="form-label">
                  Product Description
                </label>
                <JoditEditor
                  ref={editor}
                  config={editorConfig}
                  value={productDescription}
                  onBlur={(newContent) => setProductDescription(newContent)}
                  onChange={(newContent) => setProductDescription(newContent)}
                //   dangerouslySetInnerHTML={{ __html: stripHtmlTags(productDescription) }}
                />
                {/* <div className="mt-3">
        <label className="form-label">Plain Text Description</label>
        <div
          className="border p-2"
        //   dangerouslySetInnerHTML={{ __html: stripHtmlTags(productDescription) }}
        />
      </div> */}
              </div>

              <div className="btn-flex-center d-flex gap-2 pt-10">
                <Commonbtn
                  Commonbtntext="Save"
                  type="submit"
                  disabled={isSubmitting}
                />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate(`/productDetail/${sku}`)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>

          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProductDescription;