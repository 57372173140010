import React, { useEffect, useState } from "react";
import './Askfaq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import { useNavigate, Link } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function Askfaq() {
  const navigate = useNavigate();
  const [data, setData] = useState([]); // Store FAQ data
  const [isFetching, setIsFetching] = useState(false); // Track fetching state
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  // Fetch data once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true); // Indicate loading state

      try {
        const response = await fetch(`${baseUrl}admin/faq/all/?offset=0&limit=1000`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.ok) {
          const result = await response.json();
          if (result && result.data && result.data.faqs) {
            setData(result.data.faqs);
          }
        } else {
          const errorData = await response.json();
          // console.error("Failed to fetch data:", response.statusText, errorData);
        }
      } catch (error) {
        // console.error("An error occurred while fetching data:", error);
      } finally {
        setIsFetching(false); // Stop loading state
      }
    };

    fetchData();
  }, [token, baseUrl]); // Dependency array ensures this runs only on mount

  // Function to toggle status
  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch(
        `${baseUrl}admin/faq/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: "GET", // Assuming your API supports GET for updates
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.ok) {
        setData((prevData) =>
          prevData.map((faq) =>
            faq.id === id ? { ...faq, status: newStatus } : faq
          )
        );
      } else {
        const errorData = await response.json();
        // console.error("Failed to update status:", response.statusText, errorData);
      }
    } catch (error) {
      // console.error("An error occurred while updating status:", error);
    }
  };

  return (
    <div>
      <div className='p-60 ml-14-d userdetail'>
        <div className="container">
          <div className="main-bg-off-white bg-off-white">
            <section className="faq-body bg-white">
              <div className="container">
                <div className="d-flex-center justify-content-between pb-3">
                  <div className='d-flex gap-2 '>
                    <ArrowRedirect />
                    <h3 className=" f-18 f-weight-600">FAQ</h3>
                  </div>
                  <div className='btn-flex-center d-flex gap-2'>
                    <Link to="/addfaq">
                      <Commonbtn Commonbtntext="+Add Faq" />
                    </Link>
                  </div>
                </div>
                <div className="row">
                  {data.map((faq, index) => (
                    <div key={index} className="col-md-4 d-flex">
                      <div className={`faq-card bg-white ${faq.status === 0 ? 'iactive' : ''}`}>
                        <div>
                          <Link to={`/faqdetail/${faq.id}`}>
                            <FontAwesomeIcon icon={faq.icon} />
                            <h3 className="f-20">{faq.question}</h3>
                            <p className="f-14 answer">{faq.answer}</p>
                          </Link>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="status-toggle" onClick={() => toggleStatus(faq.id, faq.status)}>
                            <p className={`status-indicator mt-2 ${faq.status === 0 ? 'inactive' : 'active'}`}>
                              {faq.status === 1 ? "Active" : "Inactive"}
                            </p>
                          </div>
                          <Link to={`/editfaq/${faq.id}`}>
                            <i className='icon-edit'>
                              <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.9266 1.63387C16.4399 0.169407 18.8934 0.169404 20.4067 1.63387L23.2117 4.34834C24.7249 5.81281 24.7249 8.18717 23.2117 9.65164L11.7434 20.75H24.125C24.8384 20.75 25.4167 21.3096 25.4167 22C25.4167 22.6903 24.8384 23.25 24.125 23.25H2.16667C1.4533 23.25 0.875 22.6903 0.875 22V15.75C0.875 15.4185 1.01109 15.1005 1.25332 14.8661L14.9266 1.63387ZM8.08997 20.75L18.4233 10.75L13.7917 6.26775L3.45833 16.2678V20.75H8.08997ZM15.6184 4.49999L20.25 8.98222L21.385 7.88387C21.8894 7.39571 21.8894 6.60426 21.385 6.11611L18.58 3.40164C18.0756 2.91348 17.2577 2.91348 16.7533 3.40164L15.6184 4.49999Z" fill="black" />
                              </svg>
                            </i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Askfaq;
