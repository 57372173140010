import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ArrowRedirect from "../../Commonbtn/ArrowRedirect";

const AddProducttandc = () => {
  const { sku } = useParams();
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [denominations, setDenominations] = useState([]);
  const [denominationInput, setDenominationInput] = useState("");
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState("");
  const [denominationError, setDenominationError] = useState("");

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate min and max prices
    if (!minPrice || !maxPrice) {
      setFormError("Both Min and Max prices are required.");
      return;
    }

    if (Number(minPrice) >= Number(maxPrice)) {
      setFormError("Min price must be less than Max price.");
      return;
    }

    if (denominations.length === 0) {
      setFormError("At least one denomination is required.");
      return;
    }

    setFormError(""); // Clear previous errors

    try {
      const payload = {
        sku,
        min: Number(minPrice),
        max: Number(maxPrice),
        denominations: denominations.map(Number),
      };

      const response = await fetch(`${baseUrl}admin/product/update/price`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Error ${response.status}: ${errorData.message || "Submission failed!"}`
        );
      }

      alert("Product added successfully!");
      setMinPrice("");
      setMaxPrice("");
      setDenominations([]);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDenominationKeyDown = (e) => {
    if (e.key === "Enter" && denominationInput.trim() !== "") {
      e.preventDefault();
      const value = Number(denominationInput.trim());

      // Validate denomination against min and max price
      if (isNaN(value)) {
        setDenominationError("Please enter a valid number.");
        return;
      }

      if (value < Number(minPrice) || value > Number(maxPrice)) {
        setDenominationError(
          `Denomination must be between ${minPrice} and ${maxPrice}.`
        );
        return;
      }

      if (!denominations.includes(value)) {
        setDenominations((prev) => [...prev, value]);
      }
      setDenominationInput("");
      setDenominationError(""); // Clear error
    }
  };

  const removeDenomination = (value) => {
    setDenominations((prev) => prev.filter((den) => den !== value));
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">
        <ArrowRedirect /> Add New Price
      </h2>
      <div className="card shadow-sm p-4">
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label>Min Price</label>
            <input
              type="number"
              className="form-control"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group mt-3">
            <label>Max Price</label>
            <input
              type="number"
              className="form-control"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group mt-3">
            <label>Denominations</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter a denomination and press Enter"
              value={denominationInput}
              onChange={(e) => setDenominationInput(e.target.value)}
              onKeyDown={handleDenominationKeyDown}
            />
            {denominationError && (
              <div className="text-danger mt-2">{denominationError}</div>
            )}
            <div className="mt-3 d-flex flex-wrap">
              {denominations.map((denomination, index) => (
                <div
                  key={index}
                  className="p-2 m-1 border rounded bg-secondary text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeDenomination(denomination)}
                >
                  {denomination} &times;
                </div>
              ))}
            </div>
            {denominations.length > 0 && (
              <small className="form-text text-muted">
                Click on a denomination to remove it.
              </small>
            )}
          </div>
          {formError && (
            <div className="alert alert-danger mt-3">{formError}</div>
          )}
          <button type="submit" className="common-btn mt-4">
            Add Product
          </button>
        </form>

        {error && <div className="alert alert-danger mt-4">{error}</div>}
      </div>
    </div>
  );
};

export default AddProducttandc;
