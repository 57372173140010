import React from 'react'
import Products from '../Components/AllProducts/Products'

const AllProducts = () => {
  return (
    <div>
     <Products/>
    </div>
  )
}

export default AllProducts