import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../Input/Input";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import Commonbtn from "../Commonbtn/Commonbtn";

const GeneralSettings = () => {
  const [company_name, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsappLink] = useState("");
  const [facebook, setFacebookLink] = useState("");
  const [twitter, setTwitterLink] = useState("");
  const [instagram, setInstagramLink] = useState("");
  const [youtube, setYoutubeLink] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");
  // console.log(token)
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/cms/contactInfo`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("Fetching data from:", baseUrl);
        const response = await fetch(baseUrl, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        });

        // console.log("API response status:", response);

        if (response.ok) {
          const result = await response.json();
          const generalSetting = result.data; 
          // console.log("Fetched data:", result);

          setCompanyName(generalSetting.company_name || "");
          setPhone(generalSetting.phone.replace('+91', '').trim());
          setEmail(generalSetting.email || "");
          setWhatsappLink(generalSetting.whatsapp || "");
          setFacebookLink(generalSetting.facebook || "");
          setTwitterLink(generalSetting.twitter || "");
          setInstagramLink(generalSetting.instagram || "");
          setYoutubeLink(generalSetting.youtube || "");
          setAddress(generalSetting.address || "");
        } else {
          const errorResponse = await response.json();
          // console.error("Error Response:", errorResponse);
          setMessage(errorResponse.message || "Failed to load data.");
          setError(true);
        }
      } catch (err) {
        // console.error("Fetch error:", err);
        setMessage("Something went wrong while fetching data.");
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      company_name,
      phone,
      email,
      whatsapp,
      facebook,
      twitter,
      instagram,
      youtube,
      address,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/cms/contactInfo/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage("Settings updated successfully!");
        setError(false);
      } else {
        setMessage(result.message || "Failed to update settings");
        setError(true);
      }
    } catch (err) {
      // console.error("Update error:", err);
      setMessage("Something went wrong, please try again.");
      setError(true);
    } finally {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        // navigate("/dashboard"); 
      }, 2000);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">General Settings</h3>
          </div>
          {message && (
            <div className={`alert ${error ? "alert-danger" : "alert-success"}`}>
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  labeltext="Company Name"
                  fortext="companyName"
                  typetext="text"
                  value={company_name}
                  name="company_name" // Fixed the name attribute to match state
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="Phone"
                  fortext="phone"
                  typetext="text"
                  value={phone}
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="Email"
                  fortext="email"
                  typetext="email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="WhatsApp Link"
                  fortext="whatsappLink"
                  typetext="url"
                  name="whatsapp"
                  value={whatsapp}
                  onChange={(e) => setWhatsappLink(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="Facebook Link"
                  fortext="facebookLink"
                  typetext="url"
                  name="facebook"
                  value={facebook}
                  onChange={(e) => setFacebookLink(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="Twitter Link"
                  fortext="twitterLink"
                  typetext="url"
                  name="twitter"
                  value={twitter}
                  onChange={(e) => setTwitterLink(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="Instagram Link"
                  fortext="instagramLink"
                  typetext="url"
                  name="instagram"
                  value={instagram}
                  onChange={(e) => setInstagramLink(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="YouTube Link"
                  fortext="youtubeLink"
                  typetext="url"
                  name="youtube"
                  value={youtube}
                  onChange={(e) => setYoutubeLink(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  labeltext="Address"
                  fortext="address"
                  typetext="text"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="btn-flex-center d-flex gap-2 pt-3">
                <Commonbtn Commonbtntext="Update" type="submit" />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <p>{error ? "Failed to save" : "Settings updated successfully!"}</p>
        </div>
      )}
    </div>
  );
};

export default GeneralSettings;
