import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";

function CreateStaticImage() {
  const mainArray = ["avatar", "product", "category", "blog", "team"];
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blogImage, setBlogImage] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [newArray, setNewArray] = useState([]);
  const [value, setValue] = useState("Select an Option");
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/cms/staticImages`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        if (response.ok) {
          const result = await response.json();
          const staticData = result.data.staticData;
          const matchedTypes = staticData
            .filter((item) => mainArray.includes(item.type))
            .map((item) => item.type);

          const filteredArray = mainArray.filter(
            (item) => !matchedTypes.includes(item)
          );
          setNewArray(filteredArray);
        } else {
          setErrorMessage("Failed to fetch blog data.");
        }
      } catch (error) {
        setErrorMessage("Error fetching blog: " + error.message);
      }
    };

    fetchBlogData();
  }, [token, baseUrl]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("type", value);
    if (blogImage) {
      formData.append("static_image", blogImage);
    }

    try {
      const response = await fetch(`${baseUrl}admin/cms/staticImage/create`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        // Reset fields after submission
        setBlogImage("");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/staticimage");
        }, 2000);
      } else {
        setErrorMessage("Failed to update blog");
      }
    } catch (err) {
      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Create Static Image</h3>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <select
            value={value}
            onChange={handleChange}
            className="form-control"
            style={{ padding: "10px 10px" }}
          >
            <option value="Select an Option">Select an Option</option>
            {newArray.map((filteredArray) => (
              <option value={filteredArray} key={filteredArray}>
                {filteredArray}
              </option>
            ))}
          </select>

          {value !== "Select an Option" && (
            <form onSubmit={handleSubmit}>
              <div className="row">
              
                <div className="col-md-12 ">
                  <Input 
                    placeholdertext="Choose an image"
                    // labeltext={`${value.charAt(0).toUpperCase() + value.slice(1)} Image`}
                    fortext="blogImage"
                    typetext="file"
                    onChange={(e) => {
                      setBlogImage(e.target.files[0]);
                      setBlogImageUrl(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                  {blogImageUrl && (
                    <img
                      src={blogImageUrl}
                      alt="Preview"
                      height="100"
                      style={{ borderRadius: "10px", paddingBottom: "10px" }}
                    />
                  )}
                </div>

                <div className="btn-flex-center d-flex gap-2 pt-10">
                  <Commonbtn
                    Commonbtntext="Save"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  <button
                    type="button"
                    className="Commonredbtn"
                    onClick={() => navigate("/staticimage")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}

          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateStaticImage;
