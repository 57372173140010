import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import Commonbtn from "../Commonbtn/Commonbtn";
import "./ViewReview.scss";

function ViewReview() {
  const { id } = useParams(); // Get the review id from the URL
  const [review, setReview] = useState(null); // State to store review data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReviewDetails = async () => {
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}admin/review/detail?id=${id}`, {
          headers: {
            Authorization: token,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch review details");
        }

        const result = await response.json();
        setReview(result.data); // Assuming the review details are in `data`
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReviewDetails();
  }, [id]);

  const handlePublishReview = async () => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch(
        `${baseUrl}admin/review/changeStatus?id=${id}&status=1`,
        {
          method: "GET", // Use the appropriate method for your API
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `Failed to update status: ${response.status} ${errorBody}`
        );
      }

      const result = await response.json();
      // console.log("Review status updated:", result);
      // Optionally, navigate to another page or update state
      navigate("/review"); // Navigate back to the review list or relevant page
    } catch (error) {
      // console.error("Error updating review status:", error);
      // Handle the error accordingly (e.g., show an alert or message)
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!review) {
    return <div>No review found.</div>;
  }

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex-center justify-content-between mb-3 gap-2">
            <div className="d-flex gap-1">
              <ArrowRedirect />
              <h3 className="grey-85 f-18 f-weight-600">View Review</h3>
            </div>
            <Commonbtn
              Commonbtntext={loading ? "Publishing..." : "Publish review"}
              onclicktext={handlePublishReview}
              disabled={loading} // Optionally disable the button while loading
            />
          </div>

          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="userInfo" className="form-label">
                User Info
              </label>
              <input
                type="text"
                id="userInfo"
                className="form-control"
                value={`${review.first_name} ${review.last_name} (${review.email})`}
                disabled
              />
            </div>

            <div className="col-md-12 mb-3">
              <label htmlFor="reviewTitle" className="form-label">
                Review Title
              </label>
              <input
                type="text"
                id="reviewTitle"
                className="form-control"
                value={review.review}
                disabled
              />
            </div>

            <div className="col-md-12 mb-3">
              <label htmlFor="reviewRole" className="form-label">
                Role
              </label>
              <input
                type="text"
                id="reviewRole"
                className="form-control"
                value={review.role}
                disabled
              />
            </div>

            <div className="col-md-12 mb-3">
              <label htmlFor="reviewRating" className="form-label">
                Rating
              </label>
              <input
                type="text"
                id="reviewRating"
                className="form-control"
                value={review.rating}
                disabled
              />
            </div>

            <div className="col-md-12 mb-3">
              <label className="form-label">Review Description</label>
              <div
                class="reviewinnerhtml"
                dangerouslySetInnerHTML={{ __html: review.description }}
              />
            </div>

            <div className="col-md-12 mb-3">
              <label htmlFor="reviewPublish" className="form-label">
                Date
              </label>
              <input
                type="text"
                id="reviewPublish"
                className="form-control"
                value={review.published_on}
                disabled
              />
            </div>

            <div className="btn-flex-center d-flex gap-2 mt-3">
              <button
                type="button"
                className="pagination-button"
                onClick={() => navigate("/review")}
              >
                Back to Reviews
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewReview;
