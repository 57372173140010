import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import Commonbtn from "../Commonbtn/Commonbtn";

function StaticImage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");
  // Fetch data when offset changes
  useEffect(() => {
    const fetchData = async () => {
      if (!token) return; // Stop API calls if no more data

      try {
        const response = await fetch(`${baseUrl}admin/cms/staticImages`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          // console.log("Data fetched", result);
          if (result && result.data && result.data.staticData.length > 0) {
            setData(result.data.staticData); // Set the user list
          }
        } else {
          const errorData = await response.json();
          // console.error(
          //   "Failed to fetch data:",
          //   response.statusText,
          //   errorData
          // );
        }
      } catch (error) {
        // console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [token, baseUrl]);

  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="p-60 ml-14-d user-list">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex-center justify-content-between pb-3">
            <div className="d-flex gap-2 bg-unset cursor-pointer">
              <i className="arrow-left me-2 " onClick={handleArrowClick}>
                <svg
                  width="18px"
                  height="15px"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_945_2412)">
                    <path
                      d="M17 7.5H1M1 7.5L7 1.5M1 7.5L7 13.5"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_945_2412">
                      <rect
                        width="18"
                        height="14"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              <h3 className="text-dark f-18 f-weight-600">Static Image List</h3>
            </div>

            <div className="btn-flex-center d-flex gap-2 pb-20">
              <Link to="/createstaticimage">
                <Commonbtn Commonbtntext="+Add Static image for types" />
              </Link>
            </div>
          </div>

          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Image</th>
                <th scope="col">Section</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{item.type}</th>
                    <td>
                      <img src={item.image} height="50px" width="50px" />
                    </td>
                    <td>
                      <Link
                        to={`/editstaticimage/${item.id}/${item.type}`}
                        className="table-row-link"
                      >
                        <MdEdit className="edit-icon" />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No Pages found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StaticImage;
