import React from 'react'
import Mydashboard from '../Components/Mydashboard/Mydashboard'


function Dashboard() {
  return (
    <div>
<Mydashboard/>



    </div>
  )
}

export default Dashboard