import React, { useState, useMemo, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";

function TermCondition() {
  const editor = useRef(null);
  const [termConditionDescription, setTermConditionDescription] = useState("");
  const [link, setLink] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { sku } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter terms and conditions here...",
    }),
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${baseUrl}/admin/product/update/tnc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          sku,
          link,
          content: termConditionDescription,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setMessage("Terms and Conditions updated successfully!");
        setError(false);
        setTermConditionDescription("");
        setLink("");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/allProducts");
        }, 2000);
      } else {
        const result = await response.json();
        setMessage(result.message || "Failed to update terms and conditions");
        setError(true);
      }
    } catch (err) {
      setMessage("Something went wrong, please try again.");
      setError(true);
    } finally {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Term & Condition</h3>
          </div>
          {message && (
            <div
              className={`alert ${error ? "alert-danger" : "alert-success"}`}
            >
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="col-md-12 mb-3">
              <label htmlFor="sku" className="form-label">
                SKU
              </label>
              <input
                type="text"
                id="sku"
                className="form-control"
                value={sku}
                readOnly
              />
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="link" className="form-label">
                Link
              </label>
              <input
                type="text"
                id="link"
                className="form-control"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="termConditionDescription" className="form-label">
                Terms & Conditions Description
              </label>
              <JoditEditor
                ref={editor}
                value={termConditionDescription}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setTermConditionDescription(newContent)}
                onChange={(newContent) =>
                  setTermConditionDescription(newContent)
                }
              />
            </div>
            <div className="btn-flex-center d-flex gap-2 pt-3">
              <Commonbtn Commonbtntext="Save" type="submit" />
              <button
                type="button"
                className="Commonredbtn"
                onClick={() => navigate("/productDetail/:sku")}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <p>{error ? "Failed to save" : "Successfully Saved"}</p>
        </div>
      )}
    </div>
  );
}

export default TermCondition;
