import React, { useState } from 'react';
import Input from '../Input/Input';
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import { useNavigate } from "react-router-dom";

function AddFaq() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior
    setIsSubmitting(true);

    const faqData = {
      question,
      answer
    };

    try {
      const response = await fetch(`${baseUrl}admin/faq/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify(faqData),
      });

      if (response.ok) {
        // Handle success, navigate back to FAQ list or show a success message
        navigate('/faq'); // Redirect to FAQ list page after successful creation
      } else {
        const errorData = await response.json();
        // console.error('Failed to create FAQ:', errorData);
        // Show error message
      }
    } catch (error) {
      // console.error('An error occurred while creating FAQ:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='p-60 ml-14-d userdetail'>
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add FAQ</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-12'>
                <Input 
                  placeholdertext="Enter the FAQ question" 
                  labeltext="Question" 
                  fortext="faqQuestion" 
                  typetext="text" 
                  value={question} 
                  onChange={(e) => setQuestion(e.target.value)} 
                  required
                />
              </div>
              <div className='col-md-12'>
                <Input 
                  placeholdertext="Enter the FAQ answer" 
                  labeltext="Answer" 
                  fortext="faqAnswer" 
                  typetext="text" 
                  value={answer} 
                  onChange={(e) => setAnswer(e.target.value)} 
                  required
                />
              </div>
              <div className='btn-flex-center d-flex gap-2'>
                <Commonbtn Commonbtntext="Save" disabled={isSubmitting} />
                <button 
                  type="button" 
                  className="Commonredbtn"
                  onClick={() => navigate('/faq')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddFaq;
