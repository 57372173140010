import React from 'react'

import MyFlatoffer from '../Components/Flatoffer/MyFlatoffer'

function Offer() {
  return (
    <div>
        <MyFlatoffer/>
    </div>
  )
}

export default Offer