import React, { useEffect, useState } from "react";
import "./Team.css";
import Commonbtn from "../Commonbtn/Commonbtn";
import { Link } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import "./Team.scss";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function TeamList() {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogData = async () => {
      setLoading(true);
      setError(null);

      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/team/all?offset=0&limit=99999999`;
      const token = localStorage.getItem("authToken");
      // console.log(token);

      try {
        const response = await fetch(baseUrl, {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        // console.log("API Response:", result); // Log the entire response

        if (response.ok) {
          // Access the members array from the response
          if (result.data && result.data.members) {
            setBlogData(result.data.members); // Set the members array to state
          } else {
            setError("Unexpected response structure: Missing members array");
            // console.error("Expected data structure not found:", result);
          }
        } else {
          setError(result.message || "Failed to fetch teams");
        }
      } catch (err) {
        setError("Something went wrong, please try again.");
        // console.error("Fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, []);

  // const toggleStatus = async (id, currentStatus) => {
  //   const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
  //   const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/team/update/${id}`; // Update endpoint
  //   const token = localStorage.getItem("authToken");

  //   try {
  //     const response = await fetch(baseUrl, {
  //       method: "PATCH",
  //       headers: {
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ status: newStatus }),
  //     });

  //     const result = await response.json();
  //     if (response.ok) {
  //       // Update the local state if the toggle was successful
  //       setBlogData((prevData) =>
  //         prevData.map((member) =>
  //           member.id === id ? { ...member, status: newStatus } : member
  //         )
  //       );
  //     } else {
  //       console.error("Failed to toggle status:", result.message);
  //     }
  //   } catch (err) {
  //     console.error("Error toggling status:", err);
  //   }
  // };
  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/team/changeStatus?id=${id}&status=${newStatus}`;
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch(baseUrl, {
        method: "GET", // Use PATCH to update the resource
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setBlogData((prevData) =>
          prevData.map((member) =>
            member.id === id ? { ...member, status: newStatus } : member
          )
        );
      } else {
        const errorData = await response.json();
        // console.error("Failed to toggle status:", errorData);
      }
    } catch (err) {
      // console.error("Error toggling status:", err);
    }
  };

  const handleDeleteUser = async (userId) => {
    const currentStatus = blogData.find(member => member.id === userId).status;
    toggleStatus(userId, currentStatus); // Call toggleStatus to change status
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <div className="p-60 ml-14-d userdetail">
        <div className="container">
          <div className="main-bg-off-white bg-off-white d-flex-center flex-wrap">
            <Link to="/addteam">
              <Commonbtn Commonbtntext="+Add Team" />
            </Link>
            <p className="error-message w-100 mt-4 f-18 f-weight-600">
              {error}
            </p>
          </div>
        </div>
      </div>
    ); // Error state
  }

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <section className="blog-body">
            <div className="">
              <div className="d-flex-center justify-content-between pb-3">
                <div className="d-flex gap-2 ">
                  <ArrowRedirect />
                  <h3 className="grey-85 f-18 f-weight-600">Team</h3>
                </div>
                <div className="btn-flex-center d-flex gap-2 pb-20">
                  <Link to="/addteam">
                    <Commonbtn Commonbtntext="+Add Team" />
                  </Link>
                </div>
              </div>

              {/* Team Members List */}
              <div className="team-list row pb-10">
                {blogData.length > 0 ? (
                  blogData.map((teamMember) => (
                    <div
                      key={teamMember.id}
                      className="col-md-4 d-flex position-relative pb-20"
                    >
                      <div className="blog-card bg-white px-2 py-2">
                        <i className="blog-icon pb-20">
                          <img
                            src={teamMember?.image || "path/to/placeholder.png"}
                            alt={teamMember.name}
                            height="100%"
                            width="100%"
                            style={{ borderRadius: "5px" }}
                          />
                        </i>

                        <div className="member-details">
                          <p className="f-14 grey-85 mb-1 mt-1">
                            <strong>Name:</strong> {teamMember.name}
                          </p>
                          <p className="f-14 grey-85 mb-1 mt-1">
                            <strong>Designation:</strong>{" "}
                            {teamMember.designation}
                          </p>

                          <p className="f-14 grey-85 mb-1 mt-1">
                            <strong>Description:</strong>{" "}
                            <div
                              contentEditable="true"
                              dangerouslySetInnerHTML={{
                                __html: teamMember?.description,
                              }}
                            ></div>
                          </p>

                          <div className="action-row d-flex justify-content-between">
                            <div className="status-toggle">
                            <p
                                className={`status-indicator ${
                                  teamMember.status === 0
                                    ? "inactive"
                                    : "active"
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteUser(teamMember.id);
                                }}
                              >
                                {teamMember.status === 1
                                  ? "Active"
                                  : "Inactive"}
                              </p>
                            </div>

                            <div className="action-buttons d-flex">
                              {/* <button
                  onClick={() => {
                    if (window.confirm("Are you sure you want to change the status of the user?")) {
                      handleDeleteUser(teamMember.id, teamMember.status === 1 ? 0 : 1);
                    }
                  }}
                  className="delete-icon me-3"
                >
                  <MdDelete />
                </button> */}

                              <Link
                                to={`/editteam/${teamMember.id}`}
                                className="edit-icon"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 26 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.9266 1.63387C16.4399 0.169407 18.8934 0.169404 20.4067 1.63387L23.2117 4.34834C24.7249 5.81281 24.7249 8.18717 23.2117 9.65164L11.7434 20.75H24.125C24.8384 20.75 25.4167 21.3096 25.4167 22C25.4167 22.6903 24.8384 23.25 24.125 23.25H2.16667C1.4533 23.25 0.875 22.6903 0.875 22V15.75C0.875 15.4185 1.01109 15.1005 1.25332 14.8661L14.9266 1.63387ZM8.08997 20.75L18.4233 10.75L13.7917 6.26775L3.45833 16.2678V20.75H8.08997ZM15.6184 4.49999L20.25 8.98222L21.385 7.88387C21.8894 7.39571 21.8894 6.60426 21.385 6.11611L18.58 3.40164C18.0756 2.91348 17.2577 2.91348 16.7533 3.40164L15.6184 4.49999Z"
                                    fill="black"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No team members found.</p>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default TeamList;
