import React, { useState, useMemo, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import { useNavigate } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";

function AddTeam() {
  const editor = useRef(null);
  const [teamImage, setTeamImage] = useState(null);
  const [teamImageUrl, setTeamImageUrl] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamDesignation, setTeamDesignation] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/team`;

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter team description here...",
    }),
    []
  );

  const fetchTeamData = async () => {
    try {
      const response = await fetch(`${baseUrl}/list`, {
        headers: {
          Authorization: token,
        },
      });
      const result = await response.json();

      if (response.ok) {
        setTeamList(result.data);
      } else {
        // console.error("Failed to fetch team data");
      }
    } catch (err) {
      // console.error("Error fetching team data", err);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("team_image", teamImageUrl);
    formData.append("description", teamDescription);
    formData.append("designation", teamDesignation);
    formData.append("name", teamName);
    // console.log("form data", formData);
    try {
      const response = await fetch(`${baseUrl}/create`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      // console.log("result", result);
      // console.log("res", response);
      if (response.ok) {
        setMessage("Team member added successfully!");
        setError(false);

        setTeamImage(null);
        setTeamImageUrl("");
        setTeamDescription("");
        setTeamName("");
        setTeamDesignation("");

        fetchTeamData();

        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/team");
        }, 2000);
      } else {
        setMessage(result.message || "Failed to add team member");
        setError(true);
      }
    } catch (err) {
      setMessage("Something went wrong, please try again.");
      setError(true);
    } finally {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Team Member</h3>
          </div>
          {message && (
            <div
              className={`alert ${error ? "alert-danger" : "alert-success"}`}
            >
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Enter team member name"
                  labeltext="Team Member Name"
                  fortext="teamName"
                  typetext="text"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  placeholdertext="Enter team member designation"
                  labeltext="Team Member Designation"
                  fortext="teamDesignation"
                  typetext="text"
                  value={teamDesignation}
                  onChange={(e) => setTeamDesignation(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <Input
                  placeholdertext="Choose an image"
                  labeltext="Team Image"
                  fortext="Team Image"
                  typetext="file"
                  onChange={(e) => {
                    setTeamImage(e.target.files[0]);
                    setTeamImageUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {teamImageUrl && (
                  <img
                    src={teamImageUrl}
                    alt="Preview"
                    height="100"
                    style={{ borderRadius: "10px" }}
                  />
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="teamDescription" className="form-label">
                  Team Description
                </label>
                <JoditEditor
                  ref={editor}
                  value={teamDescription}
                  config={config}
                  tabIndex={1}
                  onBlur={(newContent) => setTeamDescription(newContent)}
                  onChange={(newContent) => setTeamDescription(newContent)}
                />
              </div>
              <div className="btn-flex-center d-flex gap-2 pt-3">
                <Commonbtn Commonbtntext="Save" type="submit" />
                <button type="button" className="Commonredbtn">
                  Cancel
                </button>
              </div>
            </div>
          </form>

          <div className="mt-4">
            <ul>
              {teamList.map((teamMember) => (
                <li key={teamMember.id}>
                  <img
                    src={teamMember.team_image}
                    alt={teamMember.name}
                    width="50"
                    height="50"
                  />
                  <p>
                    <strong>Name:</strong> {teamMember.name}
                  </p>
                  <p>
                    <strong>Designation:</strong> {teamMember.designation}
                  </p>
                  <p>
                    <strong>Description:</strong> {teamMember.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <p>{error ? "Failed to save" : "Successfully Saved"}</p>
        </div>
      )}
    </div>
  );
}

export default AddTeam;
