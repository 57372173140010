import React, { useEffect, useState } from "react";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";

const EditOffer = () => {
  const { sku, itemid } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const [id, setId] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [offerData, setOfferData] = useState(null);
  const [error, setError] = useState("");
  const [discountMax, setDiscountMax] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [discountDesc, setDiscountDesc] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [priority, setPriority] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const fetchEditOffer = async () => {
    try {
      const response = await fetch(
        `${baseUrl}admin/offer/detail?id=${itemid}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        // console.log("product.........", result);
        setOfferData(result.data);
        setId(itemid || "");
        setDiscountAmount(result.data?.discount_amount || "");
        setDiscountType(result.data?.discount_type || "");
        setDiscountMax(result.data?.discount_max || "");
        setStartDate(parseDate(result.data?.start_date) || new Date());
        setEndDate(parseDate(result.data?.end_date) || new Date());
        setCouponCode(result.data?.coupon_code || "");
        setPriority(result.data?.priority || "");
        setDiscountDesc(result.data?.discount_desc || "");
        if (result.data.length > 0) {
        }
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to fetch offer data.");
      }
    } catch (err) {
      setError("Error while fetching offers: " + err.message);
    }
  };

  useEffect(() => {
    fetchEditOffer();
  }, [sku]);

  const parseDate = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }
    return null;
  };

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const today = new Date();
    if (startDate > today) {
      setError("Start date should not be greater than today.");
      setIsSubmitting(false);
      return;
    }

    const updatedOfferData = {
      id: itemid,
      sku: sku,
      discount_amount: discountAmount,
      coupon_code: couponCode,
      discount_type: discountType,
      discount_max: discountMax,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      discount_desc: discountDesc,
      order: parseInt(priority),
    };

    // console.log("updatedOfferData ", updatedOfferData);

    try {
      const response = await fetch(`${baseUrl}admin/offer/update`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedOfferData),
      });

      // console.log("Response:", response);
      if (response.ok) {
        const result = await response.json();
        // console.log("Offer updated successfully:", result);
        setShowPopup(true);

        setTimeout(() => {
          setShowPopup(false);
          navigate(`/List/${sku}`, { state: { updated: true } });
        }, 2000);
      } else {
        const errorData = await response.json();
        // console.error("Update failed:", errorData);
        setError(errorData.message || "Failed to update the offer.");
      }
    } catch (err) {
      setError("Error while updating the offer: " + err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit Offer</h3>
          </div>
          {error && <p className="text-danger">{error}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <React.Fragment>
                <div className="col-md-12 my-3">
  <label className="text-center">Discount Type</label>
  <select
    value={discountType}
    onChange={(e) => setDiscountType(e.target.value)}
    className="form-control"
    style={{
      padding: "10px 10px",
      backgroundColor: "#F5F5F5",
      border: "1px solid #F5F5F5",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      color: "grey",
    }}
  >
    <option value="">Select Discount Type</option>
    <option value="BY_FIXED">BY_FIXED</option>
    <option value="BY_PERCENT">BY_PERCENT</option>
    <option value="TO_PERCENT">TO_PERCENT</option>
  </select>
</div>

{/* Conditionally render inputs based on discountType */}
{discountType === "BY_FIXED" && (
  <div className="col-md-12 my-3">
    <Input
      placeholdertext="Enter discount amount"
      labeltext="Discount Amount"
      typetext="text"
      onChange={(e) => setDiscountAmount(e.target.value)}
      value={discountAmount}
    />
  </div>
)}

{discountType === "BY_PERCENT" && (
  <div className="col-md-12 my-3">
    <Input
      placeholdertext="Enter maximum discount"
      labeltext="Max Discount"
      typetext="text"
      onChange={(e) => setDiscountMax(e.target.value)}
      value={discountMax}
    />
  </div>
)}

{discountType === "TO_PERCENT" && (
  <>
    <div className="col-md-12 my-3">
      <Input
        placeholdertext="Enter discount amount"
        labeltext="Discount Amount"
        typetext="text"
        onChange={(e) => setDiscountAmount(e.target.value)}
        value={discountAmount}
      />
    </div>
    <div className="col-md-12 my-3">
      <Input
        placeholdertext="Enter maximum discount"
        labeltext="Max Discount"
        typetext="text"
        onChange={(e) => setDiscountMax(e.target.value)}
        value={discountMax}
      />
    </div>
  </>
)}

                  <div className="col-md-12 my-3">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="text-center">Start Date:</label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        placeholderText="Select date"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 my-3">
                    <div className="d-flex gap-2 align-items-center">
                      <label className="text-center">End Date:</label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        placeholderText="Select date"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 my-3">
                    {/* <Input
                        placeholdertext="Enter coupon code"
                        labeltext="Coupon Code"
                        typetext="text"
                        onChange={(e) => setCouponCode(e.target.value)}
                        value={couponCode}
                        readOnly
                      /> */}
                    <div className="col-md-12 my-3">
                      <label>Coupon Code</label>
                      <div className="shadow-sm"
                        style={{
                          padding: "10px",
                          backgroundColor: "#F5F5F5",
                          borderRadius: "10px",

                        }}
                      >
                        {couponCode}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 my-3">
                    <Input
                      placeholdertext="Enter priority"
                      labeltext="Priority"
                      typetext="text"
                      onChange={(e) => setPriority(e.target.value)}
                      value={priority}
                    />
                  </div>

                  {/* <div className="col-md-12 pb-4">
                      <label
                        htmlFor="discountDescription"
                        className="form-label"
                      >
                        Discount Description
                      </label>
                      <JoditEditor
                        value={discountDesc}
                        onBlur={(newContent) => setDiscountDesc(newContent)}
                      />
                    </div> */}

                  <div className="btn-flex-center d-flex gap-2 pt-10">
                    <Commonbtn
                      Commonbtntext="Save"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <button
                      type="button"
                      className="Commonredbtn"
                      onClick={() => navigate(`/List/${sku}`)}
                    >
                      Cancel
                    </button>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </form>

          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditOffer;
