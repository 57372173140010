import React, { useState, useEffect, useMemo, useRef } from "react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";

function Addflatoffer() {
  const editor = useRef(null);
  const mainArray = ["BY_PERCENT", "BY_FIXED", "TO_PERCENT"];
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const [productArray, setProductArray] = useState([]);
  const [value, setValue] = useState(""); // Product SKU value
  const [discount_amount, setDiscountAmount] = useState(""); 
  const [discount_desc, setDiscountDesc] = useState(""); 
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Check localStorage for SKU on component mount
  useEffect(() => {
    const localsku = localStorage.getItem("skulocalStorage");
    // console.log(localsku, 'sku from localStorage');
    
    if (localsku) {
      setValue(localsku); // Set SKU if it exists in localStorage
    } else {
      // If no SKU is found, clear the localStorage
      localStorage.removeItem("skulocalStorage");
    }
  }, []);

  const config = useMemo(() => ({
    readonly: false,
    placeholder: 'Enter blog description here...',
  }), []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/offer/products`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProductArray(data.data.products);
        } else {
          setErrorMessage("Failed to fetch products.");
        }
      } catch (error) {
        setErrorMessage("Error fetching products: " + error.message);
      }
    };

    fetchProducts();
  }, [baseUrl, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const offerData = {
      sku: value,
      value: discount_amount,
      desc: discount_desc,
    };

    try {
      const response = await fetch(`${baseUrl}admin/discount/create`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(offerData),
      });
    const result = await response.json();
      if (response.ok) {
    
        setSuccessMessage("Offer created successfully!");
        setErrorMessage("");
      } else if (result.errors.errors && Array.isArray(result.errors.errors)) {
        // Handle array of errors
        const errorMessage = result.errors.errors.map(err => err.msg).join(', ');
        setErrorMessage(errorMessage || 'Login failed. Please try again.');
    } else {
      setErrorMessage(result.message || 'Login failed. Please try again.');
    }
    } catch (error) {
      setErrorMessage("Error creating offer: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Discount</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-12 my-3">
              <label className="text-center">Product </label>
              <select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="form-control"
                style={{
                  padding: "10px 10px",
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "grey",
                }}
              >
                <option value="Select an Option">Select Product</option>
                {productArray.map((item) => (
                  <option value={item.sku} key={item.sku}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-12 my-3">
              <Input
                placeholdertext="Enter discount %"
                labeltext="Discount %"
                typetext="text"
                onChange={(e) => setDiscountAmount(e.target.value)}
                value={discount_amount}
                maxLength={3}
              />
            </div>
            <div className='col-md-12 pb-4'>
              <label htmlFor="discountDescription" className="form-label">Discount Description</label>
              <JoditEditor
                ref={editor}
                value={discount_desc}
                config={config}
                onBlur={(newContent) => setDiscountDesc(newContent)}
                onChange={(newContent) => setDiscountDesc(newContent)}
              />
            </div>

            <div className="btn-flex-center d-flex gap-2 pt-10">
              <Commonbtn
                Commonbtntext="Save"
                type="submit"
                disabled={isSubmitting}
              />
              <button
                type="button"
                className="Commonredbtn"
                onClick={() => navigate("/flatoffer")}
              >
                Cancel
              </button>
            </div>

            {successMessage && <p className="text-success mt-3">{successMessage}</p>}
            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Addflatoffer;
