import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { useNavigate, useParams } from "react-router-dom";
import "./Team.css";

function EditTeam() {
  const editor = useRef(null);
  const [blogImage, setBlogImage] = useState(); 
  const [blogImageUrl, setBlogImageUrl] = useState(""); 
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDesignation, setBlogDesignation] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: " ",
    }),
    []
  );

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/team/detail?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        if (response.ok) {
          const result = await response.json();
          // Check for the expected data structure
          if (result.data) {
            setBlogTitle(result.data.name);
            setBlogDesignation(result.data.designation); 
            setBlogDescription(result.data.description);
          
            setBlogImageUrl(result.data.image);
          } else {
            setErrorMessage("No data found.");
          }
        } else {
          setErrorMessage("Failed to fetch blog data.");
        }
      } catch (error) {
        setErrorMessage("Error fetching blog: " + error.message);
      }
    };

    fetchBlogData();
  }, [id, token, baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("id", id); 
    formData.append("name", blogTitle); 
    formData.append("designation", blogDesignation);
    formData.append("description", blogDescription);
    
    // Append image only if it exists
    if (blogImage) {
      formData.append("team_image", blogImage); 
    }

    // console.log('formData',formData);

    try {
      const response = await fetch(`${baseUrl}admin/team/update`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        
        setBlogImage(undefined); 
        setBlogTitle("");
        setBlogDescription("");
        setBlogDesignation("");
        setShowPopup(true);

        setTimeout(() => {
          navigate("/team"); 
          setShowPopup(false);
        }, 2000);

        
      } else {
        setErrorMessage("Failed to update blog");
      }
    } catch (err) {
      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit Team</h3>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Choose an image"
                  labeltext="Team Image"
                  fortext="blogImage"
                  typetext="file"
                  onChange={(e) => {
                    setBlogImage(e.target.files[0]);
                    setBlogImageUrl(URL.createObjectURL(e.target.files[0])); 
                  }}
                />
                {blogImageUrl && (
                  <img
                    src={blogImageUrl}
                    alt="Preview"
                    height="100"
                    style={{ borderRadius: "10px" }}
                  />
                )}
              </div>
              <div className="col-md-12">
                <Input
                  placeholdertext="Enter Team title"
                  labeltext="Team Title"
                  typetext="text"
                  onChange={(e) => setBlogTitle(e.target.value)}
                  value={blogTitle}
                />
              </div>

              <div className="col-md-12">
                <Input
                  placeholdertext="Enter Team designation"
                  labeltext="Team designation"
                  typetext="text"
                  onChange={(e) => setBlogDesignation(e.target.value)}
                  value={blogDesignation}
                />
              </div>

              <div className="col-md-12 pb-4">
                <label htmlFor="blogDescription" className="form-label">
                  Team Description
                </label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={blogDescription} 
                  onBlur={(newContent) => setBlogDescription(newContent)}
                  onChange={(newContent) => setBlogDescription(newContent)}
                />
              </div>
              <div className="btn-flex-center d-flex gap-2 pt-10">
                <Commonbtn
                  Commonbtntext="Save"
                  type="submit"
                  disabled={isSubmitting}
                />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate("/team")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditTeam;


