import React from 'react'
import List from '../Components/Giftcardlist/List'

function Giftcard() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default Giftcard