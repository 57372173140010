import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";

function OrderDetail() {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}admin/order/detail?id=${id}`,
          {
            headers: { Authorization: token },
          }
        );
        if (response.data.status === 200) {
          setOrderData(response.data.data);
        } else {
          setError("Failed to fetch order details.");
        }
      } catch (err) {
        setError("An error occurred while fetching the order details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id, token]);

  if (loading) {
    return <p>Loading order details...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!orderData) {
    return <p>No order details found.</p>;
  }

  const formatAmount = (amount) => `₹${amount.toFixed(2)}`;
  const formatDate = (date) => new Date(date).toLocaleString();

  // Calculate total for Gifzi Plus (payment mode 0) and both online & Gifzi Plus (payment mode 2)
  // Calculate total for Gifzi Plus (payment mode 0) and both online & Gifzi Plus (payment mode 2)
const plusPaymentTotal = orderData.plus_payment_data?.reduce((sum, item) => sum + item.amount, 0) || 0;
const onlinePaymentAmount = orderData.online_payment_data?.amount || 0;
const totalAmount = onlinePaymentAmount + plusPaymentTotal;
// Calculate the total amount for all vouchers
const totalVoucherAmount = orderData.vouchers?.reduce((sum, voucher) => sum + voucher.amount, 0) || 0;


  return (
    <div className="order-detail p-60 ml-14-d">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="header d-flex gap-2 mb-4">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Order Detail</h3>
          </div>

          <div className="order-detail-card bg-white shadow-sm rounded-3 p-4 mb-4">
            <h4 className="f-20 f-weight-600 mb-3 text-main">
              Order ID: <span>{orderData.code}</span>
            </h4>

            <div className="row mb-3">
              <div className="col-md-6">
                <p className="f-14 text-muted">
                  <strong>Date:</strong> {orderData.order_dt}
                </p>
                <p className="f-14 text-muted">
                  <strong>Name:</strong> {orderData.first_name}{" "}{orderData.last_name}
                </p>
                <p className="f-14 text-muted">
                  <strong>Email:</strong> {orderData.email}
                </p>
                <p className="f-14 text-muted">
                  <strong>Mobile:</strong> {orderData.mobile}
                </p>
                <p className="f-14 text-muted">
                  <strong>Receiver Name:</strong> {orderData.receiver_name}
                </p>
                <p className="f-14 text-muted">
                  <strong>Receiver Email:</strong> {orderData.receiver_email}
                </p>
                <p className="f-14 text-muted">
                  <strong>Receiver Mobile:</strong> {orderData.receiver_mobile}
                </p>
                <p className="f-14 text-muted">
                  <strong>Payment Mode:</strong>{" "}
                  {orderData.payment_mode === 1
                    ? "Online"
                    : orderData.payment_mode === 0
                    ? "Gifzi Plus"
                    : "Online + Gifzi Plus"}
                </p>
                <p className="f-14 text-muted">
                  <strong>Total Amount:</strong> {formatAmount(orderData.total_amount)}
                </p>
              </div>

              <div className="col-md-6">
                <p className="f-14 text-muted">
                  <strong>Status:</strong>{" "}
                  <span
                    className={`status ${
                      orderData.status === 1 ? "text-success" : "text-danger"
                    }`}
                  >
                    {orderData.status === 1
                      ? "Success"
                      : orderData.status === 2
                      ? "Failed"
                      : "Pending"}
                  </span>
                </p>
               {orderData.expiry && (

              
                <p className="f-14 text-muted">
                  <strong>Expiry Date:</strong> {orderData.expiry}
                </p> )}
              </div>
            </div>
          </div>

          {/* Transaction Details */}
          <div className="transaction-detail-card bg-white shadow-sm rounded-3 p-4 mb-4">
            <h5 className="f-20 f-weight-600 mb-3">Transaction Details</h5>
            {orderData.payment_mode === 1 && (
              <p className="f-14">
                <strong>Transaction ID:</strong> {orderData.online_payment_data?.transaction_id}
                <br />
                <br />
                <strong>Final Amount:</strong> {formatAmount(onlinePaymentAmount)}
                <br />
              </p>
            )}
            {orderData.payment_mode === 0 && (
              <p className="f-14">
                <strong>This transaction is done from Gifzi Plus</strong>
                <br />   <br />
                <strong>Total Amount Gifzi Plus:</strong> {formatAmount(plusPaymentTotal)}
              </p>
            )}
            {orderData.payment_mode === 2 && (
              <p className="f-14">
                <strong>Online Transaction ID:</strong> {orderData.online_payment_data?.transaction_id}
                <br /><br />
                <strong>Online Payment Amount:</strong> {formatAmount(onlinePaymentAmount)}
                <br /><br />
                <strong>Gifzi Plus Total Amount:</strong> {formatAmount(plusPaymentTotal)}
                <br />
                {/* <strong>Total Amount (Online + Gifzi Plus):</strong> {formatAmount(totalAmount)} */}
              </p>
            )}
          </div>

          {/* Voucher Details */}
          {orderData.vouchers && orderData.vouchers.length > 0 && (
  <div className="voucher-detail-card bg-white shadow-sm rounded-3 p-4 mb-4">
    <h5 className="f-20 f-weight-600 mb-3">Voucher Details</h5>
    {orderData.vouchers.map((voucher, index) => (
      <div key={index} className="voucher-item mb-3">
        <p className="f-14">
          <strong>Product Name:</strong> {voucher.product_name}
        </p>
        <p className="f-14">
          <strong>SKU:</strong> {voucher.sku}
        </p>
      
        <p className="f-14">
          <strong>Amount:</strong> {formatAmount(voucher.amount)}
        </p>
        {voucher.flat_discount_amount && (
            <p className="f-14">
            <strong>Flat Discount:</strong> {voucher.flat_discount_amount} ({voucher.flat_discount_value}%)
          </p>
        )}
        {voucher.coupon_discount && (
  <p className="f-14">
   <strong> Coupon Discount:</strong> { voucher.coupon_discount} ({voucher.coupon_code})
  </p>
)}

    
                
      </div>
    ))}
    <p className="f-14">
      <strong>Final Voucher Amount:</strong> {formatAmount(totalVoucherAmount)}
    </p>
  </div>
)}


          {orderData.reason && (
            <div className="reason-card bg-white shadow-sm rounded-3 p-4 mb-4">
              <h5 className="f-20 f-weight-600 mb-3">Reason</h5>
              <p className="f-14">{orderData.reason}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
