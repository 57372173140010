import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./Product.css";
import { Link } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import { CSVLink } from "react-csv";

function List() {
  const [productdata, setProductdata] = useState([]); // Original data
  const [filteredData, setFilteredData] = useState([]); // Filtered data for display
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10); // Default limit set to 10
  const [totalUsers, setTotalUsers] = useState(0);

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const userPhoneNumber = localStorage.getItem("userPhoneNumber");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${baseUrl}admin/gifzi_plus/all?offset=${offset}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setProductdata(data?.data.cards || []);
          setFilteredData(data?.data.cards || []); // Initialize filtered data
          setTotalUsers(data?.data.totalCards || 0); // Set the total number of users (or items)
        } else {
          const errorData = await response.json();
          setError(`Failed to fetch data: ${errorData.message || "Unknown error"}`);
          setProductdata([]);
          setFilteredData([]);
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
        setProductdata([]);
        setFilteredData([]);
        // console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, token, offset, limit]);

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter logic based on multiple fields
    const filtered = productdata.filter((item) =>
      item.card_number?.toLowerCase().includes(value) ||
      item.order_code?.toLowerCase().includes(value) ||
      item.first_name?.toLowerCase().includes(value) ||
      item.last_name?.toLowerCase().includes(value) ||
      item.email?.toLowerCase().includes(value) ||
      item.expiry?.toLowerCase().includes(value) ||
      (item.status === 1 ? "active" : "inactive").includes(value)
    );

    setFilteredData(filtered);
  };

  const formatCardNumber = (cardNumber) => {
    if (cardNumber.length < 6) return cardNumber;
    const lastFour = cardNumber.slice(-4);
    const maskedSection = "X".repeat(cardNumber.length - 4);
    return `${maskedSection}${lastFour}`;
  };

  const csvData = productdata.map((item) => ({
    ID: item.id,
    "Card Number": formatCardNumber(item.card_number),
    "Expiry Date": item.expiry,
    Status: item.status ? "Active" : "Inactive",
  }));

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Change status: 1 for inactive, 0 for active

    try {
      const response = await fetch(
        `${baseUrl}admin/gifzi_plus/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: "GET", // Assuming your API supports PATCH for updates
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.ok) {
        setFilteredData((prevData) =>
          prevData.map((faq) =>
            faq.id === id ? { ...faq, status: newStatus } : faq
          )
        );
      } else {
        const errorData = await response.json();
        // console.error("Failed to update status:", response.statusText, errorData);
      }
    } catch (error) {
      // console.error("An error occurred while updating status:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setOffset((newPage - 1) * limit);
  };

  const totalPages = Math.ceil(totalUsers / limit);

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setOffset(0);
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white product-list">
          <section className="faq-body">
            <div className="container pb-5">
              <div className="d-flex-center justify-content-between pb">
                <h3 className="grey-85 f-18 f-weight-600">Gift Card</h3>
                <div className="btn-flex-center d-flex gap-2">
                  {userPhoneNumber === "9799866929" && (
                    <Link to="/getgiftcardbyadmin">
                      <Commonbtn Commonbtntext="Check Gifzi plus card by admin" />
                    </Link>
                  )}

                  {userPhoneNumber === "9799866929" && (
                    <Link to="/getgiftcardd">
                      <Commonbtn Commonbtntext="Add Gifzi plus Card" />
                    </Link>
                  )}

                  <CSVLink data={csvData} filename={"product_data.csv"} className="Commonbtn">
                    <Commonbtn Commonbtntext="Export to CSV" type="submit" />
                  </CSVLink>
                </div>
              </div>
            </div>

            <div className="bg-white table">
              <div className="d-flex pb-4">
                <div className="col-md-12 d-flex align-items-center justify-content-between">
                  <div className="input-group mb-3 search-bar-container custom-search-bar">
                    <input
                      type="text"
                      className="search-input"
                      onChange={handleSearchChange}
                      value={searchTerm}
                      placeholder="Search Gift Cards"
                    />
                    <button className="search-button">
                      <FontAwesomeIcon icon={faSearch} className="f-16 greyf6" />
                    </button>
                  </div>
                
                </div>
              </div>

              {filteredData.length > 0 ? (
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Card Number</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item) => (
                      <tr key={item.id}>
                        <td>{formatCardNumber(item.card_number)}</td>
                        <td className="text-main">
                          <Link to={`/giftcarddetails/${item.id}`}>{item.order_code}</Link>
                        </td>
                        <td>{`${item.first_name || "Gift Card"} ${item.last_name || ""}`}</td>
                        <td>{item.email}</td>
                        <td>{item.expiry}</td>
                        <td className="status-toggle" onClick={() => toggleStatus(item.id, item.status)}>
                          <p className={`status-indicator mt-2 ${item.status === 0 ? 'inactive' : 'active'}`}>
                            {item.status === 1 ? "Active" : "Inactive"}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-data-message">No cards available.</div>
              )}
            </div>
          </section>

          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select value={limit} onChange={handleLimitChange} className="form-select w-auto d-inline-block select-width">
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> orders per page</span>
            </div>
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary prev-btn" 
                >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="btn btn-secondary  next-btn"
                >
                 <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
