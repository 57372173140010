import React from 'react'
import CategoryList from '../Components/Category/Categorylist'

function Category() {
  return (
    <div>
        <CategoryList/>
    </div>
  )
}

export default Category