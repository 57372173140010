import React, { useEffect, useState } from 'react';
import './Askfaq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link } from 'react-router-dom';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';

function AskFaqDetail() {
  const { id } = useParams(); // Get the ID from the URL
  const [faq, setFaq] = useState(null); // State to hold FAQ data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const token = localStorage.getItem("authToken"); // Get the auth token
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`; // Base URL for API

  // Fetch FAQ data by ID
  useEffect(() => {
    const fetchFaqDetail = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/faq/detail?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch FAQ detail');
        }

        const result = await response.json();
        if (result && result.data) {
          setFaq(result.data); // Assuming the FAQ data is in result.data
        }
      } catch (error) {
        setError(error.message); // Set the error message
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchFaqDetail();
  }, [id, token, baseUrl]); // Fetch data when ID or token changes

  // If loading, show a loading indicator
  if (loading) {
    return <div>Loading...</div>;
  }

  // If there was an error, show the error message
  if (error) {
    return <div>Error: {error}</div>;
  }

  // If no FAQ data found, handle accordingly
  if (!faq) {
    return <div>No FAQ found.</div>;
  }

  // Function to toggle status
  const toggleStatus = async () => {
    const newStatus = faq.status === 1 ? 0 : 1; // Toggle status

    try {
      const response = await fetch(`${baseUrl}admin/faq/changeStatus?id=${id}&status=${newStatus}`, {
        method: "GET", // Use PATCH for status update
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update status');
      }

      // Update FAQ state if the status was successfully updated
      setFaq((prevFaq) => ({ ...prevFaq, status: newStatus }));
    } catch (error) {
      // console.error("Error updating status:", error);
    }
  };

  return (
    <div className='p-60 ml-14-d userdetail'>
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className='d-flex gap-2 mb-4'>
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">FAQ</h3>
          </div>
          <div className="faq-card bg-white">
            <FontAwesomeIcon icon={faq.icon} />
            <h3 className="f-20">{faq.question}</h3>
            <p className="f-14">{faq.answer}</p>
            <div className="status-toggle" onClick={toggleStatus}>
              <p className={`status-indicator mt-2 ${faq.status === 1 ? 'inactive' : 'active'}`}>
                {faq.status === 0 ? "Active" : "Inactive"}
              </p>
            </div>
            <Link to={`/editfaq/${id}`}>
              <i className='icon-edit'>
                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.9266 1.63387C16.4399 0.169407 18.8934 0.169404 20.4067 1.63387L23.2117 4.34834C24.7249 5.81281 24.7249 8.18717 23.2117 9.65164L11.7434 20.75H24.125C24.8384 20.75 25.4167 21.3096 25.4167 22C25.4167 22.6903 24.8384 23.25 24.125 23.25H2.16667C1.4533 23.25 0.875 22.6903 0.875 22V15.75C0.875 15.4185 1.01109 15.1005 1.25332 14.8661L14.9266 1.63387ZM8.08997 20.75L18.4233 10.75L13.7917 6.26775L3.45833 16.2678V20.75H8.08997ZM15.6184 4.49999L20.25 8.98222L21.385 7.88387C21.8894 7.39571 21.8894 6.60426 21.385 6.11611L18.58 3.40164C18.0756 2.91348 17.2577 2.91348 16.7533 3.40164L15.6184 4.49999Z" fill="black" />
                </svg>
              </i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AskFaqDetail;
