import React from 'react'
import Userdetail from '../Components/UserList/Userdetail'

function UserDetailList() {
  return (
    <div>

        <Userdetail/>
    </div>
  )
}

export default UserDetailList