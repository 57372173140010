import React from 'react'

import MyOffer from '../Components/MyOffer/MyOffer'

function Offer() {
  return (
    <div>
        <MyOffer/>
    </div>
  )
}

export default Offer