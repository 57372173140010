import React, { useEffect, useState } from "react";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { Link } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import cImage from "./c.jpg";

const CustomCategory = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  // Function to fetch categories (with or without search term)
  const fetchCategories = async (search = "") => {
    try {
      const url = search
        ? `${baseUrl}admin/category/sub/search?offset=0&limit=10&search=${search}`
        : `${baseUrl}admin/category/sub/all?offset=0&limit=100000000`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }

      const data = await response.json();
      setCategories(data.data.subCategories || []); // Adjust based on API response structure
    } catch (error) {
      // console.error("Error fetching categories:", error);
    }
  };

  // Fetch all categories on initial load
  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Trigger search when the form is submitted
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchCategories(searchTerm);
  };

  const toggleStatus = async (id, currentStatus) => {
    const token = localStorage.getItem("authToken");
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}admin/category/sub/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setCategories((prevData) =>
          prevData.map((blog) =>
            blog.id === id ? { ...blog, status: newStatus } : blog
          )
        );
      } else {
        alert(result.message || "Failed to update status");
      }
    } catch (err) {
      alert("Something went wrong. Please try again.");
    }
  };
  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <section className="blog-body">
            <div>
              <div className="d-flex-center justify-content-between pb-3">
                <div className="d-flex gap-2 align-items-center">
                  <ArrowRedirect />
                  <h3 className="grey-85 f-18 f-weight-600">Custom Category</h3>
                </div>
                <Link to="/addcategory">
                  <Commonbtn Commonbtntext="+Add Category" />
                </Link>
              </div>

              {/* Search Bar */}
              <form
                className="d-flex gap-2 pb-3"
                onSubmit={handleSearchSubmit}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search categories..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <Commonbtn Commonbtntext="Search" type="submit" />
              </form>

              {/* Category List */}
              <div className="row">
                {categories.map((category) => (
                  <div
                    className="col-md-3 d-flex position-relative pb-20"
                    key={category.id}
                  >
                    <div className="blog-card bg-white p-3 position-relative">
                      <Link
                        to={`/updateCategory/${category.id}`}
                        state={{
                          id: category.id,
                          name: category.name,
                          thumbnail: category.thumbnail,
                          status: category.status,
                        }}
                      >
                        <i className="blog-icon category-icon">
                          <img
                            className="rounded"
                            src={category.thumbnail || cImage}
                            alt={category.name || "Custom Category"}
                            style={{
                              height: "100%",
                              width: "100%",
                              zIndex: "1",
                              position: "relative",
                            }}
                          />
                        </i>
                      </Link>
                      <div className="action-row d-flex justify-content-between pt-4">
                        <div>
                          <h3 className="f-20 f-weight-600">{category.name}</h3>
                        </div>
                        <div
                            className="status-toggle"
                            onClick={() => toggleStatus(category.id, category.status)}
                          >
                            <p
                              className={`status-indicator mt-2 ${
                                category.status === 0 ? "inactive" : "active"
                              }`}
                            >
                              {category.status === 1 ? "Active" : "Inactive"}
                            </p>
                          </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CustomCategory;
