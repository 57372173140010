import React from 'react'
import Heading from '../Heading/Heading'
import '../Commonbtn/Table.css'

function Table() {
  return (
    <div className='bg-white table'>
        {/* <Heading text="Recent Transactions" /> */}
<table class="table table-responsive">
  <thead>
    <tr>
      <th scope="col">Date</th>
 
      <th scope="col">User ID</th>
      <th scope="col">Name</th>
      <th scope="col">Suject</th>
      <th scope="col">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">14/01/2024</th>
      <td>7482394892</td>
      <td>Anamika</td>
      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </td>
     
      <td> <span className='success'>Completed</span></td>
    </tr>
    <tr>
      <th scope="row">14/01/2024</th>
      <td>7482394892</td>
      <td>Anamika</td>
      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </td>
     
      <td> <span className='success'>Completed</span></td>
    </tr>
    <tr>
      <th scope="row">14/01/2024</th>
      <td>7482394892</td>
      <td>Anamika</td>
      <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </td>
     
      <td> <span className='success'>Completed</span></td>
    </tr>
  
   

  </tbody>
</table>
    </div>
  )
}

export default Table