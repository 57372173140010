import React, { useState, useEffect, useCallback } from "react";
import closeIcon from "../Assets/Icon/close.svg";
import order from "../Assets/Icon/order.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import balance from "../Assets/Icon/balance.svg";
import earn from "../Assets/Icon/earn.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


import Input from "../Input/Input";
import Select from "../Input/Select";
import "./Myprofile.css";
import Commonbtn from "../Commonbtn/Commonbtn";
import profileimage from "../Assets/Icon/profile-image.png";
import camera from "../Assets/Icon/camera.svg";
import QuerDetail from "../Querylist/QuerDetail";
import axios from "axios";
import westside from "../Assets/westside.png";
import Querylist from "../Querylist/Querylist";
import gifziplus from "../Assets/gifziplus.png";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";

function Admindetail() {
  const [activeTab, setActiveTab] = useState("personal");
  const citylist = ["Select a City", "Udaipur", "Jaipur", "Jodhpur"];
  const [selectedCity, setSelectedCity] = useState(citylist[0]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setContactNumber] = useState("");
  const [password, setpassword] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");
  // console.log(token);
  const { id } = useParams();

  const location = useLocation(); // To get the current location (URL)
  
  

  // Read the 'tab' query parameter from the URL and update the activeTab
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search); // Extract query params from the URL
    const tab = searchParams.get('tab') || 'personal'; // Default to 'personal' if no 'tab' param is found
    setActiveTab(tab);
  }, [location.search]); // Run this whenever the location/search (query params) change






  useEffect(() => {
    const fetchPersonalData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}admin/user/detail?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.ok) {
          const result = await response.json();
          // console.log("qqqqqqqqqqqqqqqqqqq",result);
          const user = result.data?.userDetail;
          const accountDetail = result.data?.accountDetail;
          const kycDetail = result.data?.kycDetail;
          const addressDetail = result.data?.addressDetail;

          setFirstName(user.first_name);
          setLastName(user.last_name);
          setEmail(user.email);
          setContactNumber(user.mobile);
     
          setProfileImage(user.avatar || "");
         

          if (addressDetail.region_id) {
            setTimeout(async () => {
              await fetchCities(addressDetail.region_id, token, addressDetail.city_id);
            }, 2000);
            
          }

          const regionResponse = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}address/region/101`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`, 
              },
            }
          );
  
          if (regionResponse.ok) {
            const regionResult = await regionResponse.json();
            setRegions(regionResult.data?.regions || []);
          } else {
            setError("Failed to load regions.");
          }
  
        } else {
          // console.error("Failed to fetch personal data:", response.statusText);
        }
      } catch (error) {
        // console.error("An error occurred while fetching personal data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (activeTab === "personal") {
      fetchPersonalData();
    }
  }, [activeTab, baseUrl, token, id]);

  const fetchCities = async (regionId, token,city_id) => {
    try {
      const cityResponse = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}address/city/${regionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (cityResponse.ok) {
        const cityResult = await cityResponse.json();
        // console.log("ssssssssssssssssssss",cityResult);
        setCities(cityResult.data?.cities || []);
        setSelectedCity(city_id); 
      } else {
        // console.error("Failed to load cities.");
      }
    } catch (error) {
      // console.error("An error occurred while fetching cities:", error);
    }
  };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
        const imageUrl = URL.createObjectURL(file);
        setProfileImage(imageUrl);
        setSelectedFile(file);
    } else {
        setError("Failed to upload image.");
    }
};

const handlePersonalDetailSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    setLoading(true);
  
    const token = localStorage.getItem("authToken");
    const formData = new FormData();
  
    formData.append("salutation", "Mr");
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("id", id);
    formData.append("password", password || null);
    if (selectedFile) {
      formData.append("avatar", selectedFile);
    }
  
    try {
      const response = await fetch(`${baseUrl}admin/edit`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
  
      const result = await response.json();
  
      if (response.ok) {
        alert("Personal details updated successfully");
      } else if (result.errors && result.errors.errors && Array.isArray(result.errors.errors)) {
        // Handle array of errors
        const errorMessages = result.errors.errors.map((err) => err.msg).join(", ");
        setError(errorMessages || "An error occurred. Please try again.");
      } else if (result.errors && result.errors.message) {
        // Handle single error message
        setError(result.errors.message || "An error occurred. Please try again.");
      } else {
        setError("An unknown error occurred. Please try again.");
      }
    } catch (error) {
      // console.error("Error:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  
  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="bg-white main">
            <h1 className="f-20 text-main f-weight-700"><ArrowRedirect/> Admin Detail</h1>

            <div className="personal detail">
                <form onSubmit={handlePersonalDetailSubmit}>
                  <div className="bg-fb my-profile">
                    <div className="row profileimage">
                      <div className="col-md-12 d-flex-center">
                        <img
                          src={profileImage || "/default-profile.png"}
                          className="profile-image"
                          alt="profile"
                          height="130px"
                          width="130px"
                        />
                        <span className="progress-bar">100%</span>
                        <i
                          className="profile-cameraicon shadow-sm"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <img
                            src={camera}
                            className="profile-camera"
                            alt="camera"
                            height="40px"
                            width="40px"
                          />
                        </i>
                      </div>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  <h2 className="f-20 text-main mt-15 f-weight-600">
                    Personal Detail
                  </h2>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        fortext="id"
                        name="id"
                        typetext="hidden"
                        value={id}
                        labeltext=""
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="first_name"
                        name="first_name"
                        typetext="text"
                        value={first_name}
                        labeltext="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="last_name"
                        name="last_name"
                        typetext="text"
                        value={last_name}
                        labeltext="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="email"
                        name="email"
                        typetext="email"
                        value={email}
                        labeltext="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="mobile"
                        name="mobile"
                        typetext="text"
                        value={mobile}
                        labeltext="Contact Number"
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <Input
                        fortext="password"
                        name="password"
                        typetext="text"
                        value={password}
                        labeltext="Change Password"
                        onChange={(e) => setpassword(e.target.value)}
                        required
                      />
                    </div>
             
                    <div className="col-md-12">
                      {error && <p className="error-message text-danger">{error}</p>}
                      <Commonbtn
                        Commonbtntext={loading ? "Saving..." : "Save"}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </form>
              </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default Admindetail;

