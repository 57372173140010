import React from 'react'
import List from '../Components/Write&Earnlist/List'

function WriteEarn() {
  return (
    <div>
<List/>
    </div>
  )
}

export default WriteEarn