import React from 'react'
import List from '../Components/UserList/List'


function UserList() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default UserList