import React from 'react'
import SectionDetail from '../Components/PageList/Sectiondetail.js'


function SectionList() {
  return (
    <div>
        <SectionDetail/>
    </div>
  )
}

export default SectionList