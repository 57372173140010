import React, { useState, useRef, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import "./AddReview.scss";

function AddReview() {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [rating, setRating] = useState(5.0);
  const [reviewDescription, setReviewDescription] = useState("");
  const [users, setUsers] = useState([]); // State for users list
  const [selectedUserId, setSelectedUserId] = useState(""); // State for selected user
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Enter review description here...",
    }),
    []
  );

  // Fetch users when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      const baseUrl = process.env.REACT_APP_BASE_API_URL;
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(
          `${baseUrl}admin/user/all?offset=0&limit=1000`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const result = await response.json();

        setUsers(result.data.users); // Assuming response has a 'users' array
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const token = localStorage.getItem("authToken");

    const reviewData = {
      user_id: selectedUserId, // Send the selected user id
      role: role,
      review: name,
      rating: rating,
      description: reviewDescription,
    };
    // console.log("form data", reviewData);
    try {
      const response = await fetch(`${baseUrl}admin/review/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(reviewData),
      });

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`Failed to save data: ${response.status} ${errorBody}`);
      }

      const result = await response.json();
      // console.log("Saved Review Data:", result);

      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate("/review");
      }, 2000);

      // Clear form inputs
      setName("");
      setRole("");
      setRating(5.0);
      setReviewDescription("");
      setSelectedUserId(""); // Reset selected user
    } catch (err) {
      // console.error("Error saving review:", err);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Review</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="user" className="form-label">
                  Select User
                </label>
                <select
                  className="form-control"
                  id="user"
                  value={selectedUserId}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                  required
                >
                  <option value="">Select a user</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.first_name} {user.last_name} ({user.email})
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter the name"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyPress={(e) => {
                    if (!/[a-zA-Z\s]/.test(e.key)) {
                      e.preventDefault(); // Prevent typing of any characters other than letters and spaces
                    }
                  }}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="role" className="form-label">
                  {" "}
                  {/* New role field */}
                  Role
                </label>
                <input
                  type="text"
                  placeholder="Enter the role"
                  className="form-control"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                />
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="rating" className="form-label">
                  Rating (1 to 5)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="rating"
                  value={rating}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 1 && value <= 5) {
                      setRating(value);
                    }
                  }}
                  min="1"
                  max="5"
                  required
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="blogDescription" className="form-label">
                  Review Description
                </label>
                <JoditEditor
                  ref={editor}
                  value={reviewDescription}
                  config={config}
                  tabIndex={1}
                  onBlur={(newContent) => setReviewDescription(newContent)}
                  onChange={(newContent) => setReviewDescription(newContent)}
                />
              </div>

              <div className="btn-flex-center d-flex gap-2 mt-3">
                <Commonbtn Commonbtntext="Save" />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate("/review")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>

          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddReview;
