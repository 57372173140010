import React from 'react'
import List from '../Components/Notification/List'

function Notification() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default Notification