import React from 'react';

function Select(props) {
  return (
    <div className='form-group'>
      {/* Label for the select box */}
      <label className="label" htmlFor={props.fortext}>
        {props.labeltext}
      </label>

      {/* Select box */}
      <select
        id={props.fortext}
        className="form-control shadow-sm"
        value={props.selectedValue}
        onChange={props.onChange}
      >
        {props.options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
