import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Commonbtn/Table.css';

function Table() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}admin/write_earn/all?offset=${offset}&limit=${limit}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('authToken'),
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch data');
      }

      if (data.status === 200 && Array.isArray(data.data.requests)) {
        setTransactions(data.data.requests);
        setTotalCount(data.data.totalRequests);
        setHasMoreData(data.data.requests.length === limit);
      } else {
        setError('Unexpected data format received.');
      }
    } catch (error) {
      setError(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offset, limit]);

  const handleRowClick = (id) => {
    navigate(`/writeearndetail/${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit);
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1);
    setOffset(0);
  };

  const totalPages = Math.ceil(totalCount / limit);

  return (
    <div className="bg-white table">
      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}

      {!loading && !error && (
        <>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">User ID</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
              </tr>
            </thead>
            <tbody>
              {transactions.length > 0 ? (
                transactions.map((transaction) => (
                  <tr
                    key={transaction.id}
                    onClick={() => handleRowClick(transaction.id)}
                    className="cursor-pointer"
                  >
                    <th scope="row">{transaction.created_at}</th>
                    <td>{transaction.user_id}</td>
                    <td className="text-main">{transaction.name}</td>
                    <td>{transaction.email}</td>
                    <td>{transaction.phone}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No WriteEarn available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select
                value={limit}
                onChange={handleLimitChange}
                className="form-select w-auto d-inline-block select-width"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> transactions per page</span>
            </div>

            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary prev-btn"
                aria-label="Previous page"
              >
                {/* Previous icon */}
                <svg
                  height="25px"
                  width="25px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
              </button>

              {/* Page buttons */}
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`btn ${
                    currentPage === i + 1
                      ? 'btn-primary filled-primary'
                      : 'btn-secondary outline-page'
                  }`}
                  aria-label={`Page ${i + 1}`}
                >
                  {i + 1}
                </button>
              ))}

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || !hasMoreData}
                className="btn btn-secondary next-btn"
                aria-label="Next page"
              >
                {/* Next icon */}
                <svg
                  height="25px"
                  width="25px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Table;
