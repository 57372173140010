import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import Commonbtn from "../Commonbtn/Commonbtn";

const List = () => {
  const { sku } = useParams();
  const [offerData, setOfferData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  const fetchListOffer = async () => {
    try {
      const response = await fetch(
        `${baseUrl}admin/offer/product/offers?sku=${sku}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        // console.log("list data...........", result);
        setOfferData(result.data.custom_offers || []);
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to fetch offer data.");
      }
    } catch (err) {
      setError("Error while fetching offers: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListOffer();
  }, [sku]);

  if (loading) {
    return <p>Loading...</p>;
  }
  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Change status: 1 for inactive, 0 for active
  
    try {
      const response = await fetch(`${baseUrl}admin/offer/changeStatus?id=${id}&status=${newStatus}`, {
        method: "GET", // Assuming your API supports PATCH for updates
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
  
      if (response.ok) {
        setOfferData((prevData) => 
          prevData.map((faq) => 
            faq.id === id ? { ...faq, status: newStatus } : faq
          )
        );
      } else {
        const errorData = await response.json();
        // console.error("Failed to update status:", response.statusText, errorData);
      }
    } catch (error) {
      // console.error("An error occurred while updating status:", error);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex-center justify-content-between pb-3">
            <div className="d-flex gap-2">
              <ArrowRedirect />
              <h3 className="grey-85 f-18 f-weight-600">All Offers</h3>
            </div>

            <div className="btn-flex-center d-flex gap-2">
              <Link to="/addoffer">
                <Commonbtn Commonbtntext="+ Add offer" />
              </Link>
         
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}

          <div className="gift-card mt-4">
            <div className="row">
              {offerData.length > 0 ? (
                offerData.map((item) => (
                  <div key={item.discount_id} className="col-md-4 mb-4">
                    <div className="offer-card shadow rounded-4 bg-white p-4 position-relative">
                      <div
                        className="rounded-3 p-4"
                        style={{ backgroundColor: "#F5F5F5" }}
                      >
                        <h3 className="f-20 mb-1">{item.discount_id}</h3>
                        <h3 className="f-20 mb-1">{item.sku}</h3>
                        <div className="d-flex gap-2 align-items-center">
                          <label className="text-center">Start Date:</label>
                          <p className="f-18 text-muted mb-1">
                            {item.start_date}
                          </p>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <label className="text-center">End Date:</label>
                          <p className="f-18 text-muted mb-1">
                            {item.end_date}
                          </p>
                        </div>
                        <p className="f-18 text-muted mb-1">
                          {item.discount_max}
                        </p>

                        <div className="d-flex gap-2 align-items-center">
                        <label className="text-center">Code:</label>
                        <p className="f-18 text-muted mb-1">
                          {item.coupon_code}
                        </p>
                        </div>
                        
                      </div>

                      <Link
                       to={`/editoffer/${item.sku}/${item.id}`}
                        className="edit-icon position-absolute"
                        style={{ bottom: "30px", right: "30px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="icon-edit">
                          <svg
                            width="26"
                            height="24"
                            viewBox="0 0 26 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.9266 1.63387C16.4399 0.169407 18.8934 0.169404 20.4067 1.63387L23.2117 4.34834C24.7249 5.81281 24.7249 8.18717 23.2117 9.65164L11.7434 20.75H24.125C24.8384 20.75 25.4167 21.3096 25.4167 22C25.4167 22.6903 24.8384 23.25 24.125 23.25H2.16667C1.4533 23.25 0.875 22.6903 0.875 22V15.75C0.875 15.4185 1.01109 15.1005 1.25332 14.8661L14.9266 1.63387ZM8.08997 20.75L18.4233 10.75L13.7917 6.26775L3.45833 16.2678V20.75H8.08997ZM15.6184 4.49999L20.25 8.98222L21.385 7.88387C21.8894 7.39571 21.8894 6.60426 21.385 6.11611L18.58 3.40164C18.0756 2.91348 17.2577 2.91348 16.7533 3.40164L15.6184 4.49999Z"
                              fill="black"
                            />
                          </svg>
                        </i>
                      </Link>

                      <div className="status-toggle" onClick={() => toggleStatus(item.id, item.status)}>
            <p className={`status-indicator mt-2 ${item.status === 0 ? 'inactive' : 'active'}`}>
              {item.status === 1 ? "Active" : "Inactive"}
            </p>
          </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No offers available at the moment.</p>
              )}
            </div>
          </div>

          {/* {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default List;
