import React, { useState } from 'react';
import Logo from '../../Logo/Logo';
import './../Form.css';
import './Otp.css';
import Commonbtn from '../../Commonbtn/Commonbtn';
import { useLocation, Link, useNavigate } from 'react-router-dom';

function LoginOtp() {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [sentOtp, setSentOtp] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const mobile = location.state?.mobile || '+91 - 8888888888';
    const otp2 = location.state?.otp;
    const first_name = location.state?.first_name;

    // Handle change for each OTP input field
    const handleChange = (element, index) => {
        if (/^[0-9]$/.test(element.value) || element.value === "") {
            const newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Move to the next input field after entering a number
            if (element.value && element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    // Handle backspace and focus shift
    const handleKeyDown = (element, index) => {
        if (element.key === "Backspace" && otp[index] === "") {
            if (element.target.previousSibling) {
                element.target.previousSibling.focus();
            }
        }
    };

    // Handle OTP form submission
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setError(null);

        const otpCode = otp.join('');
        if (otpCode.length !== 6) {
            setError("Please enter a valid 6-digit OTP.");
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/login/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'mobile',
                    mobile: mobile,
                    otp: otpCode,
                    first_name: first_name,
                }),
            });

            const result = await response.json();
            localStorage.setItem('authToken', result.data.token);
            localStorage.setItem('userId', result.data.id);

            if (response.ok) {
                navigate('/dashboard');
            } else {
                setError(result.message || 'OTP verification failed');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setLoading(false);
    };

    // Handle Resend OTP
    const handleResendOtp = async () => {
        setResendLoading(true);
        setError(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/login/resend`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile, first_name }),
            });

            const result = await response.json();

            if (response.ok) {
                setSentOtp(result.data.otp);
                // console.log("OTP resent successfully:", result.data.otp);
            } else {
                setError(result.message || 'Failed to resend OTP');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setResendLoading(false);
    };

    return (
        <section className='authentication otp card'>
            <div className=''>
                <Logo className="logo" />
                <p className="otp-text mb-2">your OTP: {otp2}</p>
                <h1 className='f-40 mb-2'>OTP Verification</h1>
                <p className='f-18 mb-4 greyf6'>
                    We sent you a one-time OTP on this Mobile Number <strong>{mobile}</strong>
                </p>

                <label className='f-16 f-weight-600'>Enter your OTP</label>
                <div className='inputs-5 d-flex'>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="tel"
                            maxLength="1"
                            className='form-control form-control-lg input-otp'
                            value={otp[index]}
                            onChange={e => handleChange(e.target, index)}
                            onKeyDown={e => handleKeyDown(e, index)}
                            onFocus={e => e.target.select()} 
                        />
                    ))}
                </div>

                {error && <p className='error-message'>{error}</p>}

                <p className='f-18 text-center mb-4'>
                    Did not receive OTP? <strong className='text-decoration-underline'>Resend</strong>
                </p>

                <form onSubmit={handleVerifyOtp}>
                    <div className="col-md-12 d-flex-center btn-form">
                        <Commonbtn Commonbtntext={loading ? 'Verifying...' : 'Verify code'} disabled={loading} />
                    </div>
                </form>

                <p className='f-16 greyf6 text-center mt-3'>
                    Back to <Link to='/'><strong>Login</strong></Link>
                </p>
            </div>
        </section>
    );
}

export default LoginOtp;
