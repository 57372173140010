import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdEdit } from "react-icons/md";
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import Commonbtn from '../Commonbtn/Commonbtn';

const AdminControl = () => {
  const navigate = useNavigate();
   const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const token = localStorage.getItem('authToken');
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const fetchAdminControl = async () => {
    try {
      const response = await fetch(
        `${baseUrl}admin/user/byType?offset=0&limit=100&type=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
        setCurrentRecords(result.data.users || []);
      } else {
        const errorData = await response.json();
        setError(`${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      setError('An error occurred while fetching data.');
      // console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminControl();
  }, []);

  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/dashboard');
    }
  };

  const handleEditClick = (userId)=>{
    navigate(`/edituser/${userId}`);
  }

  return (
    <div className="p-60 ml-14-d user-list">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex">
            <div className="arrow-left" onClick={handleArrowClick}>
              <ArrowRedirect/>
              
            </div>
           <div className='d-flex justify-content-between w-100'>
           <h3 className="grey-85 f-18 f-weight-600 ">Admin Data</h3>
            <Link to="/addadmin" className='ml-auto d-flex justify-content-end'>
                      <Commonbtn Commonbtntext="+Add Admin" />
                    </Link>
           </div>
           
          </div>

          {loading ? (
            <p className="text-center">Loading...</p>
          ) : (
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">User Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.length > 0 ? (
                  currentRecords.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Link
                          to={`/admindetail/${item.id}`}
                          className="table-row-link"
                          state={{ queryDetails: item }}
                        >
                          {`${item.salutation} ${item.first_name} ${item.last_name}`}
                        </Link>
                      </th>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                      <td>
                        {item.user_type === 2
                          ? 'Customer'
                          : item.user_type === 3
                          ? 'Corporate'
                          : 'Admin'}
                      </td>
                      <td>{item.status === 1 ? 'Active' : 'Inactive'}</td>
                      <td>
                      <Link
                        to={`/admindetail/${item.id}`}
                        className="table-row-link"
                        state={{ queryDetails: item }}
                      >
                        <MdEdit className="edit-icon"/>
                      </Link>
                       
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Admin Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminControl;
