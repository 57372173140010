import { faBell } from '@fortawesome/free-regular-svg-icons';
import './List.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';

function List() {
  const [notificationData, setNotificationData] = useState([]);
  const [clickedNotifications, setClickedNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef(null);
  const limit = 100;

  // Fetch notifications from API
  const fetchNotifications = async () => {
    if (!hasMore) return; // Stop fetching if there are no more notifications
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}admin/notification/all?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const newNotifications = result.data.notifications || [];
        setNotificationData((prev) => [...prev, ...newNotifications]);
        setOffset((prevOffset) => prevOffset + limit);
        setHasMore(newNotifications.length === limit);
      } else {
        setError("Failed to load notifications.");
      }
    } catch (err) {
      setError("An error occurred while fetching notifications.");
    }
  };

  // Use IntersectionObserver for infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchNotifications();
        }
      },
      { threshold: 1 }
    );

    if (loaderRef.current) observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [hasMore]);

  const handleNotificationClick = (index) => {
    setClickedNotifications((prevClicked) => [...prevClicked, index]);
  };

  return (
    <div className="dashboard p-60 ml-14-d">
      <div className="container ">
        <div className="main-bg-off-white bg-off-white">
          <div className="notification">
            <div className="notification-list bg-white">
              <h2 className="text-center f-40">Notification</h2>
              {error && <p className="error-message">{error}</p>}
              <div className="notification-list-main">
                <div>
                  {notificationData.map((item, index) => {
                    const isActive = !clickedNotifications.includes(index);
                    return (
                      <div
                        key={index}
                        className={`notification-item row ${isActive ? 'active' : ''}`}
                        onClick={() => handleNotificationClick(index)}
                      >
                        <div className="notification-icon col-md-1">
                          <FontAwesomeIcon icon={faBell} className="text-white f-16" />
                        </div>
                        <div className="notification-content col-md-11">
                          <h4 className="f-18 f-weight-600">{item.title}</h4>
                          <p className="f-15 grey">{item.content}</p>
                          <p className="text-muted f-14">{item.time}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {hasMore && (
                  <div ref={loaderRef} className="loading-indicator">
                    Loading more notifications...
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
