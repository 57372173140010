import React from 'react'

import ListMedia from '../Components/Media/ListMedia'

export default function AllMedia() {
  return (
    <div>
       <ListMedia/>
    </div>
  )
}
