import React from 'react';
import PropTypes from 'prop-types';

function Input({
  typetext = 'text',      // Default value instead of using defaultProps
  maxLength,
  onChange,
  value = '',             // Default value for value
  placeholdertext = '',   // Default value for placeholdertext
  labeltext,
  fortext,
  name,
  error // New prop for error messages
}) {
  const handleChange = (e) => {
    const { value, type } = e.target;

    if (type === 'number') {
      if (name === 'aadhar_no') {
        // Specific validation for Aadhaar Number
        if (/^\d*$/.test(value) && value.length <= 12) {
          onChange(e);
        }
      } else{
        if (/^\d*$/.test(value) && value.length <= 10) {
          onChange(e);
        }
      }
      // Restrict to numeric values and limit the length to 10 digits
      
    } else {
      // Apply maxLength for text input types
      if (maxLength && value.length <= maxLength) {
        onChange(e);
      } else if (!maxLength) {
        onChange(e);
      }
    }
  };

  return (
    <div className='form-group'>
      <label className="label" htmlFor={fortext}>{labeltext}</label>
      <input 
        type={typetext} 
        placeholder={placeholdertext} 
        className="form-control shadow-sm" 
        onChange={handleChange}
        value={value}
        name={name}
        maxLength={typetext !== 'number' ? maxLength : undefined}
      />
         {error && <div className="error-message text-danger">{error}</div>} {/* Error message */}
    </div>
  );
}

Input.propTypes = {
  typetext: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholdertext: PropTypes.string,
  labeltext: PropTypes.string.isRequired,
  fortext: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string // New prop type
};

// No need for defaultProps as defaults are already set in the function parameters

export default Input;
 