import React, { useState, useEffect, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { useNavigate, useParams } from 'react-router-dom';
import Commonbtn from '../Commonbtn/Commonbtn';
import { Navigate } from 'react-router-dom';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';

function SectionContent({ placeholder }) {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [sectionType, setSectionType] = useState(''); 
  const [order, setOrder] = useState(''); 
  const [isFetching, setIsFetching] = useState(false);
  const [notification, setNotification] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState(""); 
  const navigate = useNavigate();
  const { id, sectionid } = useParams();
  const token = localStorage.getItem("authToken");
  // console.log(token);
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Start typing...',
    }),
    [placeholder]
  );

  useEffect(() => {
    const fetchSectionDetails = async () => {
      setIsFetching(true);
      try {
        const response = await fetch(`${baseUrl}admin/cms/section/detail?id=${sectionid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        });
        const result = await response.json();

        if (response.ok) {
          const section = result.data;
          setContent(sanitizeContent(section.content));
          setSectionType(section.section_type); 
          setOrder(section.order); 
        } else {
          // console.error('Error fetching section details:', result);
        }
      } catch (error) {
        // console.error('Error occurred while fetching section details:', error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchSectionDetails();
  }, [baseUrl, id, token]);

  const sanitizeContent = (content) => {
    return content.replace(/\r\n/g, "<br>");
  };

  const handleUpdate = async (e) => {
    e.preventDefault(); 
  
    setIsFetching(true); 
    setNotification(null); 
  
    try {
      const response = await fetch(`${baseUrl}admin/cms/section/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: sectionid,  
          page_id: id,    
          section_type: sectionType, 
          content, 
          order,
        }),
      });
  
      if (response.ok) {
        setPopupMessage("Section updated successfully!"); 
        setShowPopup(true); 
        setTimeout(() => {
          navigate(`/sectionlist/${id}`);   
        }, 2000);
        setNotification({ type: 'success', message: 'Section updated successfully!' });
      } else {
        const errorData = await response.json();
        setNotification({ type: 'error', message: `Error updating section: ${errorData.message}` });
      }
    } catch (error) {
      setNotification({ type: 'error', message: `Error occurred while updating section: ${error.message}` });
    } finally {
      setIsFetching(false);
      setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
  };
  
  return (
    <div className="p-60 ml-14-d userdetail">
  <div className="container">
    <div className="main-bg-off-white">
      <div className="row mb-4">
        <div className="col-12 d-flex align-items-center gap-2">
        <ArrowRedirect />
          <h3 className="grey-85 f-18 f-weight-600 ">Update Section</h3>
        </div>
      </div>

      {notification && (
        <div className={`alert alert-${notification.type}`}>
          {notification.message}
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <form onSubmit={handleUpdate}>
            <div className="form-group mb-3">
              <label htmlFor="sectionType">Section Type:</label>
              <input
                type="text"
                id="sectionType"
                className="form-control"
                value={sectionType}
                onChange={(e) => setSectionType(e.target.value)}
                placeholder="Enter Section Type"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="order">Order:</label>
              <input
                type="number"
                id="order"
                className="form-control"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                placeholder="Enter Order"
                required
              />
            </div>

            <div className="form-group mb-4">
              <label htmlFor="contentEditor">Content:</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1}
                onChange={(newContent) => setContent(newContent)}
              />
            </div>

            <div className="d-flex">
              <Commonbtn Commonbtntext="Update section" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  {showPopup && (
    <div className="popup">
      <p>{popupMessage}</p> 
    </div>
  )}
</div>

  );
}

export default SectionContent;