import React, { useState, useEffect } from 'react';
import Logo from '../../Logo/Logo';
import './../Form.css';
import './Otp.css';
import Commonbtn from '../../Commonbtn/Commonbtn';
import { useLocation, Link, useNavigate } from 'react-router-dom';

function Adminotp() {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [sentOtp, setSentOtp] = useState('');
    const [resendTimer, setResendTimer] = useState(0); // Timer state
    const navigate = useNavigate();
    const location = useLocation();
   
    const mobile = location.state?.mobile || '9799866929';
    const password =   localStorage.getItem('password');
    const otp2 = location.state?.otp;
    const first_name = location.state?.first_name;

    // Handle change for each OTP input field
    const handleChange = (element, index) => {
        if (/^[0-9]$/.test(element.value) || element.value === "") {
            const newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Move to the next input field after entering a number
            if (element.value && element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    // Handle backspace and focus shift
    const handleKeyDown = (element, index) => {
        if (element.key === "Backspace" && otp[index] === "") {
            if (element.target.previousSibling) {
                element.target.previousSibling.focus();
            }
        }
    };

    // Timer logic using useEffect
    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendTimer]);

    // Handle OTP form submission
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setError(null);

        const otpCode = otp.join('');
        if (otpCode.length !== 6) {
            setError("Please enter a valid 6-digit OTP.");
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/super/login_verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mobile: mobile,
                    otp: otpCode,
                    password: password,
                }),
            });

            const result = await response.json();
            localStorage.setItem('authToken', result.data.token);
            localStorage.setItem('userId', result.data.id);

            if (response.ok) {
                localStorage.setItem("userPhoneNumber", result.data.mobile);
                localStorage.setItem('user-info', JSON.stringify(result));
                localStorage.setItem('authToken', result.data.token);
                // console.log(result.data.token);
                navigate('/dashboard');
            } else {
                setError(result.message || 'OTP verification failed');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setLoading(false);
    };

    // Handle Resend OTP
    const handleResendOtp = async () => {
        if (resendTimer > 0) return; // Prevent resending if timer is active

        setResendLoading(true);
        setError(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/login/resend`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile, first_name ,password }),
            });

            const result = await response.json();

            if (response.ok) {
                setSentOtp(result.data.otp);
                // console.log("OTP resent successfully:", result.data.otp);
                setResendTimer(60); // Start 60-second timer
            } else {
                setError(result.message || 'Failed to resend OTP');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setResendLoading(false);
    };

    return (
        <section className='authentication otp card'>
            <div className=''>
                <Logo className="logo" />
             {/* <p className="otp-text mb-2">Your OTP: {otp2}</p> */}
                <h1 className='f-40 mb-2'>Admin OTP Verification</h1>
                <p className='f-18 mb-4 greyf6'>
                    We sent you a one-time OTP on this Mobile Number <strong>{mobile}</strong>
                </p>

                <label className='f-16 f-weight-600'>Enter your OTP</label>
                <div className='inputs-5 d-flex'>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="tel"
                            maxLength="1"
                            className='form-control form-control-lg input-otp'
                            value={otp[index]}
                            onChange={e => handleChange(e.target, index)}
                            onKeyDown={e => handleKeyDown(e, index)}
                            onFocus={e => e.target.select()} 
                        />
                    ))}
                </div>

                {error && <p className='error-message'>{error}</p>}

                <p className='f-18 text-center mb-4'>
                    Did not receive OTP?{' '}
                    {resendTimer > 0 ? (
                        <span className="text-muted">Resend in {resendTimer}s</span>
                    ) : (
                        <strong className='text-decoration-underline' onClick={handleResendOtp}>
                            {resendLoading ? 'Resending...' : 'Resend'}
                        </strong>
                    )}
                </p>

                <form onSubmit={handleVerifyOtp}>
                    <div className="col-md-12 d-flex-center btn-form">
                        <Commonbtn Commonbtntext={loading ? 'Verifying...' : 'Verify code'} disabled={loading} />
                    </div>
                </form>

                <p className='f-16 greyf6 text-center mt-3'>
                    Back to <Link to='/'><strong>Login</strong></Link>
                </p>
            </div>
        </section>
    );
}

export default Adminotp;
