import React, { useState, useEffect, useCallback } from "react";
import closeIcon from "../Assets/Icon/close.svg";
import order from "../Assets/Icon/order.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import balance from "../Assets/Icon/balance.svg";
import earn from "../Assets/Icon/earn.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./List.css";
import Myorder from "./Myorder/Myorder";
import Input from "../Input/Input";
import Select from "../Input/Select";
import "./Myprofile.css";
import Commonbtn from "../Commonbtn/Commonbtn";
import profileimage from "../Assets/Icon/profile-image.png";
import camera from "../Assets/Icon/camera.svg";
import QuerDetail from "../Querylist/QuerDetail";
import axios from "axios";
import westside from "../Assets/westside.png";
import Querylist from "../Querylist/Querylist";
import gifziplus from "../Assets/gifziplus.png";

function Userdetail() {
  const [activeTab, setActiveTab] = useState("personal");
  const citylist = ["Select a City", "Udaipur", "Jaipur", "Jodhpur"];
  const statelist = ["Select a State", "Rajasthan", "Gujarat"];
  const [selectedCity, setSelectedCity] = useState(citylist[0]);
  const [selectedState, setSelectedState] = useState(statelist[0]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [account_no, setAccountNo] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [aadhar_no, setAadhaarNumber] = useState("");
  const [pan_no, setPanNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [panError, setPanError] = useState(""); 
  const [aadharError, setAadharError] = useState("");
  const [postcode, setPostcode] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [orderData, setOrderData] = useState(null);
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");
  // console.log(token);
  const { id } = useParams();


  const navigate = useNavigate();
  const location = useLocation(); // To get the current location (URL)
  
  

  // Read the 'tab' query parameter from the URL and update the activeTab
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search); // Extract query params from the URL
    const tab = searchParams.get('tab') || 'personal'; // Default to 'personal' if no 'tab' param is found
    setActiveTab(tab);
  }, [location.search]); // Run this whenever the location/search (query params) change

  // Function to update the URL when a tab is clicked
  const updateTabInUrl = (tab) => {
    // Navigate and update the URL with the new 'tab' query parameter
    navigate(`?tab=${tab}`);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleRegionChange = async (e) => {
    const selectedRegionId = e.target.value; 
    setSelectedRegion(selectedRegionId); 
  
    if (selectedRegionId) {
      try {
        
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}address/city/${selectedRegionId}`, 
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${localStorage.getItem('authToken')}`,
            },
          }
        );
  
        if (response.ok) {
          const result = await response.json();
          setCities(result.data?.cities || []); 
        } else {
          // console.error("Failed to load cities.");
        }
      } catch (error) {
        // console.error("An error occurred while fetching cities:", error);
      }
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}admin/order/byUser?offset=0&limit=10000&user_id=${id}`,
          {
            headers: {
              Authorization: token, 
            },
          }
        );
        
        if (response.data.status === 200) {
          setOrderData(response.data.data.orders);
        }
        // console.log("Response:", response);
      } catch (error) {
        // console.error("Error fetching order details", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id, token]);

  useEffect(() => {
    const fetchPersonalData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}admin/user/detail?id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.ok) {
          const result = await response.json();
          // console.log("qqqqqqqqqqqqqqqqqqq",result);
          const user = result.data?.userDetail;
          const accountDetail = result.data?.accountDetail;
          const kycDetail = result.data?.kycDetail;
          const addressDetail = result.data?.addressDetail;

          setFirstName(user.first_name);
          setLastName(user.last_name);
          setEmail(user.email);
          setContactNumber(user.mobile);
          setAddress(user.address);
          setProfileImage(user.avatar || "");
          setAccountNo(accountDetail.account_no || "");
          setIfsc(accountDetail.ifsc || "");
          setAadhaarNumber(kycDetail.aadhar_no || "");
          setPanNumber(kycDetail.pan_no || "");
          setSelectedState(user.state || "");
          setPostcode(addressDetail.postcode || "");
          setAddress(addressDetail.line1 || "");
          setSelectedRegion(addressDetail.region_id || "");

          if (addressDetail.region_id) {
            setTimeout(async () => {
              await fetchCities(addressDetail.region_id, token, addressDetail.city_id);
            }, 2000);
            
          }

          const regionResponse = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}address/region/101`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`, 
              },
            }
          );
  
          if (regionResponse.ok) {
            const regionResult = await regionResponse.json();
            setRegions(regionResult.data?.regions || []);
          } else {
            setError("Failed to load regions.");
          }
  
        } else {
          // console.error("Failed to fetch personal data:", response.statusText);
        }
      } catch (error) {
        // console.error("An error occurred while fetching personal data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (activeTab === "personal") {
      fetchPersonalData();
    }
  }, [activeTab, baseUrl, token, id]);

  const fetchCities = async (regionId, token,city_id) => {
    try {
      const cityResponse = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}address/city/${regionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (cityResponse.ok) {
        const cityResult = await cityResponse.json();
        // console.log("ssssssssssssssssssss",cityResult);
        setCities(cityResult.data?.cities || []);
        setSelectedCity(city_id); 
      } else {
        // console.error("Failed to load cities.");
      }
    } catch (error) {
      // console.error("An error occurred while fetching cities:", error);
    }
  };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
        const imageUrl = URL.createObjectURL(file);
        setProfileImage(imageUrl);
        setSelectedFile(file);
    } else {
        setError("Failed to upload image.");
    }
};

  const handlePersonalDetailSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const token = localStorage.getItem("authToken");
    const formData = new FormData();

    formData.append("salutation", "Mr");
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("id", id);
    if (selectedFile) {
      formData.append("avatar", selectedFile);
    }

    // console.log("Form Data:", {
    //   salutation: "Mr",
    //   first_name,
    //   last_name,
    //   email,
    //   mobile,
    //   id,
    //   avatar: selectedFile ? selectedFile.name : "No file selected",
    // });

    try {
      const response = await fetch(`${baseUrl}admin/user/editPersonalDetail`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });

      const res = await response.json();
      // console.log("Full response:", res.data);

      if (response.ok) {
        alert("Personal details updated successfully");
      } else {
        const result = await response.json();
        // console.log("bbbbbbbbbbbbbbbbbb",result);
        setError(result.message || "Failed to update personal details.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAccountDetailSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError("");
      setLoading(true);

      const accountData = {
        account_no,
        ifsc,
        id,
      };

      try {
        const response = await fetch(`${baseUrl}admin/user/editAccountDetail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(accountData),
        });

        const result = await response.json();

        if (response.ok) {
          alert("Account details updated successfully");
        } else {
          setError(
            result.message ||
              "Failed to update account details. Please try again."
          );
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [account_no, ifsc, id, token]
  );

  const handleKycDetailSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setPanError(""); 
      setAadharError(""); 

      // PAN validation
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!panRegex.test(pan_no)) {
        setPanError(
          "Invalid PAN number. Please follow the correct format (e.g., ABCDE1234F)."
        );
        setLoading(false);
        return;
      }

      // Aadhaar validation (12 digits, no spaces)
      const aadharRegex = /^[2-9][0-9]{11}$/;
      if (!aadharRegex.test(aadhar_no)) {
        setAadharError(
          "Invalid Aadhaar number. It should be a 12-digit number."
        );
        setLoading(false);
      }
      // Proceed with the API call if both validations pass
      const kycData = {
        aadhar_no,
        pan_no,
        id,
      };

      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}user/editKycDetail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(kycData),
          }
        );

        const result = await response.json();
        if (response.ok) {
          alert("KYC details updated successfully");
        } else {
          setError(
            result.message || "Failed to update KYC details. Please try again."
          );
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      }

      setLoading(false);
    },
    [aadhar_no, pan_no, id]
  );

  
  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="bg-white main">
            <ul className="d-flex bg-white detail-tab">
              <li
                className={`menu-dropdown-list ${
                  activeTab === "personal" ? "active-tab" : ""
                }`}
              >
                <button
                  className="d-flex-center"
                  onClick={() => updateTabInUrl("personal")}
                >
                  <FontAwesomeIcon icon={faUser} className="f-15 bg-85 pu" />
                  <span className="text-list">Personal Detail</span>
                </button>
              </li>
              <li
                className={`menu-dropdown-list ${
                  activeTab === "order" ? "active-tab" : ""
                }`}
              >
                <button
                  className="d-flex-center"
                  onClick={() => updateTabInUrl("order")}
                >
                  <img
                    src={order}
                    className="menu-list-icon"
                    alt="Order"
                    height="20px"
                    width="20px"
                  />
                  <span className="text-list">My Order</span>
                </button>
              </li>
              <li
                className={`menu-dropdown-list ${
                  activeTab === "account" ? "active-tab" : ""
                }`}
              >
                <button
                  className="d-flex-center"
                  onClick={() => updateTabInUrl("account")}
                >
                  <img
                    src={balance}
                    className="menu-list-icon"
                    alt="account"
                    height="20px"
                    width="20px"
                  />
                  <span className="text-list">Account</span>
                </button>
              </li>
              
              <li className={`menu-dropdown-list ${activeTab === 'address' ? 'active-tab' : ''}`}>
                <button className="d-flex-center" onClick={() => updateTabInUrl('address')}>
                  <img src={earn} className='menu-list-icon' alt='Address' height="20px" width="20px" />
                  <span className='text-list'>Address</span>
                </button>
              </li>
              <li className={`menu-dropdown-list ${activeTab === 'Query' ? 'active-tab' : ''}`}>
                <button className="d-flex-center" onClick={() => updateTabInUrl('Query')}>
                  <img src={earn} className='menu-list-icon' alt='Earn' height="20px" width="20px" />
                  <span className='text-list'>Query</span>
                </button>
              </li>
            </ul>

            {/* Conditionally render based on activeTab */}
            {activeTab === "personal" && (
              <div className="personal detail">
                <form onSubmit={handlePersonalDetailSubmit}>
                  <div className="bg-fb my-profile">
                    <div className="row profileimage">
                      <div className="col-md-12 d-flex-center">
                        <img
                          src={profileImage || "/default-profile.png"}
                          className="profile-image"
                          alt="profile"
                          height="130px"
                          width="130px"
                        />
                        <span className="progress-bar">100%</span>
                        <i
                          className="profile-cameraicon shadow-sm"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <img
                            src={camera}
                            className="profile-camera"
                            alt="camera"
                            height="40px"
                            width="40px"
                          />
                        </i>
                      </div>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  <h2 className="f-20 text-main mt-15 f-weight-600">
                    Personal Detail
                  </h2>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        fortext="id"
                        name="id"
                        typetext="hidden"
                        value={id}
                        labeltext=""
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="first_name"
                        name="first_name"
                        typetext="text"
                        value={first_name}
                        labeltext="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="last_name"
                        name="last_name"
                        typetext="text"
                        value={last_name}
                        labeltext="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="email"
                        name="email"
                        typetext="email"
                        value={email}
                        labeltext="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="mobile"
                        name="mobile"
                        typetext="text"
                        value={mobile}
                        labeltext="Contact Number"
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                      />
                    </div>
                    {/* <div className='col-md-12'>
                      <Input fortext="address" name="address" typetext="text" value={address} labeltext="Address" onChange={e => setAddress(e.target.value)} required />
                    </div>
                    <div className='col-md-6'>
                      <Select fortext="city" labeltext="City" options={citylist} value={selectedCity} onChange={handleCityChange} />
                    </div>
                    <div className='col-md-6'>
                      <Select fortext="state" labeltext="State" options={statelist} value={selectedState} onChange={handleStateChange} />
                    </div> */}
                    <div className="col-md-12">
                      {error && <p className="error-message">{error}</p>}
                      <Commonbtn
                        Commonbtntext={loading ? "Saving..." : "Save"}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}

            {activeTab === "account" && (
              <div className="account-detail mt-3">
                <h2 className="f-20 text-main mt-2 mb-3 f-weight-600">
                  Account Detail
                </h2>
                <form onSubmit={handleAccountDetailSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        fortext="account_no"
                        name="account_no"
                        typetext="text"
                        value={account_no}
                        labeltext="Account Number"
                        onChange={(e) => setAccountNo(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        fortext="ifsc"
                        name="ifsc"
                        typetext="text"
                        value={ifsc}
                        labeltext="IFSC Code"
                        onChange={(e) => setIfsc(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12  mb-5">
                      {error && <p className="error-message">{error}</p>}
                      <Commonbtn
                        Commonbtntext={loading ? "Saving..." : "Save"}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </form>

                <h2 className="f-20 text-main mb-3 f-weight-600">KYC Detail</h2>
                <form onSubmit={handleKycDetailSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        fortext="id"
                        name="id"
                        typetext="hidden"
                        value={id}
                        labeltext=""
                      />
                    </div>

                    {/* Aadhaar Number */}
                    <div className="col-md-12">
                      <Input
                        placeholdertext="Aadhaar Number"
                        name="aadhar_no"
                        labeltext="Aadhaar Number"
                        fortext="aadhaar"
                        typetext="number"
                        value={aadhar_no}
                        maxLength={14}
                        onChange={(e) => setAadhaarNumber(e.target.value)}
                      />
                      {aadharError && (
                        <p className="error-message">{aadharError}</p>
                      )}
                    </div>

                    {/* PAN Number */}
                    <div className="col-md-12 pancard">
                      <Input
                        placeholdertext="PAN Card Number"
                        name="pan_no"
                        labeltext="PAN Card Number"
                        fortext="pan"
                        typetext="text"
                        value={pan_no}
                        maxLength={10} // Corrected to 10 characters
                        onChange={(e) =>
                          setPanNumber(e.target.value.toUpperCase())
                        }
                      />
                      {panError && <p className="error-message">{panError}</p>}
                    </div>

                    <div className="col-md-12 btn-flex-center d-flex gap-2">
                      <Commonbtn
                        Commonbtntext={loading ? "Saving..." : "Save"}
                        disabled={loading}
                      />
                      <button type="button" className="Commonredbtn">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}

            {activeTab === "order" && 
       
            <div className="Orderhistorydata-list-main">
            <div className="row">
              {orderData && orderData.length  > 0 ? (
                orderData.map((order, index) => (
                  <div key={index} className="col-md-3">
                    <Link to={`/orderdetail/${order.id}`} className="d-block">
                      <div className="mright-3 Orderhistorydata-item shadow-sm rounded-3">
                        <div className="Orderhistorydata-icon col-md-12">
                         <img
                           src={order.is_gifzi_plus_order === 1 ? gifziplus : order.thumbnail || westside}
                           alt="Order"
                           className="text-white f-16"
                           />
                        </div>
                        <div className="Orderhistorydata-content col-md-12">
                          <h4 className="f-20 f-weight-600 text-main mb-1">
                            Order id: <b className="text-main">{order.code}</b>
                          </h4>
                          <p className="text-muted f-16">{order.order_dt}</p>
                        </div>
                        <div className="col-md-12 d-flex btn-list mt-1 mb-3">
                        <button
  className={`btn-lg ${
    order.status === 1
      ? "success-status"
      : order.status === 2
      ? "failed"
      : "pending"
  }`}
>
  Status:{" "}
  {order.status === 1
    ? "Success"
    : order.status === 2
    ? "Failed"
    : "Pending"}
</button>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p>No orders found</p>
              )}
            </div>
          </div>
          
}

            {activeTab === 'address' &&(
                 <div className="personal-detail">
                 <h2 className="f-28 text-main mt-3 f-weight-600">
                   Address Detail
                 </h2>
                 <form >
                   <div className="row">
                     <div className="col-md-12">
                       <Input
                         fortext="id"
                         name="id"
                         typetext="hidden"
                         value={id}
                         labeltext=""
                       />
                     </div>
     
                     <div className="col-md-6">
                       <label class="label" for="State">State</label>
                       <select
                           name="region_id"
                           className="form-control shadow-sm "
                           style={{ backgroundColor: '#F3F3F3', padding: '12px 10px',border: 'none',}}
                           value={selectedRegion} 
                           onChange={handleRegionChange} 
                         >
                           <option value="">Select a Region</option>
                           {regions.map((region) => (
                             <option key={region.region_id} value={region.region_id}>
                               {region.region_title}
                             </option>
                           ))}
                         </select>
                       
                     </div>
                     
                     <div className="col-md-6">
                       <label class="label" for="city">City</label>
                       <div >
                       <select
                           name="city_id"
                           className="form-control shadow-sm"
                           style={{ backgroundColor: '#F3F3F3', padding: '12px 10px',border: 'none',}}
                          value={selectedCity}
                           onChange={handleCityChange}
                           disabled={!selectedRegion} 
                         >
                           <option value="">Select a City</option>
                           {cities.map((city) => (
                             <option key={city.city_id} value={city.city_id}>
                               {city.city_title}
                             </option>
                           ))}
                         </select>
                       </div>
                      
                         
                       
                     </div>
                     
                     <div className="col-md-12 mt-4">
                       <Input
                         placeholdertext="Address"
                         name="address"
                         labeltext="Address"
                         fortext="address"
                         typetext="text"
                         value={address}
                         onChange={(e) => setAddress(e.target.value)}
                       />
                     </div>
                     <div className="col-md-12">
                       <Input
                         placeholdertext="Post Code"
                         name="postcode"
                         labeltext="Postcode"
                         fortext="postcode"
                         typetext="text"
                         value={postcode} 
                         onChange={(e) => setPostcode(e.target.value)} 
                       />
                     </div>
                     
                   </div>
                 </form>
               </div>
            )}
             {activeTab === 'Query'&&


             
<Querylist />
              
            }

          </div>
        </div>
      </div>
    </div>
  );
}

export default Userdetail;

