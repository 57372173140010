import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SearchBar from '../Searchbar/Searchbar';

function SubCategory() {
  const { id } = useParams(); // Get category ID from URL
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem('authToken');
  console.log(token)

  useEffect(() => {
    const fetchSubcategories = async (query = '') => {
      try {
        const url = query
          ? `${baseUrl}admin/category/sub/search?id=${id}&offset=0&limit=10000&search=${query}`
          : `${baseUrl}admin/category/sub/all?id=${id}&offset=0&limit=10`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        });

        if (response.ok) {
          const result = await response.json();
          // console.log("Subcategories:", result.data.subcategories);

          // console.log("aaaaaa", result);
          if (result.data && result.data.subcategories) {
            setSubcategories(result.data.subcategories);
            setFilteredSubcategories(result.data.subcategories);
          } else {
            // console.error('Subcategories data not found.');
          }
        } else {
          // console.error('Failed to fetch subcategories.');
        }
      } catch (error) {
        // console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubcategories(searchQuery);
  }, [baseUrl, id, token, searchQuery]);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query); 
  };

  return (
    <div className='p-60 ml-14-d userdetail'>
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <h3 className='title'>Subcategory</h3>
          <div className='row'>
            <div className='col-md-12'>
              <SearchBar
                searchtext="Search Here"
                searchtype="text"
                searchclass="custom-search-bar"
                handleSearchChange={handleSearchChange}
              />
            </div>
            {filteredSubcategories.length === 0 ? (
               <div className='col-md-12 text-center d-flex-center mt-4 mb-4'>
               <p className="f-24 f-weight-600 grey-85 mb-1 mt-1">No SubCategories available.</p>
             </div>
            ) : (
              filteredSubcategories.map((subcategory) => (
                <div className='col-md-4 position-relative mb-2' key={subcategory.id}>
                  <div className='bg-white p-2'>
                    <p className="f-14 grey-85 mb-1 mt-1">Subcategory: {subcategory.name}</p>
                    
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubCategory;
