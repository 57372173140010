import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SearchBar from "../Searchbar/Searchbar";
import Select from "../Select/Select";

function List() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0); // Initialize offset state
  const [isFetching, setIsFetching] = useState(false); // Control fetching state
  const [hasMoreData, setHasMoreData] = useState(true); // Track if there's more data
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [limit, setLimit] = useState(10); // Default limit for items per page
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem("authToken");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const totalPages = Math.ceil(data.length / limit);

  // Fetch data on component mount and when offset changes
  useEffect(() => {
    const fetchData = async () => {
      if (!token || !hasMoreData) return; // Stop API calls if no more data is available

      setIsFetching(true); // Start fetching data

      try {
        const response = await fetch(
          `${baseUrl}admin/cms/messages?offset=${offset}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          if (result && result.data && result.data.queries.length > 0) {
            // Append new items to existing list
            setData((prevData) => [...prevData, ...result.data.queries]);
          } else {
            // No more items, stop further API calls
            setHasMoreData(false);
          }
        } else {
          const errorData = await response.json();
          console.error("Failed to fetch data:", response.statusText, errorData);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      } finally {
        setIsFetching(false); // Stop fetching data
      }
    };

    fetchData();
  }, [offset, token, baseUrl, hasMoreData, limit]);

  // Handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit); // Update offset based on page number
  };

  // Handle items per page selection
  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 on limit change
    setOffset(0);
  };

  // Function to navigate back or to the dashboard
  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="p-60 ml-14-d user-list query">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex pb-4">
            <i className="arrow-right" onClick={handleArrowClick}>
              <svg
                width="18px"
                height="15px"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_945_2412)">
                  <path
                    d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_945_2412">
                    <rect
                      width="18"
                      height="14"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </i>
            <h3 className="grey-85 f-18 f-weight-600">Query List</h3>
          </div>

          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">User Name</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Message</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.created_at}</td>
                    <td>{item.user_name || 'Unregistered'}</td>
                    <th scope="row">
                      <Link
                        to={`/querDetail/${item.id}`}
                        className="table-row-link"
                      >
                        {item.name}
                      </Link>
                    </th>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>
                      <span className="tuncate-tab">{item.message}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between align-items-center mt-4">
          <div>
            <span>Show </span>
            <select
              value={limit}
              onChange={handleLimitChange}
              className="form-select w-auto d-inline-block select-width"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
            <span> orders per page</span>
          </div>

          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="btn btn-secondary prev-btn" 
              >
             <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="btn btn-secondary  next-btn"
              >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
        
            </button>
          </div>
        </div>

        {isFetching && <p>Loading more data...</p>}
        {!hasMoreData && <p>No more data to load.</p>}
      </div>
    </div>
  );
}

export default List;
