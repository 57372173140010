import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Navbar.css";
import "../Commonbtn/Common.css";

import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";
import Outline from "../Commonbtn/Outline";
import ScrollToTop from "../../Pages/Scrolltotop";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import menu from "../Assets/menu.svg"; // Add this line
import arrowdown from "../Assets/Icon/arrowdown.svg"; // Add this line

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const accountDropdownRef = useRef(null);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);

  // Toggle account dropdown
  const toggleDropdownAccount = () => {
    setAccountDropdownOpen(!accountDropdownOpen);
  };

  // Handle scroll event for sticky navbar
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle outside click to close account dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target)
      ) {
        setAccountDropdownOpen(false);
      }
    };

    if (accountDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [accountDropdownOpen]);
  // Existing state and refs...


  // Function to toggle the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="header-main position-relative">
      <ScrollToTop />
      <header className={`navbar-header user-logined ${isSticky ? "sticky" : ""}`}>
        <div className="">
          <div className="row text-center nav-mob">
            <div className="col-md-3 text-center d-flex-center nav-mob-logo">
            <button onClick={toggleSidebar} className="menu-login">
                <img src={menu} alt="menu" height="40px" width="40px" />
              </button>
              <AdminSidebar isOpen={isSidebarOpen} toggleDropdown={setIsSidebarOpen} />
            <Link to="/dashboard" className="d-block">
                <img src={logo} className="logo-img" alt="logo" height="60" width="100px" />
              </Link>
            </div>

            <div className="col-md-9 d-flex-center nav-mob-login justify-content-end">
              <div className="nav-login-cart d-flex-center">
                <Link to="/notification" className="bell-icon common-btn text-white">
                 <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.26806 9.75011C5.26681 8.86096 5.44148 7.98036 5.78202 7.15901C6.12256 6.33766 6.62223 5.5918 7.25226 4.96438C7.88228 4.33697 8.63021 3.84041 9.45297 3.50329C10.2757 3.16617 11.1571 2.99516 12.0462 3.0001C15.7587 3.02823 18.7306 6.11261 18.7306 9.83448V10.5001C18.7306 13.8564 19.4337 15.8064 20.0524 16.8751C20.1181 16.9889 20.1528 17.118 20.1529 17.2494C20.153 17.3809 20.1186 17.51 20.0531 17.624C19.9876 17.7379 19.8933 17.8326 19.7796 17.8986C19.666 17.9647 19.537 17.9996 19.4056 18.0001H4.59306C4.46163 17.9996 4.33263 17.9647 4.21899 17.8986C4.10534 17.8326 4.01104 17.7379 3.94552 17.624C3.88001 17.51 3.84559 17.3809 3.8457 17.2494C3.84582 17.118 3.88047 16.9889 3.94619 16.8751C4.56494 15.8064 5.26806 13.8564 5.26806 10.5001V9.75011Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 18V18.75C9 19.5456 9.31607 20.3087 9.87868 20.8713C10.4413 21.4339 11.2044 21.75 12 21.75C12.7956 21.75 13.5587 21.4339 14.1213 20.8713C14.6839 20.3087 15 19.5456 15 18.75V18"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="17"
                      cy="6"
                      r="3.5"
                      fill="#E34255"
                      stroke="#EEEDEB"
                    />
                  </svg>
                </Link>

                <div className="account position-relative" ref={accountDropdownRef}>
                  <button className="outline-btn d-flex-center gap-1" onClick={toggleDropdownAccount}>
                    <FontAwesomeIcon icon={faUser} className="f-15" />
                    <span className="f-14">Admin</span>
                    <img
                      src={arrowdown}
                      alt="arrow"
                      className={accountDropdownOpen ? "rotate-arrow" : ""}
                    />
                  </button>

                  {accountDropdownOpen && (
                    <ul className="menu-dropdown shadow-sm text-left profile-dropdown">
                      <li className="menu-dropdown-list">
                        <Link to="/profile">
                          <FontAwesomeIcon icon={faUser} className="menu-list-icon f-15" />
                          <span className="text-list">Profile</span>
                        </Link>
                      </li>
                      <li className="menu-dropdown-list">
                        <Link to="/">
                          <FontAwesomeIcon icon={faSignOutAlt} className="menu-list-icon" />
                          <span className="text-list">Log Out</span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
