import React from 'react'
import GiftCardAdminList from '../Components/Giftcardlist/GiftCardAdminList'

function Getgiftcardadmin() {
  return (
    <div>
        <GiftCardAdminList/>
    </div>
  )
}

export default Getgiftcardadmin