import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SearchBar from "../Searchbar/Searchbar";
import Select from "../Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNeos } from "@fortawesome/free-brands-svg-icons";
import { faArrowAltCircleDown, faDiagramNext } from "@fortawesome/free-solid-svg-icons";

function List() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0); // Total users from response
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50); // Number of users per page
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("All");
  const token = localStorage.getItem("authToken");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
   

    fetchData();
  }, [token, baseUrl, offset, limit, searchTerm, selectedRole]);

  const fetchData = async () => {
    if (!token) return;
  
    try {
      let url = `${baseUrl}admin/user/all?offset=${offset}&limit=${limit}`;
  
      if (selectedRole === "Customer") {
        url = `${baseUrl}admin/user/byType?offset=${offset}&limit=${limit}&type=2`;
      } else if (selectedRole === "Corporate") {
        url = `${baseUrl}admin/user/byType?offset=${offset}&limit=${limit}&type=3`;
      }
  
      if (searchTerm) {
        url += `&search=${searchTerm}`;
      }
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        setData(result.data?.users || []);
        setTotalUsers(result.data?.totalUsers || 0); // This updates the total user count for pagination
      } else {
        // console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      // console.error("An error occurred while fetching data:", error);
    }
  };
  

  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  const handleDeleteUser = async (userId, status) => {
    try {
      const newStatus = status === 1 ? 0 : 1;
      const response = await fetch(
        `${baseUrl}admin/user/changeStatus?id=${userId}&status=${newStatus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      if (response.ok) {
        setData((prevData) =>
          prevData.map((user) =>
            user.id === userId ? { ...user, status: status } : user
          )
        );
        fetchData();
      } else {
        const errorData = await response.json();
        // console.error("Failed to delete user:", errorData);
      }
    } catch (error) {
      // console.error("An error occurred while deleting user:", error);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
  
    const filtered = data.filter((user) =>
      [
        `${user.first_name} ${user.last_name}`.toLowerCase(),
        user.email.toLowerCase(),
        user.mobile.toLowerCase(),
        user.user_type === 2 ? "customer" : user.user_type === 3 ? "corporate" : "admin",
        user.status === 1 ? "active" : "inactive",
      ].some((field) => field.includes(searchValue))
    );
  
    setFilteredData(filtered);
  };

  const handleSelectChange = (value) => {
    setSelectedRole(value);
    setData([]);
    setOffset(0);
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setOffset(0);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setOffset((newPage - 1) * limit); // Adjust the offset for the new page
  };
  

  const totalPages = Math.ceil(totalUsers / limit);

  return (
    <div className="p-60 ml-14-d user-list">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex bg-white">
            <div className="arrow-right" onClick={handleArrowClick}>
              <svg
                width="18px"
                height="15px"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_945_2412)">
                  <path
                    d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_945_2412">
                    <rect
                      width="18"
                      height="14"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3 className="grey-85 f-18 f-weight-600">User List</h3>
          </div>

          <div className="d-flex bg-white">
            <div className="col-md-8">
              <SearchBar
                searchtext="Search Here"
                searchtype="text"
                searchclass="custom-search-bar"
                handleSearchChange={handleSearchChange}
              />
            </div>
            <div className="col-md-4 mb-0seacrh">
              <Select onSelectChange={handleSelectChange} />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
              <span className="text-bold rounded-3 m-2">
  {searchTerm 
    ? filteredData.length > 0 
      ? `Total Records: ${filteredData.length}` 
      : "Total Records: 0"
    : data.length > 0 
      ? `Total Records: ${data.length}` 
      : "Total Records: 0"}
</span>
            
          </div>

          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">User Type</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
            
              {(searchTerm ? filteredData : data).length > 0 ? (
          (searchTerm ? filteredData : data).map((item, index) => (
                  <tr key={item.id}>
                    <td>{offset + index + 1}</td>
                    <th scope="row">
                      <Link
                        to={`/userdetaillist/${item.id}`}
                        className="table-row-link"
                        state={{ queryDetails: item }}
                      >
                        {item.first_name + " " + item.last_name}
                      </Link>
                    </th>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>
                      {item.user_type === 2
                        ? "Customer"
                        : item.user_type === 3
                        ? "Corporate"
                        : "Admin"}
                    </td>
                    <td>{item.status === 1 ? "Active" : "Inactive"}</td>
                    <td>
                        <p
                            className={`status-indicator mt-2 ${item.status === 0 ? "inactive" : "active"}`}
                              onClick={() => handleDeleteUser(item.id, item.status)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.status === 1 ? "Active" : "Inactive"}
                            </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select value={limit} onChange={handleLimitChange} className="form-select w-auto d-inline-block select-width">
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
              </select>
              <span> orders per page</span>
            </div>

            <div className="pagination d-flex  gap-1"> 
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary prev-btn" 
              >
             <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="btn btn-secondary  next-btn"
              >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
