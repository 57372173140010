import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ArrowRedirect from "../../Commonbtn/ArrowRedirect";

const SetLimit = () => {
  const { sku } = useParams();
  const { name } = useParams();

  const [limit, setLimit] = useState(""); // To store the limit input value
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState("");

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setFormError(""); // Clear previous form errors
    setError(""); // Clear previous errors
  
    try {
      const payload = {
        sku,
        max_amount: Number(limit), // Convert the limit to a number
      };
  
      const response = await fetch(`${baseUrl}admin/product/update/limit`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        // Handle validation and other errors
        const validationErrors = result.errors?.errors; // Nested errors array
        if (Array.isArray(validationErrors)) {
          const errorMessage = validationErrors.map((err) => err.msg).join(", ");
          throw new Error(errorMessage);
        }
        throw new Error(result.message || "Submission failed!");
      }
  
      alert("Set Limit assigned successfully!");
      setLimit(""); // Reset the limit input
    } catch (err) {
      // Handle catch block errors
      // console.error("Error occurred:", err.message);
      setError(err.message || "An unexpected error occurred.");
    }
  };
  

  return (
    <div className="container mt-5">
      <div className="main-bg-off-white bg-off-white">
      <h2 className="mb-4 f-weight-600 f-16">
        <ArrowRedirect /> Set Limit
      </h2>
      <div className="card shadow-sm p-4">
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label>SKU</label>
            <input
              type="text"
              className="form-control"
              value={sku}
              disabled
            />
          </div>
          <div className="form-group mt-3">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              disabled
            />
          </div>
          <div className="form-group mt-3">
            <label>Limit</label>
            <input
              type="number"
              className="form-control"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              placeholder="Enter limit"
            />
          </div>

          {formError && <div className="alert alert-danger mt-3">{formError}</div>}
          <button type="submit" className="common-btn mt-4">
            Set Limit
          </button>
        </form>

        {error && <div className="alert alert-danger mt-4">{error}</div>}
      </div>
      </div>

    
    </div>
  );
};

export default SetLimit;
