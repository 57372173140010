import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function QueryList() {
  const navigate = useNavigate();
  const { id } = useParams(); // User ID
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

  const [queries, setQueries] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch queries on component load
  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const response = await fetch(
          `${baseUrl}admin/cms/messages/byUser?offset=0&limit=10000&user_id=${id}`,
          { method: "GET", headers }
        );

        const data = await response.json();

        if (response.ok && data.status === 200) {
          setQueries(data.data.queries);
        } else {
          setError(data.message || "Failed to fetch queries.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchQueries();
  }, [id, baseUrl]);

  // Handle navigation back
  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <div className="p-60 ml-14-d user-list query">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white shadow rounded-3">
          <div className="d-flex pb-3" style={{ backgroundColor: "#fff" }}>
            <i className="arrow-right" onClick={handleArrowClick}>
              <svg
                width="18px"
                height="15px"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_945_2412)">
                  <path
                    d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_945_2412">
                    <rect
                      width="18"
                      height="14"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </i>
            <h3 className="grey-85 f-18 f-weight-600">
              {selectedQuery ? "Query Details" : "Query List"}
            </h3>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : selectedQuery ? (
            <div style={{ backgroundColor: "#fff", padding: "10px" }}>
              <button onClick={() => setSelectedQuery(null)}>Back to List</button>
              <p>Date: <strong>{selectedQuery.created_at || "N/A"}</strong></p>
              <p>User Name: <strong>{selectedQuery.user_name || "Unregistered"}</strong></p>
              <p>Email: <strong>{selectedQuery.email}</strong></p>
              <p>Phone Number: <strong>{selectedQuery.phone || "N/A"}</strong></p>
              <p>Message: <strong>{selectedQuery.message || "N/A"}</strong></p>
            </div>
          ) : (
            <div style={{ backgroundColor: "#fff", padding: "10px" }}>
              {queries.length > 0 ? (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>Email</th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>Message</th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {queries.map((query) => (
                      <tr key={query.id}>
                        <td >
                          {query.user_name || "Unregistered"}
                        </td>
                        <td >
                          {query.email || "N/A"}
                        </td>
                        <td >
                          {query.message}
                        </td>
                        <td >
                          <button className="common-btn " onClick={() => setSelectedQuery(query)}>
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No queries available.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QueryList;
