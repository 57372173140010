import React, { useEffect, useState } from "react";
import "./Offers.css";
import { Link, useLocation } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { debounce } from "lodash"; // Import lodash for debouncing

function MyFlatoffer() {
  const [offerdata, setOfferData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const location = useLocation();

  // Modular API fetching function
  const fetchOffers = async (search = "") => {
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}admin/discount/all?offset=${offset}&limit=${limit}&search=${search}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        setOfferData(result.data.discounts || []);
        setFilteredData(result.data.discounts || []);
        setTotalUsers(result.data.totalDiscounts || 0);
        setMessage(result.message || "");
      } else {
        const error = await response.json();
        setMessage(error.message || "Error fetching offers.");
      }
    } catch (err) {
      setMessage("Error while fetching offers.");
    } finally {
      setLoading(false);
    }
  };

  // Debounced search handler
  const debouncedSearch = debounce((term) => {
    fetchOffers(term);
  }, 500); // Delay the API call by 500ms

  // Search input change handler
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // Trigger the debounced search
    if (searchValue.trim()) {
      debouncedSearch(searchValue.trim());
    } else {
      setFilteredData(offerdata);
    }
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setOffset((newPage - 1) * limit);
  };

  const totalPages = Math.ceil(totalUsers / limit);

  // Handle limit change
  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setOffset(0);
  };

  // Toggle offer status
  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle status
    try {
      const response = await fetch(
        `${baseUrl}admin/discount/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setMessage("Status updated successfully.");
        fetchOffers(); // Refresh offers after status update
      } else {
        const error = await response.json();
        setMessage("Error updating status.");
      }
    } catch (err) {
      setMessage("Error while updating status.");
    }
  };

  useEffect(() => {
    fetchOffers(); // Initial fetch on component mount
    if (location.state && location.state.updated) {
      fetchOffers(); // Re-fetch if there's an update
    }
  }, [location.state, offset, limit]); // Fetch when offset or limit changes

  return (
    <div>
      <div className="p-60 ml-14-d userdetail offers">
        <div className="container">
          <div className="main-bg-off-white bg-off-white">
            <section className="faq-body">
              <div className="container">
                <div className="d-flex-center justify-content-between pb-3">
                  <div className="d-flex gap-2 ">
                    <ArrowRedirect />
                    <h3 className="grey-85 f-18 f-weight-600">All Flat Discount</h3>
                  </div>

                  <div className="btn-flex-center d-flex gap-2">
                    <Link to="/addflatoffer">
                      <Commonbtn Commonbtntext="+Add Flat Discount" />
                    </Link>
                  </div>
                </div>

                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="d-flex pb-4">
                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                      <div className="input-group mb-3 search-bar-container custom-search-bar">
                        <input
                          type="text"
                          value={searchTerm}
                          placeholder="Search here"
                          onChange={handleSearchChange}
                          className="search-input"
                        />
                      </div>
                    </div>
                  </div>

                  
                </form>

                {loading ? (
                  <div>Loading...</div> // Show loading state
                ) : filteredData.length > 0 ? (
                  <div className="row offer-item">
                    {filteredData.map((item) => (
                      <div key={item.sku} className="col-md-3 d-flex">
                        <div className="offer-card shadow-sm rounded-3 bg-white w-100">
                          <img src={item.thumbnail} className="offer-card-imgw" />
                          <h3 className="f-20 mb-1">{item.name}</h3>
                          <p className="f-18 mb-1">Discount: {item.value}%</p>
                          <p className="f-18 text-muted mb-1">{item.created_at}</p>
                          <div className="d-flex justify-content-between align-items-center">
                            <p
                              className={`status-indicator mt-2 ${item.status === 0 ? "inactive" : "active"}`}
                              onClick={() => toggleStatus(item.id, item.status)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.status === 1 ? "Active" : "Inactive"}
                            </p>
                            <Link to={`/editOfferdiscount/${item.id}`} className="view-detail-link">
                              <div className="toggle-visibility">
                                <span>
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="eye"
                                    className="svg-inline--fa fa-eye "
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>{message || "No offers available at the moment."}</p>
                )}

                {/* Pagination */}
                <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select value={limit} onChange={handleLimitChange} className="form-select w-auto d-inline-block select-width">
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> orders per page</span>
            </div>
            <div>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary prev-btn" 
                >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="btn btn-secondary  next-btn"
                >
                 <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
              </button>
            </div>
          </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyFlatoffer;
