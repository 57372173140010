

import React, { useEffect, useState } from "react";
import "./Offers.css";
import { Link, useLocation, useParams } from "react-router-dom";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import SearchBar from "../Searchbar/Searchbar";

function MyOffer() {
  const [offerdata, setOfferData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState("");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");
  const location = useLocation();

  // Fetch all offers
  const fetchOffer = async () => {
    try {
      // console.log("Fetching all offers...");
      const response = await fetch(`${baseUrl}admin/offer/products`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result = await response.json();
        // console.log("Fetched Offers:", result);
        setOfferData(result.data.products || []);
        setFilteredData(result.data.products || []);
      } else {
        const error = await response.json();
        // console.error("Error fetching offers:", error);
        setMessage("Error fetching offers.");
      }
    } catch (err) {
      // console.error("Error while fetching offers:", err);
      setMessage("Error while fetching offers.");
    }
  };

  // Fetch filtered offers by search term
  const fetchSearchOffers = async (term) => {
    try {
      // console.log("Initiating search with term:", term);
      const response = await fetch(
        `${baseUrl}admin/offer/search_products?search=${term}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        // console.log("Search API Response:", result);
        setFilteredData(result.data.products || []);
        setMessage(result.message || "Search successful.");
      } else {
        const error = await response.json();
        // console.error("Error in search API:", error);
        setMessage(error.message || "An error occurred during search.");
      }
    } catch (err) {
      // console.error("Error while fetching search offers:", err);
      setMessage("Error while searching offers.");
    }
  };

  // Handle search input changes
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // Client-side filtering
    if (!searchValue) {
      setFilteredData(offerdata); // Reset to all offers when search is cleared
    }
  };

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // console.log("Search Term Submitted:", searchTerm);

    // Only call the API if a search term exists
    if (searchTerm.trim()) {
      fetchSearchOffers(searchTerm.trim());
    } else {
      setMessage("Please enter a search term.");
      setFilteredData(offerdata);
    }
  };

  useEffect(() => {
    fetchOffer(); // Initial fetch
    if (location.state && location.state.updated) {
      fetchOffer(); // Re-fetch if there's an update
    }
  }, [location.state]);

  return (
    <div>
      <div className="p-60 ml-14-d userdetail offers">
        <div className="container">
          <div className="main-bg-off-white bg-off-white">
            <section className="faq-body">
              <div className="container">
                <div className="d-flex-center justify-content-between pb-3">
                  <div className="d-flex gap-2">
                    <ArrowRedirect />
                    <h3 className="grey-85 f-18 f-weight-600">All Offers</h3>
                  </div>

                  <div className="btn-flex-center d-flex gap-2">
                    <Link to="/addoffer">
                      <Commonbtn Commonbtntext="+Add offer" />
                    </Link>
                
                  </div>
                </div>

                <form onSubmit={handleSearchSubmit}>
                <div className="d-flex pb-4">
                  <div className="col-md-12 d-flex align-items-center justify-content-between">
                    <div className="input-group mb-3 search-bar-container custom-search-bar">
                    <input
                        searchtext="Search Here"
                        searchtype="text"
                         className="search-input"
                        searchclass="custom-search-bar"
                        value={searchTerm}
                        placeholder="Search here"
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
                  <div className="d-flex pb-4">
                    <div className="col-md-12">
                    
                    </div>
                  </div>
                </form>

                <div className="row offer-item">
                  {filteredData.length > 0 ? (
                    filteredData.map((item) => (
                      <div key={item.sku} className="col-md-3">
                        <div className="offer-card shadow-sm rounded-3 bg-white">
                          <h3 className="f-20 mb-1">{item.sku}</h3>
                          <h3 className="f-20 mb-1">{item.name}</h3>
                          <p className="f-18 text-muted mb-1">
                            {item.discount_id}
                          </p>
                          <Link
                            to={`/list/${item.sku}`}
                            className="view-detail-link"
                          >
                            <div className="toggle-visibility">
                              <span className="">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="eye"
                                  className="svg-inline--fa fa-eye "
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No offers available at the moment.</p>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOffer;

