import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';

import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';


import Login from './Pages/Login';
import Register from './Pages/Register';
import Verification from './Pages/Verification';
import Forgotpassword from './Pages/Forgotpassword';
import Newpassword from './Pages/Newpassword';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Profile from './Pages/Profile';
import AOS from "aos";
import "aos/dist/aos.css";
import Preloader from './Components/Preloader/Preloader'; // Add the Preloader
import './Components/Responsive.css';
import Dashboard from './Pages/Dashboard';
import Notification from './Pages/Notification';
import AdminSidebar from './Components/AdminSidebar/AdminSidebar';
import UserList from './Pages/UserList';
import PageList from './Pages/PageList';
import Userdetail from './Components/UserList/Userdetail';
import UserDetailList from './Pages/UserDetailList';
import SectionList from './Pages/SectionList';
import Faq from './Pages/Faq';
import AddFaq from './Components/Askfaq/AddFaq';
import EditFaq from './Components/Askfaq/EditFaq';
import AllReview from './Pages/AllReview';
import AddReview from './Components/Review/AddReview';
import ViewReview from './Components/Review/ViewReview';
import Offer from './Pages/Offer';
import Flatoffer from './Pages/Flatoffer';
import Addoffer from './Components/MyOffer/Addoffer';
import Addflatoffer from './Components/Flatoffer/Addflatoffer';
import Giftcard from './Pages/Giftcard';
import Addgiftcard from './Components/Giftcardlist/Addgiftcard';
import OrderHistory from './Pages/OrderHistory';
import WriteEarn from './Pages/WriteEarn';
import Schedule from './Pages/Schedule';
import Querylist from './Pages/Querylist';
import QuerDetail from './Components/Querylist/QuerDetail'
import CategoryList from './Components/Category/Categorylist';
import CustomCategory from './Components/CustomCategory/CustomCategory';

import Category from './Pages/Category';
import ScrollToTop from './Pages/Scrolltotop';
import AllBlog from './Pages/AllBlog';
import AllMedia from './Pages/AllMedia';
import AddMedia from './Components/Media/AddMedia';
import AddBlog from './Components/Blog/AddBlog';
import Review from './Components/Review/Review';
import LoginOtp from './Components/Authentication/Otp/LoginOtp';
import SectionContent from './Components/PageList/SectionContent';
import AskFaqDetail from './Components/Askfaq/AskFaqDetail';
import Editblog from './Components/Blog/Editblog';
import EditMedia from './Components/Media/EditMedia';
import SubCategory from './Components/Category/SubCategory';
import WriteEarnDetail from './Components/Write&Earnlist/WriteEarnDetail';
import SyncCategory from './Components/Category/SyncCategory';
import TeamList from './Components/Team/TeamList';
import EditTeam from './Components/Team/EditTeam';
import AddTeam from './Components/Team/AddTeam';
import GiftCardDetail from './Components/Giftcardlist/GiftCardDetail';
import OrderDetail from './Components/MyorderHistory/OrderDetail';
import Getgiftcard from './Pages/Getgiftcard';
import Getgiftcardadmin from './Pages/Getgiftcardadmin';

import AddList from './Components/PageList/AddList';
import GeneralSettings from './Components/GeneralSettings/GeneralSettings';


import StaticImageList from './Pages/StaticImageList';
import EditStaticImage from './Components/StaticImage/EditStaticImage';
import CreateStaticImage from './Components/StaticImage/CreateStaticImage';
import AllProducts from './Pages/AllProducts';
import ProductDetail from './Components/AllProducts/ProductDetail/ProductDetail';
import AddImage from './Components/AllProducts/AddImage/AddImage';
import TermCondition from './Components/AllProducts/TermCondition';
import EditOffer from './Components/MyOffer/EditOffer';
import List from './Components/MyOffer/List';
import ProtectedRoute from './ProtectedRoute';
import AdminControl from './Components/AdminControl/AdminControl';

import Adminotp from './Components/Authentication/Otp/Adminotp';
import EditOfferdiscount from './Components/Flatoffer/EditOfferdiscount';
import Edittandc from './Components/AllProducts/ProductDetail/Edittandc';
import AddProducttandc from './Components/AllProducts/ProductDetail/AddProducttandc';
import SendNotification from './Components/SendNotification/SendNotification';
import AddCategory from './Components/CustomCategory/AddCategory';
import EditCustomCategory from './Components/CustomCategory/AssignCategory';
// import AddCustomProductPrice from './Components/AllProducts/ProductDetail/AddCustomProductPrice';
import UpdateCategory from './Components/CustomCategory/UpdateCategory';
import AssignCategory from './Components/CustomCategory/AssignCategory';
import EditProductDescription from './Components/AllProducts/ProductDetail/EditProductDescription';
import NotFound from './NotFound';
import Setlimit from './Components/AllProducts/ProductDetail/Setlimit';
import Editsetlimit from './Components/AllProducts/ProductDetail/Editsetlimit';
import Addadmin from './Components/AdminControl/Addadmin';
import Admindetail from './Components/AdminControl/Admindetail';




function App() {
  const [loading, setLoading] = useState(true); // Add loading state

  // aos 
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Simulate a loading process
  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Turn off preloader after 2 seconds
    }, 1000);
  }, []);

  return (
    // Show preloader while loading is true
    loading ? <Preloader /> : (
      // router start
      <Router >

          <ScrollToTop /> {/* Scroll to top on route change */}
        <ConditionalWrapper>
          <Routes>
            <Route path="/" element={<Login />} /> 
            {/* <Route path="
            
            
            " element={<Navigate to="/login" />} />  */}
            <Route path="/getgiftcardd" element={<Getgiftcard />} />
            <Route path="/getgiftcardbyadmin" element={<Getgiftcardadmin />} />
            <Route path="/querDetail/:id" element={<QuerDetail />} />
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/loginotp" element={<LoginOtp />} />
            <Route path="/Adminotp" element={<Adminotp />} />
            <Route path="/dashboard" element={<Dashboard />}/>
            <Route path="/register" element={<Register />} /> 
            <Route path="/profile" element={<Profile/>} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/forgot" element={<Forgotpassword />} />
            <Route path="/newpassword" element={<Newpassword />} />
            <Route path="/admincontrol" element={<AdminControl />} />
            <Route path="/userlist" element={<UserList />} />
            <Route path="/pagelist" element={<PageList />} />
            <Route path="/addList/:id" element={<AddList />} />
            <Route path="/sectionlist/:id" element={<SectionList />} />
            <Route path="/userdetaillist/:id" element={<UserDetailList />} />
            <Route path="/admindetail/:id" element={<Admindetail />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/addfaq" element={<AddFaq />} />
            <Route path="/editfaq/:id" element={<EditFaq />} />
            <Route path="/faqdetail/:id" element={<AskFaqDetail />} />
            <Route path="/allblog" element={<AllBlog />} />
            <Route path="/addblog" element={<AddBlog />} />
            <Route path="/editblog/:id" element={<Editblog />} />
            <Route path="/allmedia/:id" element={<AllMedia />} />
            <Route path="/addmedia/:id" element={<AddMedia />} />
            <Route path="/editmedia/:pgeid/:id" element={<EditMedia />} />
            <Route path="/team" element={<TeamList />} />
            <Route path="/addteam" element={<AddTeam />} />
            <Route path="/editteam/:id" element={<EditTeam />} />
            <Route path="/reviews" element={<Review />} />
            <Route path="/review" element={<AllReview />} />
            <Route path="/addreview" element={<AddReview />} />
            <Route path="/vieweview/:id" element={<ViewReview />} />
            <Route path="/offer" element={<Offer />} />
            <Route path="/flatoffer" element={<Flatoffer />} />
            <Route path="/List/:sku" element={<List />} />
            <Route path="/editOfferdiscount/:itemid" element={<EditOfferdiscount />} />
            <Route path="/addoffer" element={<Addoffer />} />
            <Route path="/addflatoffer" element={<Addflatoffer />} />
            <Route path="/editoffer/:sku/:itemid" element={<EditOffer />} />
            <Route path="/giftcard" element={<Giftcard />} />
            <Route path="/addgiftcard" element={<Addgiftcard />} />
            <Route path="/giftcarddetails/:id" element={<GiftCardDetail />} />
            <Route path="/orderhistory" element={<OrderHistory />} />
            <Route path="/orderdetail/:id" element={<OrderDetail />} />
            <Route path="/writeearn" element={<WriteEarn />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/querylist" element={<Querylist />} />
            <Route path="/querdetail/:id" element={<QuerDetail />} />
            <Route path="/category" element={<Category />} />
            <Route path="/customcategory" element={<CustomCategory />} />
            <Route path="/addcategory" element={<AddCategory/>} />
            <Route path="/updateCategory/:id" element={<UpdateCategory/>} />
            <Route path="/assignCategory/:sku" element={<AssignCategory/>} />
            <Route path="/EditassignCategory/:sku" element={<EditCustomCategory/>} />
            <Route path="/setlimit/:sku/:name" element={<Setlimit/>} />
            <Route path="/editsetlimit/:sku" element={<Editsetlimit/>} />
            <Route path="/editproductdescription" element={<EditProductDescription/>} />
            <Route path="/subcategory/:id" element={<SubCategory />} />
            <Route path="/writeearndetail/:id" element={<WriteEarnDetail />} />
            <Route path="/sectioncontent/:id/:sectionid" element={<SectionContent />} />
            <Route path="/staticimage" element={<StaticImageList />} />
            <Route path="/editstaticimage/:id/:type" element={<EditStaticImage />} />
            <Route path="/createstaticimage" element={<CreateStaticImage />} />
            <Route path="/generalsettings" element={<GeneralSettings/>} />
            <Route path="/allProducts" element={<AllProducts />} />
            <Route path="/allProducts/:categoryId" element={<AllProducts />} />
            {/* <Route path="/addcustomproductprice/:sku" element={<AddCustomProductPrice/>} /> */}
            <Route path="/productDetail/:sku" element={<ProductDetail />} />
            <Route path='/addadmin' element={<Addadmin />} />

            <Route path="/edittandc/:sku" element={<Edittandc />} />
            <Route path="/addProducttandc/:sku" element={<AddProducttandc />} />
            <Route path="/addimage/:sku" element={<AddImage />} />
            <Route path="/term&condition/:sku" element={<TermCondition />} />
            <Route path="/sendnotification" element={<SendNotification />} />
            
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </ConditionalWrapper>
      </Router>
      // router  end
    )
  );
}

// navbar js start  
const ConditionalWrapper = ({ children }) => {
 
  const location = useLocation();
  const hideNavbarFooterPages = ['/login', '/register', '/verification', '/forgot', '/newpassword' , '/forgetpasswordotp' , '/loginotp',"/Adminotp","/","*"];

  const shouldHideNavbarFooter = hideNavbarFooterPages.includes(location.pathname);

  return (
    <>
      {!shouldHideNavbarFooter && <Navbar />}
         {/* {!shouldHideNavbarFooter && <AdminSidebar />} */}
         
         <main>{children}</main>
   
    </>
    
  );
};


// navbar js end
export default App;