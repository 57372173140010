import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icon from '../../Assets/Authentication/icon.svg';
import card from '../../Assets/gifzicard.svg';
import '../Form.css';
import Input from '../../Input/Input';
import Commonbtn from '../../Commonbtn/Commonbtn';
import Logo from '../../Logo/Logo';
import { Link } from 'react-router-dom';
import Password from '../../Input/Password';
// import { getToken, messaging } from '../../../firebase';

function Form() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const [fcmToken, setFcmToken] = useState(null);

 




  
  // console.log(fcmToken,"fcm")
  async function login() {
    const item = {
      mobile: phoneNumber,
      password: password,
      fcm_token: fcmToken 
    };
    localStorage.setItem('password', password);
    if (phoneNumber === '9799866929') {
      try {
        setLoading(true);
  
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/super/login_request`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ mobile: phoneNumber ,password: password,  fcm_token: fcmToken }), 
        });
  
        const result = await response.json();
  
        if (response.ok) {
          // console.log('OTP sent:', result);
          navigate("/Adminotp"); 
        } else {
          setError(result.message || 'Failed to send OTP. Please try again.');
        }
      } catch (error) {
        setError('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
      return; // Prevent further login logic
    }
  
    // Regular login logic for other users
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(item),
      });
  
      const result = await response.json();
      if (response.ok) {
        localStorage.setItem("userPhoneNumber", result.data.mobile);
        localStorage.setItem('user-info', JSON.stringify(result));
        localStorage.setItem('authToken', result.data.token);
       
        // console.log(result.data.token);
        navigate('/dashboard');
      }else if (result.errors && Array.isArray(result.errors)) {
        // Handle array of errors
        const errorMessage = result.errors.map(err => err.msg).join(', ');
        setError(errorMessage || 'Login failed. Please try again.');
    } else {
        setError(result.message || 'Login failed. Please try again.');
    }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  }
  

  
  return (
    <section className="authentication">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="form-left">
           
              <form id="survey-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-head col-md-12">
                    <div className="logo-for desk-none">
                      <Logo />
                    </div>
                    <h1 className="f-40 mb-2">Sign in as Admin</h1>
                  </div>

                  <div className="col-md-12">
                    <Input
                      placeholdertext="Enter your registered number"
                      labeltext="Phone Number"
                      fortext="mobile"
                      typetext="number"
                      name="mobile"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                           maxLength={10}
                    />
                  </div>
                  
                  <div className="col-md-12">
                  <Password
  placeholdertext1="Enter your Password"
  labeltext1="Password"
  fortext1="password"
  typetext1="password"
  name="password"
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  autoComplete="current-password" // Suggest "current-password" or "new-password" as needed
/>
                  </div>
                  {error && (
                    <div className="col-md-12">
                      <p className="error-message">{error}</p>
                    </div>
                  )}
                  {/* <div className="col-md-12 text-end">
                    <Link to="/forgot">
                      <span className="text-main f-16">Forget Password?</span>
                    </Link>
                  </div> */}
                  <div className="col-md-12 d-flex-center btn-form">
                    <Commonbtn Commonbtntext={loading ? 'Signing in...' : 'Sign in'} onclicktext={handleSubmit} disabled={loading} />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-5 form-wrap">
            <div className="form-right-sec">
              <div className="logo-for">
                <Logo />
              </div>
              <div className="form-right position-relative">
                <h3 className="f-30 f-weight-600 text-main mb-3">E-Gift Vouchers</h3>
                <p className="f-16">
                  Use your gift card around the world with no hidden fees. Hold, transfer, and spend
                  money.
                </p>
                <div className="card-des">
                  <img src={card} alt="logo" data-aos="fade-right" data-aos-delay="200" height="100%" width="100%" />
                </div>
              </div>

              <div className="card-design shadow-lg rounded-2 row d-flex-center text-start p-2">
                <div className="icon col-md-4">
                  <img src={icon} alt="icon" height="100%" width="100%" />
                </div>
                <div className="right col-md-8">
                  <span className="f-14">Gifzi</span>
                  <p className="f-16 f-weight-600">Gifzi Plus</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
