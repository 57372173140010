import React, { useState } from 'react';


const options = ["All", "Customer", "Corporate"];
const Select = ({onSelectChange}) => {

  const [value, setValue] = useState('Select an Option');

  const handleChange = (e) => {
    const selectedValue = e.target.value ;
    setValue(selectedValue);
    if (onSelectChange){
      onSelectChange(selectedValue === "All" ? "": selectedValue);
    };
  };

  return (
    <div className="form-group">
      <select value={value} onChange={handleChange} className="form-control">
        {options.map(option => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
