// AddBlog.js
import React, { useState, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Input from '../Input/Input';
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import { AiOutlineClose } from 'react-icons/ai'; // Import a close icon (from react-icons)

function AddBlog() {
  const editor = useRef(null);
  const [blogImage, setBlogImage] = useState(null);
  const [blogImageName, setBlogImageName] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [blogDescription, setBlogDescription] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const token = localStorage.getItem('authToken');
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/blog/create`;

  const config = useMemo(() => ({
    readonly: false,
    placeholder: 'Enter blog description here...',
  }), []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('blog_image', blogImage);
    formData.append('title', blogTitle);
    formData.append('description', blogDescription);

    try {
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        setMessage('Blog added successfully!');
        setError(false);
        setBlogImage(null);
        setBlogImageName('');
        setBlogTitle('');
        setBlogDescription('');
      } else {
        setMessage(result.message || 'Failed to add blog');
        setError(true);
      }
    } catch (err) {
      setMessage('Something went wrong, please try again.');
      setError(true);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBlogImage(file);
      setBlogImageName(file.name);
    } else {
      setBlogImage(null);
      setBlogImageName('');
    }
  };

  const handleRemoveImage = () => {
    setBlogImage(null);
    setBlogImageName('');
  };

  return (
    <div className='p-60 ml-14-d userdetail'>
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Add Blog</h3>
          </div>
          {message && (
            <div className={`alert ${error ? 'alert-danger' : 'alert-success'}`}>
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-12'>
                {blogImageName ? (
                  <div className="d-flex align-items-center justify-content-between mb-2 bg-white img-name">
                    <span >{blogImageName}</span>
                    <AiOutlineClose onClick={handleRemoveImage} className="text-danger cursor-pointer" />
                  </div>
                ) : (
                  <Input 
                    placeholdertext="Choose an image" 
                    labeltext="Blog Image" 
                    fortext="blogImage" 
                    typetext="file" 
                    onChange={handleImageChange}
                  />
                )}
              </div>
              <div className='col-md-12'>
                <Input 
                  placeholdertext="Enter blog title" 
                  labeltext="Blog Title" 
                  fortext="blogTitle" 
                  typetext="text" 
                  onChange={(e) => setBlogTitle(e.target.value)} 
                  value={blogTitle}
                />
              </div>
              <div className='col-md-12'>
                <label htmlFor="blogDescription" className="form-label">Blog Description</label>
                <JoditEditor
                  ref={editor}
                  value={blogDescription}
                  config={config}
                  tabIndex={1}
                  onBlur={(newContent) => setBlogDescription(newContent)}
                  onChange={(newContent) => setBlogDescription(newContent)}
                />
              </div>
              <div className='btn-flex-center d-flex gap-2'>
                <Commonbtn Commonbtntext="Save" type="submit" />
                <button type="button" className="Commonredbtn">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBlog;
