import React from 'react'
import List from '../Components/PageList/List'


function PageList() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default PageList