import React from 'react'
import List from '../Components/Schedulelist/List'

function Schedule() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default Schedule