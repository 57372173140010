import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { Link } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const Products = () => {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10); // Items per page
  const [totalCount, setTotalCount] = useState(0);

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}admin/product`;
  const token = localStorage.getItem("authToken");

  // Fetch Products Function
  const fetchProducts = async (currentOffset = 0, query = "", categoryId = "") => {
    try {
      setLoading(true);
      setIsFetchingMore(true);

      const apiUrl = categoryId
        ? `${baseUrl}/category?category_id=${categoryId}&offset=${currentOffset}&limit=${limit}`
        : query
        ? `${baseUrl}/search?offset=${currentOffset}&limit=${limit}&search=${query}`
        : `${baseUrl}/all?offset=${currentOffset}&limit=${limit}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setProducts(data.data.products || []);
        setTotalCount(data.data.totalProducts);
        setHasMore(data.data.products.length === limit);
        setError(null);
      } else {
        setError("Failed to fetch products.");
      }
    } catch (err) {
      // console.error("Fetch error:", err);
      setError("Error fetching products.");
    } finally {
      setLoading(false);
      setIsFetchingMore(false);
    }
  };

  // Handle Search Input with Debouncing
  const debouncedSearch = useCallback(
    _.debounce((query) => setSearchQuery(query), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Fetch products on relevant changes
  useEffect(() => {
    fetchProducts(offset, searchQuery, categoryId);
  }, [offset, searchQuery, categoryId, limit]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit); // Calculate offset for the selected page
  };

  // Handle limit change
  const handleLimitChange = (e) => {
    const newLimit = Number(e.target.value);
    setLimit(newLimit);
    setCurrentPage(1); // Reset to the first page
    setOffset(0); // Reset offset to fetch from the first record
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / limit);




  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex gap-2 pb-4">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">All Products</h3>
          </div>

          {/* Search Form */}
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="d-flex pb-4">
              <div className="col-md-12">
                <div className="search-bar-container">
                  <input
                    type="text"
                    placeholder="Search Here"
                    className="search-input"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />
                  <button type="button" className="search-button">
                    <FontAwesomeIcon icon={faSearch} className="f-16 greyf6" />
                  </button>
                </div>
              </div>
            </div>
          </form>

          {/* Product List */}
          <div className="Orderhistorydata-list-main">
            <div className="row">
              {products.length > 0 ? (
                products.map((product, index) => (
                  <div key={index} className="col-md-3 d-flex">
                    <div className="mright-3 Orderhistorydata-item shadow-sm rounded-3 d-flex flex-column justify-content-between gap-4 w-100 position-relative">
                      <Link to={`/productDetail/${product.sku}`}>
                        {product.flat_discount ? (
                          <div className="text-dark white badge bg-warning text-dark f-12 m-0 p-1 d-flex-center gap-2 f-weight-600 justify-content-between flat-offp">
                            <p className="m-0 p-0">
                              {product.flat_discount}% Flat off
                            </p>
                          </div>
                        ) : null}
                        <div className="Orderhistorydata-icon col-md-12">
                          <img
                            src={product.thumbnail}
                            alt={product.name}
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="Orderhistorydata-content col-md-12 mt-5">
                          <h4 className="f-20 f-weight-600 text-main mb-1">
                            {product.name}
                          </h4>
                          <p className="text-muted f-16">{product.sku}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>

          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              <span>Show </span>
              <select
                value={limit}
                onChange={handleLimitChange}
                className="form-select w-auto d-inline-block select-width"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <span> per page</span>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary prev-btn" 
                >
               <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
             
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`btn ${currentPage === i + 1 ? "btn-primary filled-primary" : "btn-secondary outline-page"}`}
            >
              {i + 1}
            </button>
          ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="btn btn-secondary  next-btn"
                >
                 <svg height="25px" width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
               
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
