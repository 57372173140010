import React from 'react'
import Review from '../Components/Review/Review'

function AllReview() {
  return (
    <div>
        <Review/>
    </div>
  )
}

export default AllReview