import React, { useState, useEffect } from "react";
import Commonbtn from "../Components/Commonbtn/Commonbtn";
import { useNavigate } from "react-router-dom";
import "./Getgiftcard.css";

function Getgiftcard() {
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [error, setError] = useState(null);
  const [resendTimer, setResendTimer] = useState(0);
  const [resendLoading, setResendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");
  const userPhoneNumber = localStorage.getItem("userPhoneNumber");
  const navigate = useNavigate();

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleNumberClick = (number) => {
    setInputValue(number.toString());
  };

  const generateTxnId = () => {
    const randomString = Math.random().toString(32).substr(2, 8);
    const timestamp = Date.now();
    return `CUS_${randomString.toUpperCase()}${timestamp}`;
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${baseUrl}admin/user/search?offset=0&limit=10000&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
        setSearchResults(result.data.users);
      } else {
        // console.error("Error in search:", await response.json());
      }
    } catch (error) {
      // console.error("Fetch error:", error);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUserId(user.id);
  };

  const handleChange = (element, index) => {
    if (/^[0-9]$/.test(element.value) || element.value === "") {
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to the next input field after entering a number
        if (element.value && element.nextSibling) {
            element.nextSibling.focus();
        }
    }
};

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      const otpCopy = [...otp];
      otpCopy[index] = '';
      setOtp(otpCopy);
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const otpCode = otp.join('');
    if (otpCode.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
  }
    try {
      const response = await fetch(`${baseUrl}user/register/verifyOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          type: "mobile",
          mobile: userPhoneNumber,
          otp: otpCode,
        }),
      });

      if (response.ok) {
        handleSubmit();
        setShowOtpModal(false); // Close OTP Modal
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      // console.error("OTP verification error:", error);
      setError("An error occurred while verifying OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    if (resendLoading || resendTimer > 0) return;

    setResendLoading(true);
    try {
      const response = await fetch(`${baseUrl}user/register/requestOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "mobile",
          mobile: userPhoneNumber,
        }),
      });

      if (response.ok) {
        setResendTimer(30); // Set timer for resend functionality
        setOtp(Array(6).fill('')); // Clear the OTP fields
        setError(null); // Clear any previous error
      } else {
        // console.error("Error requesting OTP:", await response.json());
      }
    } catch (error) {
      // console.error("Error in OTP request:", error);
    } finally {
      setResendLoading(false);
    }
  };

  const handleGiftCardClick = async (e) => {
    e?.preventDefault();

    if (!selectedUserId) {
      alert("Please select a user before proceeding.");
      return;
    }
    try {
      const response = await fetch(`${baseUrl}user/register/requestOTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        
        body: JSON.stringify({
          type: "mobile",
          mobile: userPhoneNumber,
        }),
      });

      if (response.ok) {
        setShowOtpModal(true);
      } else {
        // console.error("Error requesting OTP:", await response.json());
      }
    } catch (error) {
      // console.error("Error in OTP request:", error);
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!selectedUserId) {
      alert("Please select a user from the search results.");
      return;
    }

    const txnId = generateTxnId();
    const amount = parseFloat(inputValue);

    const data = {
      transaction_id: txnId,
      amount: amount,
      status: 1,
      user_id: selectedUserId,
    };

    try {
      const response = await fetch(`${baseUrl}admin/gifzi_plus/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        navigate("/giftcard");
      } else {
        // console.error("Error:", await response.json());
      }
    } catch (error) {
      // console.error("Fetch error:", error);
    }
  };

  return (
    <div className="p-60 ml-14-d user-list">
      <div className="container py-5">
        <div>
          <div className="card shadow p-3">
            <div className="card-body">
              <h2 className="text-center fs-4 my-5">
                Select Denomination or Enter Custom Amount
              </h2>
              <div className="d-flex gap-2 mb-3">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="form-control"
                />
                <button onClick={handleSearch} className="common-btn">
                  Search
                </button>
              </div>
              <div>
                {searchResults.length > 0 && (
                  <div className="text-muted mb-2">
                    Please select a user from the list
                  </div>
                )}
                {searchResults.map((user) => (
                  <div
                    key={user.id}
                    className={`p-3 border rounded-pill m-2 cursor-pointer ${
                      selectedUserId === user.id ? "bg-secondary text-white" : "bg-light "
                    }`}
                    onClick={() => handleUserSelect(user)}
                  >
                    {user.first_name} {user.last_name} ({user.email}) ({user.mobile})
                  </div>
                ))}
              </div>
              <div className="row m-5">
                {[10, 100, 1000, 10000, 25000, 50000].map((number) => (
                  <div key={number} className="col-4 mb-2">
                    <button
                      className="btn btn-outline-secondary w-100"
                      onClick={() => handleNumberClick(number)}
                    >
                      {number}
                    </button>
                  </div>
                ))}
              </div>
              <form onSubmit={handleGiftCardClick} className="mt-3">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter custom amount (10-50000)"
                  required
                  className="form-control mb-3"
                />
                <div className="text-center">
                  <Commonbtn
                    Commonbtntext="Get Gift Card"
                    type="button"
                    onClick={handleGiftCardClick}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Custom OTP Modal */}
      {showOtpModal && (
        <div className="otp-modal-overlay">
          <div className="otp-modal">
            <div className="otp-modal-header">
            <button
                className="float-end f-18"
                onClick={() => setShowOtpModal(false)}
              >
                &times;
              </button>
            
            </div>
            <div className="otp-modal-body">
                
              <p className="f-18 mb-4 greyf6 text-center">
                We sent you a one-time OTP 
                
              </p>
              
              <label className="f-16 f-weight-600 text-center">Enter your OTP</label>
              <div className="inputs-5 d-flex">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="tel"
                    maxLength="1"
                    className="form-control form-control-lg m-2 input-otp"
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>

              {error && <p className="error-message">{error}</p>}

              <p className="f-18 text-center mb-4">
                Did not receive OTP?{" "}
                {resendTimer > 0 ? (
                  <span className="text-muted">Resend in {resendTimer}s</span>
                ) : (
                  <strong
                    className="text-decoration-underline"
                    onClick={handleResendOtp}
                  >
                    {resendLoading ? "Resending..." : "Resend"}
                  </strong>
                )}
              </p>

              <form onSubmit={handleOtpSubmit}>
                <div className="col-md-12 d-flex-center btn-form">
                  <Commonbtn
                    Commonbtntext={loading ? "Verify" : "Verify code"}
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Getgiftcard;
