import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Input from "../../Input/Input";
import Commonbtn from "../../Commonbtn/Commonbtn";
import ArrowRedirect from "../../Commonbtn/ArrowRedirect";

const AddImage = () => {
  const { sku } = useParams();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);
  const { thumbnail } = location.state || {};
  const [productImage, setProductImage] = useState(null);
  const [productImageUrl, setProductImageUrl] = useState(thumbnail || null);
  const navigate = useNavigate();
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchProductImage = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/product/update/image`, {
          method: "POST",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (data.imageUrl) {
          setProductImageUrl(data.imageUrl);
        }
      } catch (error) {
        // console.error("Error fetching product image:", error);
      }
    };

    fetchProductImage();
  }, [sku, baseUrl, token]);

  const handleImageUpload = async () => {
    // console.log("Upload button clicked");
    if (!productImage) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("sku", sku);
    formData.append("thumbnail", productImage);

    try {
      const response = await fetch(`${baseUrl}admin/product/update/image`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      // console.log("Response received:", data);

      if (response.ok) {
        setProductImageUrl(data.imageUrl);
        setShowPopup(true);
        setError(null);
        navigate("/allProducts");
      } else {
        setError("Failed to upload image");
        // console.error("Image upload failed:", data);
      }
    } catch (error) {
      setError("Error uploading image");
      // console.error("Error uploading image:", error);
    } finally {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        if (!error) {
          /// navigate(`/productDetail/${sku}`);
        }
      }, 2000);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Upload Product Image</h3>
          </div>
          <form onSubmit={handleImageUpload}>
            <div className="col-md-12">
              <Input
                placeholdertext="Choose an image"
                labeltext="Product Image"
                fortext="Product Image"
                typetext="file"
                onChange={(e) => {
                  setProductImage(e.target.files[0]);
                  setProductImageUrl(URL.createObjectURL(e.target.files[0]));
                }}
              />
              {productImageUrl && (
                <img
                  src={productImageUrl}
                  alt="Preview"
                  height="100"
                  style={{ borderRadius: "10px" }}
                />
              )}
              <div className="btn-flex-center d-flex gap-2 pt-3">
                <Commonbtn Commonbtntext="Upload Image" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <p>{error ? "Failed to upload image" : "Successfully uploaded"}</p>
        </div>
      )}
    </div>
  );
};

export default AddImage;
