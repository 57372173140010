import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../Searchbar/Searchbar";

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async (query = "") => {
    const token = localStorage.getItem("authToken");
    // console.log("Fetching categories with token:", token);

    if (!token) {
      // console.log("No token found, redirecting to login");
      navigate("/");
      return;
    }

    try {
      const url = query
        ? `${baseUrl}admin/category/search?offset=0&limit=10000&search=${query}`
        : `${baseUrl}admin/category/all?offset=0&limit=10`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.ok) {
        const result = await response.json();
        // console.log("Fetched categories:", result); // Debugging statement
        setCategories(result.data.categories);
        setFilteredCategories(result.data.categories);
      } else {
        // console.error("Failed to fetch categories:", response.status); // Added status logging
      }
    } catch (error) {
      // console.error("Error fetching categories:", error);
    }
  };

  const syncCategories = async () => {
    const token = localStorage.getItem("authToken");
    // console.log("Syncing categories with token:", token);

    if (!token) {
      // console.log("No token found, redirecting to login");
      navigate("/");
      return;
    }

    try {
      const response = await fetch(`${baseUrl}admin/category/sync`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.ok) {
        const result = await response.json();
        alert(result.message || "Categories synchronized successfully.");
        fetchCategories();
      } else {
        // console.error("Failed to sync categories:", response.status);
      }
    } catch (error) {
      // console.error("Error syncing categories:", error);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    fetchCategories(query);
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <h3 className="title">Category</h3>
          <div className="row gap-2 align-items-center">
            <div className="col-md-8">
              <SearchBar
                searchtext="Search Here"
                searchtype="text"
                searchclass="custom-search-bar"
                handleSearchChange={handleSearchChange}
              />
            </div>
            <div className="col-md-3">
              <button className="common-btn" onClick={syncCategories}>
                Sync Categories
              </button>
            </div>

            {filteredCategories.length === 0 ? (
              <div className="col-md-12 text-center d-flex-center my-4">
                <p className="f-24 f-weight-600 grey-85 mb-1 mt-1">
                  No categories available.
                </p>
              </div>
            ) : (
              filteredCategories.map((category) => (
                <div
                  className="col-md-4 position-relative my-4" 
                  key={category.id}
                >
                  <div className="bg-white p-2">
                    <i className="blog-icon">
                      <img
                        className="rounded"
                        src={category.images.image || "placeholder_image.png"}
                        alt={category.name}
                        height="100%"
                        width="100%"
                      />
                    </i>
                    <p className="f-14 grey-85 mb-1 mt-1">
                      Category: {category.name}
                    </p>
                    
                    {category.total_subcategories > 0 && (
                      <Link to={`/subcategory/${category.id}`}>
                        <button className="common-btn mt-3">
                          View Subcategories
                        </button>
                      </Link>
                    )}

                    <Link to={`/allProducts/${category.id}`}>
                      <button className="common-btn mt-3">
                        View Product
                      </button>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryList;
