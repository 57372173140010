import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import './Review.css';

function Review() {
  const [reviewsData, setReviewsData] = useState([]); // State to store reviews
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // State to toggle the visibility of review details for each review
  const [viewDetails, setViewDetails] = useState({});

  // Toggle the visibility of a specific review's details
  const toggleDetails = (index) => {
    setViewDetails(prevState => ({
      ...prevState,
      [index]: !prevState[index] // Toggle the visibility of the current review
    }));
  };

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_API_URL; // API Base URL from environment variables
    const token = localStorage.getItem("authToken"); // Authentication token stored in localStorage

    // Function to fetch reviews from the API
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/review/all?offset=0&limit=999999999`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token, // Include the token in the request headers
          },
        });
        const result = await response.json(); 
        // Check if the response is not OK (status code is not in the 2xx range)
        if (!response.ok) {
          throw new Error('No reviews available right now'); // Throw an error if the fetch fails
        }

      // Parse the response as JSON
        // console.log("Fetched Reviews:", result); // Log the fetched reviews for debugging

        // Check if there is data available in the response
        if (result.data.reviews && result.data.reviews.length > 0) {
          setReviewsData(result.data.reviews); // Set the reviews data in state
        } else {
          setError('No reviews available'); // Set an error message if no data is found
        }
      } catch (err) {
        // console.error('Error fetching reviews:', err); // Log any errors that occur
        setError(err.message); // Set the error message in state
      } finally {
        setLoading(false); // Set loading to false after fetching is complete
      }
    };

    fetchReviews(); // Call the fetchReviews function when the component mounts
  }, []);


  const toggleReviewStatus = async (id, currentStatus) => {
    const token = localStorage.getItem('authToken');
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 0 (inactive) and 1 (active)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}admin/review/changeStatus?id=${id}&status=${newStatus}`,
        {
          method: 'GET', // Assuming PATCH method to update status
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        // Update blogData locally or refetch data
        setReviewsData((prevData) =>
          prevData.map((review) =>
            review.id === id ? { ...review, status: newStatus } : review
          )
        );
      } else {
        alert(result.message || 'Failed to update status');
      }
    } catch (err) {
      alert('Something went wrong. Please try again.');
    }
  };

  // If the data is still loading, display a loading message
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='p-60 ml-14-d userdetail'>
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <section className="review-body">
            <div className="container">
              <div className="d-flex-center justify-content-between pb-3">
                <div className='d-flex gap-2'>
                  <ArrowRedirect />
                  <h3 className="grey-85 f-18 f-weight-600">Reviews</h3>
                </div>
                <div className='btn-flex-center d-flex gap-2'>
                  <Link to="/addreview">
                    <Commonbtn Commonbtntext="+ Add Review" />
                  </Link>
                  
                </div>
              </div>

              {/* If an error occurred, display the error message */}
              {error && (
                <div className="col-12">
                  <p className="error-text">Error: {error}</p>
                </div>
              )}

              <div className="row">
                {/* If no reviews are available, show a message indicating that */}
                {reviewsData.length === 0 && !error ? (
                  <div className="col-12">
                    <p className="no-data-text">No reviews available.</p>
                  </div>
                ) : (
                  // Map through the reviews and render each one
                  reviewsData.map((review, index) => (
                    <div key={index} className="col-md-4 d-flex position-relative">
                      <div className="review-card bg-white">
                        <div className="review-header">
                          <h3 className="reviewer-name f-20">{review.review}</h3>
                          <p className="review-date f-14 grey-85">{review.date}</p>
                          <div className="rating">
                            {"★".repeat(review.rating) + "☆".repeat(5 - review.rating)}
                          </div>
                        </div>
                        <p className="review-text f-14">Publish Date : {review.published_on}</p>

                        <div className='d-flex justify-content-between align-items-center'>
                        <div className="status-toggle" onClick={() => toggleReviewStatus(review.id, review.status)}>
                          <p className={`status-indicator mt-2 ${review.status === 0 ? 'inactive' : 'active'}`}>
                            {review.status === 1 ? "Active" : "Inactive"}
                          </p>
                        </div>
                        <Link to={`/vieweview/${review.id}`} className="view-detail-link">
                        <div className="toggle-visibility">
                          <span className="">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" className="svg-inline--fa fa-eye " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                              <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
                            </svg>
                          </span>
                          {/* Conditionally render the review description based on viewDetails state */}
                          {viewDetails[index] && (
                            <p className="review-description f-14">{review.description}</p>
                          )}
                        
                        </div>
                        </Link>
                    
                        </div>

                    
                      
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Review;
