import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";

function List() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0); // Initialize offset
  const [totalItems, setTotalItems] = useState(0); // Total number of items from the API
  const [isFetching, setIsFetching] = useState(false); // Control fetching state
  const [hasMoreData, setHasMoreData] = useState(true); // Track if there's more data
  const token = localStorage.getItem("authToken");
  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
  const limit = 999999999999; // Number of users to fetch per request

  // console.log(token)

  // Fetch data when offset changes
  useEffect(() => {
    const fetchData = async () => {
      if (!token || !hasMoreData) return; // Stop API calls if no more data

      setIsFetching(true); // Start fetching data

      try {
        const response = await fetch(
          `${baseUrl}admin/cms/pages?offset=${offset}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          // console.log("Data fetched", result);
          if (result && result.data && result.data.pages.length > 0) {
            setData(result.data.pages); // Set the user list
            setTotalItems(result.data.totalItems); // Set the total number of items from API
          } else {
            setHasMoreData(false); // No more data, stop further API calls
          }
        } else {
          const errorData = await response.json();
          // console.error(
          //   "Failed to fetch data:",
          //   response.statusText,
          //   errorData
          // );
        }
      } catch (error) {
        // console.error("An error occurred while fetching data:", error);
      } finally {
        setIsFetching(false); // Stop fetching data
      }
    };

    fetchData();
  }, [offset, token, baseUrl, hasMoreData]);

  // Function to navigate back or to the dashboard
  const handleArrowClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  // Pagination handlers
  const handlePreviousPage = () => {
    if (offset >= limit) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  const handleNextPage = () => {
    if (hasMoreData) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  return (
    <div className="p-60 ml-14-d user-list">
      <div className="container table">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex bg-white pt-20 cursor-pointer">
            <i className="arrow-left me-2 " onClick={handleArrowClick}>
              <svg
                width="18px"
                height="15px"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_945_2412)">
                  <path
                    d="M17 7.5H1M1 7.5L7 1.5M1 7.5L7 13.5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_945_2412">
                    <rect
                      width="18"
                      height="14"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </i>
            <h3 className="text-dark f-18 f-weight-600">Page List</h3>
          </div>

          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">Pages</th>
                <th scope="col">Status</th>
                <th scope="col">Section</th>
                <th scope="col">Media</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">
                      {/* <Link
                        // to={`/userdetaillist/${item.id}`}
                        className="table-row-link"
                      > */}
                        {item.title}
                      {/* </Link> */}
                    </th>
                    <td>
                      <span
                        className={item.status === 1 ? "active" : "inactive"}
                      >
                        {item.status === 1 ? "active" : "inactive"}
                      </span>
                    </td>
                    <td>
                      <Link
                        to={`/sectionlist/${item.id}`}
                        className="table-row-link"
                      >
                        <MdEdit className="edit-icon" /> 
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/allmedia/${item.id}`}
                        className="table-row-link flex"
                      >
                      Edit  <MdEdit className="edit-icon pt-1"  />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No Pages found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isFetching && <p>Loading more users...</p>}

        {/* Pagination Info */}
        {/* <p>
          {`Showing ${offset + 1} to ${Math.min(offset + limit, totalItems)} of ${totalItems}`}
        </p> */}

        {/* Pagination Controls */}
        {/* <div className="pagination">
          <button
            onClick={handlePreviousPage}
            disabled={offset === 0}
            className="btn btn-primary"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={!hasMoreData}
            className="btn btn-primary"
          >
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default List;
