import React from 'react'
import Table from './Table'
import ArrowRedirect from '../Commonbtn/ArrowRedirect';

function List() {
  return (
    <div>
            <div className='p-60 ml-14-d userdetail'>
 <div className="container">

   <div className="main-bg-off-white bg-off-white">
   <div className="d-flex mb-3 gap-2">
   <ArrowRedirect/>
            <h3 className="grey-85 f-18 f-weight-600">Demo Schedule</h3>
            </div>
    
        <Table/>

   </div>
   </div>
   </div>
    </div>
  )
}

export default List