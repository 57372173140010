import React, { useState } from 'react';
import Commonbtn from '../Commonbtn/Commonbtn';
import ArrowRedirect from '../Commonbtn/ArrowRedirect';
import Input from '../Input/Input';

function SendNotification() {
  const [value, setValue] = useState('Select an Option');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const token = localStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const options = [
    { category_id: 0, category: 'All' },
    { category_id: 1, category: 'New users' },
    { category_id: 2, category: 'Old Users' },
    { category_id: 3, category: 'Users with item at cart' },
    { category_id: 4, category: 'Max purchase users (more than 100000 )' },
    { category_id: 5, category: 'Min purchase users (less than 1000 )' },
  ];

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSendNotification = async () => {
    if (!title.trim() || !message.trim()) {
      alert('Please fill out all fields.');
      return;
    }

    const selectedCategory = options.find((option) => option.category === value);
    if (!selectedCategory) {
      alert('Please select a valid category.');
      return;
    }

    const payload = {
      category_id: selectedCategory.category_id,
      content: {
        title,
        body: message,
      },
    };

    try {
      const response = await fetch(`${baseUrl}admin/notification/bulk_push`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        alert('Notification sent successfully!');
        // console.log('Response:', result);
      } else {
        const error = await response.json();
        alert(`Error: ${error.message || 'Failed to send notification'}`);
        // console.error('Error Response:', error);
      }
    } catch (error) {
      alert('An error occurred while sending the notification.');
      // console.error('Error:', error);
    }
  };

  return (
    <div className="dashboard p-60 ml-14-d">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="notification">
            <div className="d-flex-center justify-content-between pb-3">
              <div className="d-flex gap-2">
                <ArrowRedirect />
                <h3 className="f-18 f-weight-600">Notification</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                <select value={value} onChange={handleChange} className="form-control">
  <option disabled>Select an Option</option>
  {options.map((option) => (
    <option value={option.category} key={option.category_id}>
      {option.category}
    </option>
  ))}
</select>

                </div>
              </div>

              <div className="col-md-12">
                <Input
                  placeholdertext="Enter your notification title"
                  labeltext="Title"
                  fortext="Title"
                  typetext="text"
                  name="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <Input
                  placeholdertext="Enter your message"
                  labeltext="Message"
                  fortext="Message"
                  typetext="text"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <div className="col-md-12">
               
                <button onClick={handleSendNotification} className='common-btn'>Send Notification</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendNotification;
