import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import Input from "../Input/Input";
import Commonbtn from "../Commonbtn/Commonbtn";
import ArrowRedirect from "../Commonbtn/ArrowRedirect";
import { useNavigate, useParams } from "react-router-dom";


function EditStaticImage() {
  const editor = useRef(null);
 
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blogImage, setBlogImage] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const token = localStorage.getItem("authToken");

  

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${baseUrl}admin/cms/staticImage/detail?static_id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        if (response.ok) {
          const result = await response.json();
          setBlogImageUrl(result.data.image);

         
        } else {
          setErrorMessage("Failed to fetch blog data.");
        }
      } catch (error) {
        setErrorMessage("Error fetching blog: " + error.message);
      }
    };

    fetchBlogData();
  }, [id, token, baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("static_id", id);
    if (blogImage) {
      formData.append("static_image", blogImage);
    }

    try {
      const response = await fetch(`${baseUrl}admin/cms/staticImage/update`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        // Reset fields after submission
        setBlogImage("");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate("/staticimage");
        }, 2000);
      } else {
        setErrorMessage("Failed to update blog");
      }
    } catch (err) {
      setErrorMessage("Something went wrong, please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <div className="d-flex mb-3 gap-2">
            <ArrowRedirect />
            <h3 className="grey-85 f-18 f-weight-600">Edit Static Image</h3>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  placeholdertext="Choose an image"
                  labeltext="Image"
                  fortext="blogImage"
                  typetext="file"
                  onChange={(e) => {
                    setBlogImage(e.target.files[0]);
                    setBlogImageUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                {blogImageUrl && (
                  <img
                    src={blogImageUrl}
                    alt="Preview"
                    height="100"
                    style={{ borderRadius: "10px", paddingBottom: "10px" }}
                  />
                )}
              </div>

              <div className="btn-flex-center d-flex gap-2 pt-10">
                <Commonbtn
                  Commonbtntext="Save"
                  type="submit"
                  disabled={isSubmitting}
                />
                <button
                  type="button"
                  className="Commonredbtn"
                  onClick={() => navigate("/staticimage")}
                >
                  Cancel
                </button>
              </div>
              
                
            </div>
          </form>
          {showPopup && (
            <div className="popup">
              <p>Successfully Saved</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditStaticImage;
